import {Model} from 'ng-wzb';
import {SurveyAnswer} from './survey-answer';

export class SurveyQuestion extends Model {
  static entity = 'survey-question';
  entity = 'survey-question';

  public text: string;
  public survey_id: number;
  public type: number;
  public position: number;
  public answers: Array<SurveyAnswer> = [];
  public answer_required = 0;


  getFormModel() {
    return [];
  }

}
