import {Component, OnInit} from '@angular/core';
import {BreadcrumbItem, DataCollection} from 'ng-wzb';
import {Survey} from '../../models/survey';
import {AppInjector} from '../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {StringHelper} from '../../helpers/string-helper';
import {DateHelper} from '../../helpers/date-helper';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {BaseIndexComponent} from '../base-index/base-index.component';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent extends BaseIndexComponent implements OnInit {

  public modelClass = Survey;

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Surveys'),
    }
  ];

  public sh;
  public dh;
  public keyValPipe;
  public calIcon = faCalendar;

  public filters = {
    id: null,
    title: null,
    active: null,
    created_at: {
      from: null,
      to: null
    }
  }

  public sortOptions = [
    {sort: {id: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Id')},
    {sort: {id: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Id')},
    {sort: {title: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Title')},
    {sort: {title: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Title')},
    {sort: {active: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Active')},
    {sort: {active: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Active')},
    {sort: {created_at: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Created at')},
    {sort: {created_at: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Created at')}
  ];
  public sortParam = null;
  public sortDefaultParam = 1;

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.sh = StringHelper;
    this.dh = DateHelper;
    this.keyValPipe = AppInjector.get(KeyValuePipe);
  }

  /**
   * Function to execute before load models
   * returns boolean. If it is false models are not loaded
   */
  beforeLoadModels() {
    if (!this.sortParam) {
      this.sortParam = this.sortDefaultParam;
      const param = this.sortOptions[this.sortParam];
      this.collection.setSort(param.sort, false);
    }
    return true;
  }

  /**
   * Function to filter collection
   */
  filter(event, fromDates = false) {
    if (fromDates) {
      this.filters.created_at.from = DateHelper.getPhpDateFromDatepickerObject(event.from);
      this.filters.created_at.to = DateHelper.getPhpDateFromDatepickerObject(event.to, true);
    }
    this.collection.filter = this.composeFilter();
  }

  /**
   * Fucntion to sort collection
   * @param event trigger
   */
  sort(event) {
    if (this.sortParam !== null) {
      let param = this.sortOptions[this.sortParam];
      if (param) {
      } else {
        this.sortParam = this.sortDefaultParam;
        param = this.sortOptions[this.sortParam];
      }
      this.collection.setSort(param.sort);
    }
  }

  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.filters = {
      id: null,
      title: null,
      active: null,
      created_at: {
        from: null,
        to: null
      }
    }
    this.collection.filter = {};
  }

  /**
   * Function to prepare filter query
   */
  composeFilter() {
    const filters = [];
    this.keyValPipe.transform(this.filters).forEach((filter) => {
      const key = filter.key;
      // filter.value === 'null' is needed to exclude both value
      if (filter.value === null || filter.value === '' || filter.value === 'null') {
        return;
      }

      const q = {};
      switch (key) {
        case 'created_at':
          if (filter.value.from != null && filter.value.to != null) {
            q[key] = {'>=': filter.value.from, '<=': filter.value.to};
          }
          break;
        case 'title':
          q[key] = {like: filter.value};
          break;
        default:
          q[key] = filter.value;
          break;
      }
      filters.push(q);

    });

    let filter = {};
    switch (filters.length) {
      case 0:
        break;
      case 1:
        filter = filters[0];
        break;
      default:
        filter = {and: filters}
        break;
    }
    return filter;
  }

}
