<div class="survey-form-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>{{model ? (model.id ? 'Actualizar encuesta #' + model.id : 'Crear encuesta') : ''}}</h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-8 offset-2">

      <div class="survey-form card card-body mb-4">
        <div class="actions mb-3">
          <a href="#" [routerLink]="['/gestor']" class="btn btn-outline-lightning rounded-0 float-left"
             i18n>Cancelar</a>
          <span (click)="saveModel()" class="btn btn-lightning rounded-0 float-right" i18n>Guardar</span>
        </div>
        <div class="row">
          <div class="col-3 offset-9">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="sort_questions"
                     [(ngModel)]="sortQuestions" (ngModelChange)="setQuestionsSortable()">
              <label class="form-check-label" for="sort_questions" i18n>
                Ordenar preguntas
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="title" i18n>Título</label>
              <input id="title" class="form-control" [(ngModel)]="model.title">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="active" [checked]="model.active == 1"
                     (change)="model.active = $event.target.checked?1:0">
              <label class="form-check-label" for="active" i18n>
                Activo
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="is_anonymous"
                     [checked]="model.is_anonymous == 1"
                     (change)="model.is_anonymous = $event.target.checked?1:0" [disabled]="model.hasStarted">
              <label class="form-check-label" for="is_anonymous" i18n>
                Es anónima
              </label>
            </div>
            <div class="form-check" *ngIf="!model.is_anonymous">
              <input class="form-check-input" type="checkbox" value="" id="callback_enabled"
                     [checked]="model.callback_enabled == 1"
                     (change)="model.callback_enabled = $event.target.checked?1:0" [disabled]="model.hasStarted">
              <label class="form-check-label" for="callback_enabled" i18n>
                Notificar respuestas
              </label>
            </div>
          </div>
          <div class="col-9 text-right" *ngIf="modelLoaded">
            <ng-container *ngIf="!isNewRecord; else audienceAdvice">
              <a href="#" [routerLink]="['/gestor/audience-survey/'+model.id]"
                 *ngIf="model.audienceCount > 0; else noAudience"
                 class="btn btn-sm btn-lightning rounded-0">{{model.audienceCount}} <span i18n
                                                                                          *ngIf="model.audienceCount == 0">Audiencia</span><span
                i18n *ngIf="model.audienceCount > 0">Audiencias</span></a>
              <ng-template #noAudience>
                <a href="#" [routerLink]="['/gestor/audience/create/'+model.id]" class="btn btn-sm btn-danger rounded-0"
                   i18n>Añadir audiencia</a>
              </ng-template>
            </ng-container>
            <ng-template #audienceAdvice>
              <p class="text-danger">Para añadir audiencias, primero debe guardar la encuesta.</p>
            </ng-template>
          </div>
        </div>
        <div class="row mt-2 notification-form" *ngIf="model.callback_enabled">
          <div class="col-6">
            <div class="form-group">
              <label i18n for="callback_text">Texto de la notificación</label>
              <textarea id="callback_text" class="form-control" [(ngModel)]="model.callback_text"></textarea>
            </div>

          </div>
          <div class="col-6">
            <div class="form-group">
              <label i18n for="callback_emails">Emails</label>
              <textarea id="callback_emails" class="form-control" [(ngModel)]="model.callback_emails"></textarea>
              <span class="text-muted emails-hint" i18n>Los emails que serán notificados, separados por comas (,) o punto y comas (;).</span>
            </div>
          </div>
        </div>

      </div>
      <ng-container *ngIf="!model.hasStarted; else manageSurvey">
        <div class="questions" *ngIf="model.questions" [dragula]="(sortQuestions?questionBag:null)"
             [dragulaModel]="sortQuestions?model.questions:[]">
          <div class="card card-body mb-3" *ngFor="let question of model.questions; let i = index;">
            <a class="btn btn-sm btn-outline-danger btn-delete-question" href="#" (click)="deleteQuestion(i, $event)">
              <fa-icon [icon]="icons.trash"></fa-icon>
            </a>
            <div class="row">
              <div class="col-8">
                <div class="form-group">
                  <label for="qtext_{{i}}" i18n>Texto de la pregunta</label>
                  <input id="qtext_{{i}}" class="form-control" [(ngModel)]="question.text">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="qtype_{{i}}" i18n>Tipo</label>
                  <select id="qtype_{{i}}" class="form-control" [(ngModel)]="question.type">
                    <option *ngFor="let type of questionTypes?.names | keyvalue"
                            value="{{type.key}}">{{type.value}}</option>
                  </select>
                </div>
              </div>

            </div>

            <ng-container *ngIf="!sortQuestions">
              <div class="row">
                <div class="col-12">
                  <ng-container *ngIf="question.type != questionTypes.constants?.TEXT; else textOptions">
                    <div class="mb-3 answer-form">
                      <label for="answer_text_{{i}}" i18n>Respuestas</label>
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="Texto de la respuesta" i18n-placeholder
                               id="answer_text_{{i}}">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" i18n (click)="addAnswer(i, $event)">
                            Añadir
                          </button>
                        </div>
                      </div>
                      <div *ngIf="model.callback_enabled">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="answer_trigger_callback_{{i}}"
                                 [checked]="model.trigger_callback == 1"
                                 (change)="model.trigger_callback = $event.target.checked?1:0"
                                 [disabled]="model.hasStarted">
                          <label class="form-check-label" for="answer_trigger_callback_{{i}}" i18n>
                            Notificar esta respuesta
                          </label>
                        </div>
                      </div>
                    </div>
                    <ul class="list-group" *ngIf="question.answers" [dragula]="answerBagPrefix+i"
                        [dragulaModel]="question.answers">
                      <li *ngFor="let answer of question.answers; let k = index;" class="list-group-item">
                        <div class="row">
                          <div class="col-1" *ngIf="model.callback_enabled">
                             <span class="notify-answer-icon"
                                   [title]="'Notificación '+(answer.trigger_callback == 1?'activada':'desactivada')"
                                   (click)="answer.trigger_callback = (answer.trigger_callback == 1?0:1)">
                              <fa-icon *ngIf="answer.trigger_callback == 1;else notNotify" [icon]="icons.notify"
                                       class="text-success"></fa-icon>
                              <ng-template #notNotify>
                                <fa-icon [icon]="icons.notNotify" class="text-danger"></fa-icon>
                              </ng-template>
                            </span>
                          </div>
                          <div [class]="'col-1'+(model.callback_enabled?'0':'1')">
                            {{answer.text}}
                          </div>
                          <div class="col-1">
                            <a class="btn btn-sm btn-danger" href="#" (click)="deleteAnswer(i, k, $event)">
                              <fa-icon [icon]="icons.trash"></fa-icon>
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                  <ng-template #textOptions>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="1" id="answer_required_{{i}}"
                             [checked]="question.answer_required == 1"
                             (change)="question.answer_required = $event.target.checked?1:0">
                      <label class="form-check-label" for="answer_required_{{i}}" i18n>
                        Respuesta requerida
                      </label>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="question-form card card-body" *ngIf="auxQuestion">
          <div class="row">
            <div class="col-8">
              <div class="form-group">
                <label for="qtext" i18n>Texto de la pregunta</label>
                <input id="qtext" class="form-control" [(ngModel)]="auxQuestion.text" placeholder="Crear pregunta"
                       i18n-placeholder>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label for="qtype" i18n>Tipo</label>
                <select id="qtype" class="form-control" [(ngModel)]="auxQuestion.type">
                  <option *ngFor="let type of questionTypes?.names | keyvalue"
                          value="{{type.key}}">{{type.value}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8 offset-2">
              <a href="#" i18n class="btn btn-block btn-primary" (click)="addQuestion($event)">Añadir pregunta</a>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #manageSurvey>
        <div class="alert alert-danger" i18n>
          Las preguntas de la encuesta no pueden ser actualizadas porque ya han sido respondidas
        </div>
      </ng-template>
    </div>
  </div>
</div>
