import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from 'ng-wzb';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toggle-field',
  templateUrl: './toggle-field.component.html',
  styleUrls: ['./toggle-field.component.scss']
})
export class ToggleFieldComponent extends BaseComponent {
  @Input() model: any;
  @Input() field: string;

  @Output() toggled: EventEmitter<any> = new EventEmitter<any>();

  public icons = {
    1: faCheck,
    0: faTimes
  };

  /**
   * Function to toggle boolean property of model
   */
  toggleField() {
    this.model[this.field] = (!this.model[this.field] ? 1 : 0);
    const req = this.model.save().subscribe(
      response => {
        this.toggled.emit({model: this.model, field: this.field});
        req.unsubscribe();
      });
  }
}
