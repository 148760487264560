import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbItem, DataCollection} from 'ng-wzb';
import {Audience} from '../../models/audience';
import {StringHelper} from '../../helpers/string-helper';
import {AppInjector} from '../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {BaseIndexComponent} from '../base-index/base-index.component';


@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent extends BaseIndexComponent implements OnInit {

  public surveyId: string | boolean = false;
  public modelClass = Audience;

  public sh;
  public keyValPipe;

  public filters = {
    id: null,
    name: null,
    active: null
  }

  public sortOptions = [
    {sort: {id: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Id')},
    {sort: {id: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Id')},
    {sort: {name: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Name')},
    {sort: {name: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Name')},
    {sort: {active: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Active')},
    {sort: {active: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Active')},
    {sort: {created_at: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Created at')},
    {sort: {created_at: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Created at')}
  ];
  public sortParam = null;
  public sortDefaultParam = 1;

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Audiences'),
    }
  ];

  /**
   * inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.sh = StringHelper;
    this.keyValPipe = AppInjector.get(KeyValuePipe);
  }

  /**
   * Function to execute before load models
   * returns boolean. If it is false models are not loaded
   */
  beforeLoadModels() {
    const surveyId = this.route.snapshot.paramMap.get('surveyId');
    if (typeof surveyId !== 'undefined' && surveyId !== null) {
      this.surveyId = surveyId;
      this.collection.setFilter({
        survey_id:  this.surveyId
      }, true);
    }
    if (!this.sortParam) {
      this.sortParam = this.sortDefaultParam;
      const param = this.sortOptions[this.sortParam];
      this.collection.setSort(param.sort, false);
    }
    return true;
  }

  /**
   * Fucntion to sort collection
   * @param event trigger
   */
  sort(event) {
    if (this.sortParam !== null) {
      let param = this.sortOptions[this.sortParam];
      if (param) {
      } else {
        this.sortParam = this.sortDefaultParam;
        param = this.sortOptions[this.sortParam];
      }
      this.collection.setSort(param.sort);
    }
  }

  /**
   * Function to filter collection
   */
  filter() {
    this.collection.filter = this.composeFilter();
  }

  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.filters = {
      id: null,
      name: null,
      active: null
    }
    this.collection.filter = {};
  }

  /**
   * Function to prepare filter query
   */
  composeFilter() {
    const filters = [];
    this.keyValPipe.transform(this.filters).forEach((filter) => {
      const key = filter.key;

      // filter.value === 'null' is needed to exclude both value
      if (filter.value === null || filter.value === '' || filter.value === 'null') {
        return;
      }

      const q = {};
      switch (key) {
        case 'name':
          q[key] = {like: filter.value};
          break;
        default:
          q[key] = filter.value;
          break;

      }
      filters.push(q);

    });

    let filter = {};
    switch(filters.length){
      case 0:
        break;
      case 1:
        filter = filters[0];
        break;
      default:
        filter = {and: filters}
        break;
    }

    return filter;
  }
}
