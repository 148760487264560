import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent, LogService, BreadcrumbItem, DefaultsService} from 'ng-wzb';
import {Survey} from '../../../models/survey';
import {Audience} from '../../../models/audience';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {StringHelper} from '../../../helpers/string-helper';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import Swal from 'sweetalert2'
import {DateHelper} from '../../../helpers/date-helper';

@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.scss']
})
export class SurveyResultsComponent extends BaseComponent implements OnInit {

  @Input() surveyId: number | boolean = false;
  @Input() survey: Survey;

  public results = {};
  public audiences: Array<Audience>;
  public resultsLoaded = false;
  public noResults = false;
  public hasAudiences = false;
  public audienceIds: Array<number> = []
  public totalSpecimens = 0;
  public totalDone = 0;
  public totalPercent = 0;
  public questionTypes;
  public textAnswersPage = {};
  private exportData = [];
  public byDataSet = false;
  public byDataSetPage = 1;
  public dataSetResults = [];
  protected keyValPipe;
  public sh;
  public specimenRelation;
  public headerOrder: Array<string> = [];
  public showFilters = false;
  public filters = {
    gender_id: null,
    job: null,
    section: null,
    workplace: null,
    seniority_id: null,
    contract_type_id: null,
  }
  public filledFilters = {}
  public filterApplied = false;

  public associativeData = {
    genders: [],
    contractTypes: [],
    seniorities: [],
    jobs: [],
    sections: [],
    workplaces: [],
  };

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Surveys'),
      routerLink: '/gestor'
    },
    {
      label: this.i18n.get('Survey results')
    }
  ];

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.sh = StringHelper;
    const surveyId = this.route.snapshot.paramMap.get('id');
    if (typeof surveyId !== 'undefined' && surveyId !== null) {
      // tslint:disable-next-line:radix
      this.surveyId = parseInt(surveyId);
      this.loadSurvey();
    }
    super.ngOnInit();
  }

  /**
   * Function to load associative data
   */
  loadAssociativeData() {
    const valuesAttr = [
      'workplaces',
      'jobs',
      'sections'
    ];
    const req = this.as.getRequest('specimen', 'get-associative-data')
      .subscribe(
        response => {
          this.keyValPipe.transform(response.body).forEach((category) => {
            const valueKey = valuesAttr.indexOf(category.key) == -1 ? 'key' : 'value';
            this.keyValPipe.transform(category.value).forEach((item) => {
              this.associativeData[category.key].push({
                label: item.value,
                value: item[valueKey],
              });
            });
          });
          this.showFilters = true;
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  /**
   * Function to load survey
   */
  loadSurvey() {
    if (typeof this.survey !== 'undefined')
      return;

    this.survey = new Survey();

    // @ts-ignore
    const req = Survey.findOne(this.surveyId).subscribe(
      response => {
        this.survey = response;
        // tslint:disable-next-line:radix
        const showFilters = !this.survey.is_anonymous || !parseInt(DefaultsService.getValue('FULL_ANONYMOUS', 'GENERAL'));
        if (showFilters) {
          this.loadAssociativeData();
        }
        this.loadResults();
        req.unsubscribe();
      });
  }


  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.filterApplied = false;
    this.filters = {
      gender_id: null,
      job: null,
      section: null,
      workplace: null,
      seniority_id: null,
      contract_type_id: null,
    }
    this.loadResults();
  }

  filterResults() {
    this.filterApplied = true;
    this.loadResults();
  }

  /**
   * Function to load results data
   */
  loadResults(event = false) {
    if (event) {
      // @ts-ignore
      event.preventDefault();
    }
    this.resultsLoaded = false;

    if (this.audienceIds.length === 0) {
      this.audienceIds.push(this.survey.audiences[0].id);
    }
    const data: any = {ids: this.audienceIds};

    if (this.filterApplied) {
      data.filter = this.filters;
    }

    // @ts-ignore
    const req = this.as.getRequest('audience', 'get-results', data).subscribe(
      response => {
        this.initializeParams();
        this.instanceAudiences(response);
        this.calculateResults();
        req.unsubscribe();
      },
      error => {
        this.es.addError(error);
        req.unsubscribe();
      });

  }

  /**
   * Function to initialize results params
   */
  initializeParams() {
    this.audiences = [];
    this.totalSpecimens = 0;
    this.totalDone = 0;
    this.hasAudiences = false;
    this.results = {};
    this.textAnswersPage = {};
  }

  /**
   * Function to instance all audiences and calculate totals
   * @param response from get-results request
   */
  instanceAudiences(response) {
    const incongruities = [];
    response.body.forEach((audience, key) => {
      const aud = new Audience(audience);
      this.audiences.push(aud);

      if (aud.hasAnswerIncongruity) {
        incongruities.push('#' + aud.id + ' - ' + this.sh.truncateString(aud.name));
      }
      this.totalSpecimens += audience.specimenCont;
      this.totalDone += audience.doneCont;
    });
    if (incongruities.length > 0) {
      let msg = incongruities.join('<br>');
      msg += '<br><p class="text-danger">' + this.i18n.get('Please, contact with the administrator') + '</p>';

      Swal.fire({
        title: this.i18n.get('Results incongruity'),
        html: msg,
        icon: 'error',
      })
    }

    if (this.totalSpecimens > 0) {
      this.totalPercent = parseFloat(((this.totalDone / this.totalSpecimens) * 100).toFixed());
    } else {
      this.totalPercent = 0;
    }
    this.hasAudiences = this.audiences.length > 0;
  }

  /**
   * Function to calculate results group by dataset
   */
  calculateResultsByDataSet() {
    const questionIds = [];
    this.specimenRelation = {};
    this.noResults = this.totalDone === 0;
    this.dataSetResults = [];
    this.headerOrder = [];

    this.survey.questions.forEach((question, k) => {
      questionIds[question.id] = {
        cont: 0,
        question
      };
    });

    this.audiences.forEach((audience, q) => {
      if (!this.survey.is_anonymous) {
        audience.audienceSpecimens.forEach((auSpe, k) => {
          const specimen = auSpe.specimen;
          this.specimenRelation[auSpe.id] = {
            id_corporativa: specimen.corporate_id,
            especimen: specimen.fullName,
            email: specimen.email,
            telefono: specimen.phone,
            realizado: null
          };
          if (this.headerOrder.length === 0) {
            this.headerOrder = ['id_corporativa', 'especimen', 'email', 'telefono', 'realizado'];
          }
        });
      } else {
        if (this.headerOrder.length === 0) {
          this.headerOrder = ['realizado'];
        }
      }
      audience.answers.forEach((answer, key) => {
        const questionId = answer.survey_question_id;
        const question = questionIds[questionId].question;
        const dataSetId = this.survey.is_anonymous ? answer.dataset_id : answer.audience_specimen_id;

        if (typeof this.dataSetResults[dataSetId] === 'undefined') {
          this.dataSetResults[dataSetId] = {};
          // @ts-ignore
          const dateString = DateHelper.getDateObjectByTimestamp(answer.created_at).toLocaleString();
          if (this.survey.is_anonymous) {
            this.specimenRelation[dataSetId] = {realizado: dateString}
          } else {
            this.specimenRelation[dataSetId].realizado = dateString;
          }
        }

        if (typeof this.dataSetResults[dataSetId][questionId] === 'undefined') {
          // tslint:disable-next-line:triple-equals
          if (question.type == this.questionTypes.MULTIPLE) {
            this.dataSetResults[dataSetId][questionId] = [];
          } else {
            this.dataSetResults[dataSetId][questionId] = {};
          }
        }

        let answerText;
        // tslint:disable-next-line:triple-equals
        if (question.type == this.questionTypes.TEXT) {
          answerText = answer.value;
        } else {
          const answerObj = question.answers.find((ans) => {
            // tslint:disable-next-line:triple-equals
            return ans.id == answer.survey_answer_id;
          });
          answerText = answerObj.id;
        }
        // tslint:disable-next-line:triple-equals
        if (question.type == this.questionTypes.MULTIPLE) {
          this.dataSetResults[dataSetId][questionId].push(answerText);
        } else {
          this.dataSetResults[dataSetId][questionId] = answerText;
        }
      });
    });
  }

  /**
   * Function to calculate results params
   */
  calculateResults() {
    this.noResults = this.totalDone === 0;
    this.audiences.forEach((audience, q) => {
      audience.answers.forEach((answer, key) => {
        if (typeof this.results[answer.survey_question_id] === 'undefined') {
          this.results[answer.survey_question_id] = {
            hasOptions: true,
            total: 0,
            answers: []
          };
        }

        this.results[answer.survey_question_id].total++;

        if (answer.survey_answer_id) {
          if (typeof this.results[answer.survey_question_id].answers[answer.survey_answer_id] === 'undefined') {
            this.results[answer.survey_question_id].answers[answer.survey_answer_id] = {
              total: 0,
              percent: 0
            };
          }
          this.results[answer.survey_question_id].answers[answer.survey_answer_id].total++;

        } else {
          // text
          this.results[answer.survey_question_id].hasOptions = false;
          if (answer.value !== '') {
            this.results[answer.survey_question_id].answers.push(answer);
            if (typeof this.textAnswersPage[answer.survey_question_id] === 'undefined') {
              this.textAnswersPage[answer.survey_question_id] = {
                total: 0,
                currentPage: 0
              };
            }
            this.textAnswersPage[answer.survey_question_id].total++;
          }
        }
      });
    });


    this.keyValPipe.transform(this.results).forEach((item) => {
      const questionData = item.value;
      if (!questionData.hasOptions) {
        return;
      }

      this.keyValPipe.transform(questionData.answers).forEach((answer) => {
        const answerData = answer.value;
        answer.value.percent = ((answerData.total / this.totalDone) * 100).toFixed(2);
      });
    });
    this.resultsLoaded = true;
  }


  /**
   * Function to go next looping as carousel
   * @param questionId text question id
   * @param event trigger
   */
  next(questionId, event) {
    event.preventDefault();
    if (this.textAnswersPage[questionId].currentPage < this.textAnswersPage[questionId].total - 1) {
      this.textAnswersPage[questionId].currentPage++;
    } else {
      this.textAnswersPage[questionId].currentPage = 0;
    }
  }

  /**
   * Function to go back looping as carousel
   * @param questionId text question id
   * @param event trigger
   */
  back(questionId, event) {
    event.preventDefault();
    if (this.textAnswersPage[questionId].currentPage > 0) {
      this.textAnswersPage[questionId].currentPage--;
    } else {
      this.textAnswersPage[questionId].currentPage = this.textAnswersPage[questionId].total - 1;
    }
  }

  /**
   * Function to export survey results group by dataset
   * @param event trigger
   */
  exportResultsByDataSet(event) {
    event.preventDefault();
    const questionIds = [];
    this.exportData = [];
    this.calculateResultsByDataSet();
    const labels = {};
    const options = {};

    // const labels = {
    //   question: this.i18n.get('Question'),
    //   answer: this.i18n.get('Answer'),
    //   total: this.i18n.get('Total'),
    //   percent: this.i18n.get('Percent'),
    //   open_question: this.i18n.get('Open question'),
    // }
    this.survey.questions.forEach((question, k) => {
      questionIds[question.id] = question
    });

    this.dataSetResults.forEach((results, dataSetId) => {
      const exportData = this.specimenRelation[dataSetId];

      this.keyValPipe.transform(results).forEach((item, z) => {
        const questionId = item.key;
        const answerValue = item.value;
        const qText = questionIds[questionId].text;
        if (typeof labels[questionId] === 'undefined') {
          labels[questionId] = qText;
        }
        const question = questionIds[questionId];
        // tslint:disable-next-line:triple-equals
        if (question.type == this.questionTypes?.TEXT) {
          if (typeof options[questionId] === 'undefined') {
            // @ts-ignore
            options[questionId] = '';
          }
          exportData[questionId] = answerValue;
        } else {
          const questionOptions = question.answers;
          questionOptions.forEach((option, key) => {
            if (typeof options[questionId + '_' + option.id] === 'undefined') {
              // @ts-ignore
              options[questionId + '_' + option.id] = option.text;
            }

            if (typeof answerValue === 'string' || typeof answerValue === 'number') {
              // tslint:disable-next-line:triple-equals
              exportData[questionId + '_' + option.id] = (answerValue == option.id ? 1 : 0);
            } else {
              if (typeof answerValue === 'object') {
                answerValue.forEach((answ, q) => {
                  // tslint:disable-next-line:triple-equals
                  exportData[questionId + '_' + option.id] = (answ == option.id ? 1 : 0);
                });
              }
            }
          });
        }
      });
      this.exportData.push(exportData);

    });
    this.exportData.unshift(options);
    this.generateCSV(true, labels);
  }

  /**
   * Function to export shown results
   * @param event trigger
   */
  exportResults(event) {
    event.preventDefault();
    // const labels = {
    //   question: this.i18n.get('Question'),
    //   answer: this.i18n.get('Answer'),
    //   total: this.i18n.get('Total'),
    //   percent: this.i18n.get('Percent'),
    //   open_question: this.i18n.get('Open question'),
    // }
    this.exportData = [];
    this.headerOrder = [];
    let answerData;
    this.survey.questions.forEach((question, k) => {
      // tslint:disable-next-line:triple-equals
      const isText = question.type == this.questionTypes.TEXT;
      const openQ = (isText ? this.i18n.get('Yes') : this.i18n.get('No'));
      const answerBaseData = {
        pregunta: question.text,
        respuesta: '',
        total: 0,
        porcentaje: 0,
        pregunta_abierta: openQ
      };
      if (!isText) {

        question.answers.forEach((answer, q) => {
          answerData = Object.assign({}, answerBaseData);
          const answerResults = this.results[question.id]?.answers[answer.id] ? this.results[question.id]?.answers[answer.id] : false;
          this.addAnswerDataToExport(
            answerBaseData,
            answer.text,
            answerResults ? answerResults.total : 0,
            answerResults ? answerResults.percent : '0'
          );
        });
      } else {
        this.results[question.id].answers.forEach((answer, q) => {
          const percent = ((1 / this.totalDone) * 100).toFixed(2);
          this.addAnswerDataToExport(answerBaseData, answer.value, 1, percent);
        });
      }

    });
    this.generateCSV();
  }

  /**
   * Function to generate filename to export file
   */
  getFileName() {
    const audienceChars = (this.audienceIds.length > 1 ? this.audienceIds.length * 6 : 64);
    const surveyChars = 128 - audienceChars;
    let surveyName = this.sh.slugify(this.survey.title);
    surveyName = surveyName.substr(0, surveyChars);
    let fileName = surveyName;
    this.audiences.forEach((audience, key) => {
      // @ts-ignore
      if (!this.audienceIds.includes(String(audience.id))) {
        return;
      }
      const audienceName = '-' + this.sh.slugify(audience.name);
      fileName += audienceName.substr(0, audienceChars);
    });
    return fileName;
  }

  /**
   * Function to generate CSV and sent it to the browser
   * @param dataset optional. If is exported by dataset
   * @param headers optional. If is exported by dataset
   * @param fs optional. Data separator
   */
  generateCSV(dataset = false, headers: {} | boolean = false, fs = ',') {
    let ws;
    if (this.headerOrder.length > 0) {
      ws = XLSX.utils.json_to_sheet(this.exportData, {header: this.headerOrder});
    } else {
      ws = XLSX.utils.json_to_sheet(this.exportData);
    }

    if (headers) {
      const range = XLSX.utils.decode_range(ws['!ref']);
      let lastKey: string | boolean = false;
      for (let index = range.s.r; index <= range.e.c; ++index) {
        const cell = XLSX.utils.encode_col(index) + '1'; // <-- first row, column number C

        if (!ws[cell]) continue;

        const headerVal = ws[cell].v
        const chunks = headerVal.split('_');
        const qKey = chunks[0];

        if (lastKey !== qKey) {
          if (typeof headers[qKey] !== 'undefined') {
            ws[cell].v = headers[qKey];
          }
        } else {
          ws[cell].v = ' ';
        }
        lastKey = qKey;
      }

    }
    const csv = XLSX.utils.sheet_to_csv(ws, {FS: fs});// By default is ',' but I add this option for future
    const blob = new Blob([csv], {type: 'text/CSV;charset=UTF-8'});

    let fileName = this.getFileName();
    if (dataset) {
      fileName += '_conjunto';
    }
    saveAs(blob, fileName + '.csv');
  }

  /**
   * Function to add necessary results data to export
   * @param answerBaseData object of answer data, initialize with question data
   * @param answerText answer text
   * @param total total number of this answer
   * @param percent total percent of this answer
   */
  addAnswerDataToExport(answerBaseData, answerText, total, percent) {
    const answerData = Object.assign({}, answerBaseData);
    answerData.respuesta = answerText;
    answerData.total = total;
    answerData.porcentaje = percent;
    this.exportData.push(answerData);
  }
}
