import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {SurveyFormComponent} from './components/survey/survey-form/survey-form.component';
import {SurveyComponent} from './components/survey/survey.component';
import {UserRouterGuard, UsuarioRoutes, AdminRouterGuard, UsersComponent, AccountFormComponent, ProfileFormComponent, AssignmentFormComponent, UserInfoComponent} from 'ng-wzb';
import {AudienceComponent} from './components/audience/audience.component';
import {AudienceFormComponent} from './components/audience/audience-form/audience-form.component';
import {SpecimenComponent} from './components/specimen/specimen.component';
import {SpecimenFormComponent} from './components/specimen/specimen-form/specimen-form.component';
import {ConductSurveyComponent} from './components/conduct-survey/conduct-survey.component';
import {SurveyLayoutComponent} from './layouts/survey-layout/survey-layout.component';
import {SurveyManageComponent} from './components/survey/survey-manage/survey-manage.component';
import {SurveyResultsComponent} from './components/survey/survey-results/survey-results.component';
import {ImportSurveyComponent} from './components/survey/import-survey/import-survey.component';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {ImportSpecimensComponent} from './components/specimen/import-specimens/import-specimens.component';
import {SmsBalanceComponent} from './components/sms-balance/sms-balance.component';
import {ScheduledSendingComponent} from './components/scheduled-sending/scheduled-sending.component';
import {ScheduledSendingFormComponent} from './components/scheduled-sending/scheduled-sending-form/scheduled-sending-form.component';
import {ScheduledSendingSingleFormComponent} from './components/scheduled-sending/scheduled-sending-single-form/scheduled-sending-single-form.component';

// @ts-ignore
UsuarioRoutes.admin.component = AppLayoutComponent;
const routes: Routes = [
  {
    path: 'gestor',
    component: AppLayoutComponent,
    canActivateChild: [UserRouterGuard],
    children: [
      {path: 'audience', component: AudienceComponent},
      {path: 'audience/create', component: AudienceFormComponent},
      {path: 'audience/create/:surveyId', component: AudienceFormComponent},
      {path: 'audience/:id', component: AudienceFormComponent},
      {path: 'audience-survey/:surveyId', component: AudienceComponent},
      {path: 'specimen', component: SpecimenComponent},
      {path: 'specimen/create', component: SpecimenFormComponent},
      {path: 'specimen/:id', component: SpecimenFormComponent},
      {path: 'survey-manage/:id', component: SurveyManageComponent},
      {path: 'survey-results/:id', component: SurveyResultsComponent},
      {path: 'survey', component: SurveyComponent},
      {path: 'survey/create', component: SurveyFormComponent},
      {path: 'survey/:id', component: SurveyFormComponent},
      {path: 'import-survey', component: ImportSurveyComponent},
      {path: 'import-specimens', component: ImportSpecimensComponent},
      {path: 'sms', component: SmsBalanceComponent},
      {path: 'scheduled-sending', component: ScheduledSendingComponent},
      {path: 'scheduled-sending/create', component: ScheduledSendingFormComponent},
      {
        path: 'scheduled-sending/create/:surveyId/:audienceId/:specimenId',
        component: ScheduledSendingSingleFormComponent
      },
      {path: 'scheduled-sending/:id', component: ScheduledSendingFormComponent},
      UsuarioRoutes.myAccount,
      UsuarioRoutes.deleteAccount,
      {path: '', component: SurveyComponent},
    ], canActivate: [UserRouterGuard]
  },
  UsuarioRoutes.admin,
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {
    path: '',
    component: SurveyLayoutComponent,
    children: [
      {path: '', component: ConductSurveyComponent},
      {path: ':code', component: ConductSurveyComponent},
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
