import {Component, OnInit} from '@angular/core';
import {BaseComponent, LogService, BreadcrumbItem, DefaultsService} from 'ng-wzb';
import * as XLSX from 'xlsx';
import {Specimen} from '../../../models/specimen';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {DateHelper} from '../../../helpers/date-helper';
import {StringHelper} from '../../../helpers/string-helper';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-import-specimens',
  templateUrl: './import-specimens.component.html',
  styleUrls: ['./import-specimens.component.scss']
})
export class ImportSpecimensComponent extends BaseComponent implements OnInit {

  public file: any;
  public allowedExtensions = '.csv,.xls,.xlsx';
  private keyValPipe;
  public specimensLoaded = false;
  public specimens: Array<Specimen> = [];
  private dateAdapter: NgbDateAdapter<string>;
  public spinnerIcon = faSpinner;
  public showSpinner = false;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Import specimens')
    }
  ];

  public dh;

  public associativeData = {
    constants: {
      jobs: [],
      genders: [],
      contractTypes: [],
      seniorities: []
    },
    types: {
      jobs: [],
      genders: [],
      contractTypes: [],
      seniorities: []
    }
  };


  public rowReference = {
    A: 'corporate_id',
    B: 'first_name',
    C: 'lastname',
    D: 'second_lastname',
    E: 'birthdate',
    F: 'email',
    G: 'phone',
    H: 'gender_id',
    I: 'contract_type_id',
    J: 'seniority_id',
    K: 'job',
    L: 'section',
    M: 'workplace'
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.dateAdapter = AppInjector.get(NgbDateAdapter);
    this.dh = DateHelper;
    this.loadAssociativeData();
  }

  /**
   * Function to load associative data
   */
  loadAssociativeData() {
    const req = this.as.getRequest('specimen', 'get-associative-data', {full: true})
      .subscribe(
        response => {
          this.associativeData = response.body;
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  /**
   * Function to assign selected file
   * @param event trigger
   */
  fileChanged(event) {
    this.file = event.target.files[0];
  }

  /**
   * Function to read and process selected file
   */
  uploadDocument() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const workBook = XLSX.read(fileReader.result, {type: 'binary'});
      this.processData(workBook);
    }
    fileReader.readAsBinaryString(this.file);
  }

  /**
   * Function to process file data and create survey structure
   * @param workBook read workBook
   */
  processData(workBook) {
    this.specimens = [];
    let objDate;
    let keyId;
    let lastKey;
    let pushed = false;
    this.keyValPipe.transform(workBook.Sheets).forEach((sheet) => {
      const cells = sheet.value;
      let specimen: Specimen | false = false;
      // Object.keys instead keyValPipe to keep order
      Object.keys(cells).forEach((cellKey, key) => {

          const keyChunks = cellKey.match(/([A-Z])([\d]+)/);
          if (!keyChunks || keyChunks.length < 3) {
            return;
          }
          const letter = keyChunks[1];
          const index = keyChunks[2];
          // this line to exclude headers
          // tslint:disable-next-line:triple-equals
          if (index == '1') {
            return;
          }
          if (lastKey !== index) {
            specimen = new Specimen();
            pushed = false;
          }
          lastKey = index;

          const item = cells[cellKey];

          const property = this.rowReference[letter];
          let value = typeof item.w === 'string' ? item.w.trim() : item.w;

          switch (property) {
            case 'first_name':
            case 'lastname':
            case 'second_lastname':
            case 'job':
            case 'section':
            case 'workplace':
              value = value.toLowerCase();
              value = value.charAt(0).toUpperCase() + value.slice(1)
              break;
            case 'birthdate':
              objDate = this.dateAdapter.fromModel(value);
              objDate.year = this.completeYear(objDate.year);
              value = DateHelper.getPhpDateFromDatepickerObject(objDate);
              break;

            case 'phone':
              // put space to enable start with blankspace
              value = ' ' + value;
              const phoneMatchs = value.match(/(\s)([6-7][0-9]{2})\)?([ .,-]?)([0-9]{3})([ .,-]?)([0-9]{3})/g);

              if (phoneMatchs && phoneMatchs.length > 0) {
                value = phoneMatchs[0].replace(/\s/g, '').replace(/-/g, '').replace(/\./g, '').replace(/,/g, '');
              } else {
                value = '';
              }
              break;
            case 'gender_id':
              value = value.toUpperCase();
              if (value.length > 1) {
                value = value.charAt(0);
              }
              keyId = (value === 'M' ? 'FEMALE' : 'MALE');
              value = this.associativeData.constants.genders[keyId];
              break;
            case 'contract_type_id':
              // tslint:disable-next-line:radix
              value = parseInt(value);
              break;
            case 'seniority_id':
              objDate = this.dateAdapter.fromModel(value);
              objDate.year = this.completeYear(objDate.year);
              objDate.month = objDate.month - 1;

              const auxDate = new Date();
              auxDate.setFullYear(objDate.year, objDate.month, objDate.day);
              const years = DateHelper.diff_years(auxDate, new Date())
              switch (true) {
                case years <= 2:
                  keyId = 'LESS_THAN_2';
                  break;
                case years >= 3 && years <= 5:
                  keyId = 'FROM_3_TO_5';
                  break;
                case years >= 6 && years <= 10:
                  keyId = 'FROM_6_TO_10';
                  break;
                case years >= 11 && years <= 15:
                  keyId = 'FROM_11_TO_15';
                  break;
                case years >= 16 && years <= 20:
                  keyId = 'FROM_16_TO_20';
                  break;
                case years > 20:
                  keyId = 'MORE_THAN_21';
                  break;
              }
              value = this.associativeData.constants.seniorities[keyId];
              break;
          }
          specimen[property] = value;
          if(!pushed){
            // @ts-ignore
            this.specimens.push(specimen);
            pushed = true;
          }
        }
      );
    });
    this.specimensLoaded = true;
  }

  /**
   * Function to complete two digits year
   * @param year to complete
   */
  completeYear(year) {
    if (year.toString().length === 4) {
      return year;
    }
    const res = String(year);
    const curDate = new Date();
    const fullYear = curDate.getFullYear();
    // tslint:disable-next-line:radix
    const fullYearSplitted = parseInt(fullYear.toString().substr(-2));
    let prefix;
    if (year > fullYearSplitted) {
      prefix = '19';
    } else {
      if (year < 10) {
        prefix = '200';
      } else {
        prefix = '20';
      }
    }
    return prefix + res;
  }

  /**
   * Function to save model and redirect to edition
   */
  saveSpecimens() {
    this.showSpinner = true;
    // @ts-ignore
    const req = this.as.postRequest('specimen', 'import-specimens', {items: this.specimens}).subscribe(
      response => {
        if (response.body.success) {
          this.cancel();
          this.toastr.success(this.i18n.get('Specimens imported successfully'));
        } else {
          this.es.addErrorFromString(this.i18n.get('Specimens could not be imported'))
        }
        this.showSpinner = false;
        req.unsubscribe();
      },
      error => {
        this.es.addError(error);
        this.showSpinner = false;
        req.unsubscribe();
      });
  }

  /**
   * Function to initalize survey import
   */
  cancel() {
    this.file = null;
    this.specimensLoaded = false;
    this.specimens = [];
  }

  /**
   * Function to import specimens
   */
  importSpecimens() {
    this.saveSpecimens();
  }
}
