<div class="survey-index-component">
  <div class="row">
    <div class="col-sm-7">
      <h4 i18n class="mb-4 text-muted">Encuestas</h4>

    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <a routerLink="/gestor/survey/create" class="btn btn-primary" i18n>Nueva encuesta</a>
    </div>
    <div class="col-10">
      <div class="card mb4">
        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col-1" i18n>ID</div>
                <div class="col-4" i18n>Título</div>
                <div class="col-2 text-center" i18n>Activo</div>
                <div class="col-3" i18n>Creado</div>
                <div class="col-2">
                  <select class="form-control" [(ngModel)]="sortParam" (change)="sort($event)">
                    <option *ngFor="let opt of sortOptions; let key = index" [value]="key" [innerHTML]="opt.label"></option>
                  </select>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col-1"><input type="text" class="form-control" [(ngModel)]="filters.id"
                                          (change)="filter($event)">
                </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="filters.title"
                                          (change)="filter($event)"></div>
                <div class="col-2">
                  <select class="form-control" [(ngModel)]="filters.active" (change)="filter($event)">
                    <option value="null" i18n>Ambos</option>
                    <option value="1" i18n>Si</option>
                    <option value="0" i18n>No</option>
                  </select>
                </div>
                <div class="col-3">
                  <app-date-range-picker (dateSelect)="filter($event, true)"></app-date-range-picker>
                </div>
                <div class="col-2">
                  <a href="#" (click)="cleanFilters($event)" i18n class="btn btn-sm btn-warning float-right">Reset</a>
                </div>
              </div>
            </div>
            <ng-container *ngIf="collection?.models?.length > 0; else noResults">
              <div class="list-group-item" *ngFor="let survey of collection.models; let i = index">
                <div class="row">
                  <div class="col-1">{{survey.id}}</div>
                  <div class="col-4">
                    {{sh.truncateString(survey.title)}}
                    <div [class]="'text-'+(survey.is_anonymous?'info':'danger')">{{!survey.is_anonymous ? 'NO ' : ''}}
                      <span i18n>Anónima</span></div>
                  </div>
                  <div class="col-2 text-center">
                    <app-toggle-field [model]="survey" field="active"></app-toggle-field>
                  </div>
                  <div class="col-3">{{dh.getDateObjectByTimestampJs(survey.created_at).toLocaleString()}}</div>
                  <div class="col-2 text-right">
                    <div class="btn-group">
                      <ng-container *ngIf="survey.hasStarted; else editable">
                        <button class="btn-outline-secondary btn btn-sm" i18n
                                [routerLink]="['/gestor/survey-manage', survey.id]">Gestionar
                        </button>
                        <div class="btn-group" ngbDropdown role="group"
                             aria-label="Button group with nested dropdown">
                          <button class="btn btn-outline-secondary dropdown-toggle-split"
                                  ngbDropdownToggle></button>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <button ngbDropdownItem i18n
                                    [routerLink]="['/gestor/survey-results', survey.id]">Resultados
                            </button>
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #editable>
                        <button class="btn-outline-secondary btn btn-sm" i18n
                                [routerLink]="['/gestor/survey', survey.id]">Actualizar
                        </button>
                        <div class="btn-group" ngbDropdown role="group"
                             aria-label="Button group with nested dropdown">
                          <button class="btn btn-outline-secondary dropdown-toggle-split"
                                  ngbDropdownToggle></button>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <button ngbDropdownItem class="text-danger" i18n
                                    (click)="deleteModel(survey)">Borrar
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noResults>
              <div class="list-group-item">
                <p class="text-info" i18n> No hay elementos</p>
              </div>
            </ng-template>
          </div>
          <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize"
                          class="d-flex justify-content-center mt-2"
                          [(page)]="collection.page"
                          [pageSize]="collection.pageSize"
                          [collectionSize]="collection.totalRecords"
                          [maxSize]="5"
                          [rotate]="true">
          </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>


