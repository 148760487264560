<div class="survey-preview-container">
  <div *ngIf="showHeader && model" class="card mb-1">
    <div class="card-header">
      <div class="card-title">
        <h5>{{model.title}}</h5>
      </div>
      <div class="card-actions">
        <span *ngIf="showSubmitBtn" (click)="submitBtnClick()"
              class="btn btn-lightning rounded-0 float-right">{{submitBtnText}}</span>
        <span *ngIf="showCancelBtn" (click)="cancelBtnClick()"
              class="btn btn-outline-lightning rounded-0 mr-2 float-right">{{cancelBtnText}}</span>
      </div>
    </div>
  </div>
  <div class=" questions" *ngIf="model && model.questions">
    <div class="card card-body mb-3 question-info" *ngFor="let question of model.questions">
      <div class="row">
        <div class="col-9">
          <p>{{question.text}}</p>
        </div>
        <div class="col-3">
          <span class="badge badge-md badge-info rounded-0 float-right" *ngIf="questionTypesNames">
            {{questionTypesNames[question.type]}}
            <span *ngIf="question.type == questionTypes?.TEXT && question.answer_required"
                  i18n> | Respuesta requerida</span>
          </span>
        </div>
      </div>

      <ng-container *ngIf="question.type != questionTypes?.TEXT">
        <div class="list-group answers" *ngIf="question.answers; else noAnswers">
          <div class="list-group-item" *ngFor="let answer of question.answers">
               <span *ngIf="model.callback_enabled" class="notify-answer-icon mr-1"
                     [title]="'Notificación '+(answer.trigger_callback == 1?'activada':'desactivada')" (click)="toggleAnswer(answer)">
                  <fa-icon *ngIf="answer.trigger_callback == 1;else notNotify" [icon]="icons.notify"
                           class="text-success"></fa-icon>
                  <ng-template #notNotify>
                    <fa-icon [icon]="icons.notNotify" class="text-danger"></fa-icon>
                  </ng-template>
               </span>
            {{answer.text}}
          </div>
        </div>
      </ng-container>
      <ng-template #noAnswers>
        <div class="alert alert-warning" i18n>
          Esta pregunta no tiene respuestas
        </div>
      </ng-template>
    </div>

  </div>

</div>
