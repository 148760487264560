import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AppService} from '../../services/app.service';
import {DefaultsService, LogService} from 'ng-wzb';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit{

  title = 'pro-dashboard-angular';
  public useFileTransport:boolean;

  constructor(private appService: AppService) {

  }

  ngOnInit(): void {
    this.useFileTransport = DefaultsService.getValue('useFileTransport', 'GENERAL');
  }

  getClasses() {
    return {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    };
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  prepareRoute(outlet: RouterOutlet) {
    LogService.log(outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']);
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
