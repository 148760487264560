<div class="wrapper default-theme register-container row">
  <div class="col-md-4 col-sm-6 col-12 mb-4 align-items-stretch">
    <div class="card border-0 rounded-0 h-100">
      <div class="card-title mb-1 p-3">
        <h5 i18n>Registro</h5>
      </div>
      <div class="card-body">
        <ng-container *ngIf="!accountCreated">

          <form #signupForm="ngForm" (ngSubmit)="submitForm(signupForm)">
            <dynamic-ng-bootstrap-form *ngIf="formModel.length > 0" [group]="formGroup"
                                       [model]="formModel"></dynamic-ng-bootstrap-form>
            <button type="submit" class="btn btn-primary mb-2 mr-2" i18n>Crear cuenta</button>
            <button (click)="back()" class="btn btn-primary mb-2" i18n>Cancelar</button>
          </form>

        </ng-container>
        <ng-container *ngIf="accountCreated && requireConfirmation">

          <div i18n>Debe confirmar su email. Le hemos enviado un email con más detalles.</div>

        </ng-container>
      </div>
    </div>
  </div>
</div>

