<div class="conduct-survey-component">
  <ng-container *ngIf="showContent && !finished">
    <ng-container *ngIf="confirmedSpecimen; else confirm">
      <ng-container *ngIf="!start || !identity; else surveyQuestions">
        <div class="specimen-login-container start-step" *ngIf="!start; else identity">
          <div class="img-container text-center mb-5">
            <img src="./assets/imgs/logo.png" alt="lightning logo" class="nav-logo">
          </div>
          <div class="begin-btn" (click)="startSurvey($event)">
            <img src="./assets/imgs/question.svg" i18n-alt="Comenzar encuesta" class="img img-responsive">
            Comenzar
          </div>
        </div>
        <ng-template #identity>
          <div [class]="'identity-advice'+(survey.is_anonymous?' anonymous':'')">
            <fa-icon [icon]="infoIcon" size="lg"></fa-icon>
            <ng-container *ngIf="survey.is_anonymous; else noAnonymous">
              <h3 i18n>Encuesta 100% anónima</h3>
              <p i18n>Questio ha habilitado los mecanismos suficientes para garantizar el anonimato en esta encuesta, al
                mismo tiempo que certifica que usted la ha rellenado.</p>
            </ng-container>
            <ng-template #noAnonymous>
              <h3 i18n>Este formulario está asociado a tu identidad</h3>
              <p i18n>Pero tu información y privacidad se respetarán de acuerdo a los marcos legales.</p>
            </ng-template>
            <a href="#" (click)="identityAdvice($event)" class="btn btn-light">
              <span i18n>Continuar</span>
              <fa-icon [icon]="arrowIcon"></fa-icon>
            </a>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #surveyQuestions>
        <div *ngIf="survey && survey.questions.length > 0">

          <div class="col-12 mb-4 align-items-stretch">
            <div class="question-wrapper">
              <ng-container *ngIf="surveyStep <= surveyLastStep; else finishScreen">
                <div class="question">
                  <h4 class="question-text">{{survey.questions[surveyStep].text}}</h4>
                  <ng-container *ngIf="survey.questions[surveyStep].type == 3; else withOptions">
            <textarea class="form-control" id="answer_{{survey.questions[surveyStep].id}}"
                      [placeholder]="(survey.questions[surveyStep].answer_required?'Escriba aquí su respuesta para continuar': 'Si lo desea, escriba aquí su respuesta')"
                      [name]="'answer_'+survey.questions[surveyStep].id"
                      (change)="registerAnswer($event)" [ngModel]="specimenAnswers[survey.questions[surveyStep].id]"
            > </textarea>
                  </ng-container>
                  <ng-template #withOptions>
                    <div class="answers">
                      <div *ngFor="let answer of survey.questions[surveyStep].answers" class="answer">

                        <input id="answer_{{answer.id}}"
                               [name]="'answer_'+survey.questions[surveyStep].id+'[]'"
                               [type]="survey.questions[surveyStep].type == questionTypes?.UNIQUE ?'radio':'checkbox'"
                               [value]="answer.id"
                               (change)="registerAnswer($event)"
                               [checked]="survey.questions[surveyStep].type == questionTypes?.UNIQUE ?specimenAnswers[survey.questions[surveyStep].id] == answer.id:specimenAnswers[survey.questions[surveyStep].id] && specimenAnswers[survey.questions[surveyStep].id].includes(answer.id)"
                        >
                        <label for="answer_{{answer.id}}">
                      <span
                        [class]="'custom-radio'+(survey.questions[surveyStep].type == questionTypes?.MULTIPLE?' custom-check':'') "></span>
                          {{answer.text}}
                        </label>

                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="conduct-survey-nav mt-5">
                  <a *ngIf="surveyStep > 0" [class]="'btn float-left '+btnClass"
                     (click)="back($event)" i18n href="#">Atrás</a>
                  <a *ngIf="surveyStep <= surveyLastStep"
                     [class.disabled]="!showNextBtn"
                     [class]="'btn float-right '+btnClass"
                     (click)="next($event)" href="#" i18n>Siguiente</a>
                </div>
              </ng-container>
              <ng-template #finishScreen>
                <div class="question final-step">
                  <h4 class="question-text">Ya casi has terminado, puedes ir atrás para revisar tus preguntas o hacer
                    click en finalizar para
                    enviar las respuestas.</h4>
                </div>
                <div class="conduct-survey-nav mt-5">
                  <a *ngIf="surveyStep > 0" [class]="'btn float-left '+btnClass"
                     (click)="back($event)" i18n href="#">Atrás</a>
                  <a href="#" *ngIf="surveyStep > surveyLastStep" [class]="'btn float-right '+btnClass"
                     [class.disabled]="!allowSend" (click)="sendSurvey($event)" i18n>Finalizar</a>
                </div>
              </ng-template>
            </div>

          </div>
          <div *ngIf="surveyStep <= surveyLastStep" class="progress-container text-center">
            <div class="progress-value">{{progress}}%</div>
            <ngb-progressbar [value]="progress" [showValue]="false"></ngb-progressbar>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #confirm>
      <div class="specimen-login-container wrapper default-theme">
        <div class="img-container text-center mb-5">
          <img src="./assets/imgs/logo.png" alt="lightning logo" class="nav-logo">
        </div>
        <div class="card col-md-4 col-sm-6 mb-4 align-items-stretch">
          <div class="card-header">
            <div class="card-title">
              <h5 i18n>Realizar encuesta</h5>
            </div>
          </div>
          <div class="card-body">
            <p i18n>Por favor, introduzca su pin personal para comenzar la encuesta</p>
            <form (ngSubmit)="confirmSpecimen()">
              <dynamic-ng-bootstrap-form *ngIf="formModel.length > 0" [group]="formGroup"
                                         [model]="formModel"></dynamic-ng-bootstrap-form>
              <button i18n type="submit" id="submitGeneral"
                      class="btn btn-lightning mb-2 mr-2 float-right">Ir a la encuesta
              </button>
            </form>
            <ng-container *ngIf="confirmError">
              <p class="text-danger" i18n>No es posible realizar la encuesta</p>
            </ng-container>
          </div>
        </div>
      </div>

    </ng-template>
  </ng-container>
  <ng-container *ngIf="showContent && finished">
    <div class="done-alert">
      <h4 i18n>Gracias</h4>
      <p>Su encuesta ha sido enviada correctamente.</p>
    </div>
  </ng-container>
</div>
