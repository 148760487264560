<div class="scheduled-sending-index-component">
  <div class="row">
    <div class="col-sm-7">
      <h4 i18n class="mb-4 text-muted">Envío programado</h4>

    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <a [routerLink]="['/gestor/scheduled-sending/create/']" class="btn btn-primary" i18n>Nuevo envío programado</a>
    </div>
    <div class="col-10">
      <div class="card mb4">
        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col-1" i18n>ID</div>
                <div class="col-3" i18n>Encuesta</div>
                <div class="col-2" i18n>Audiencia</div>
                <div class="col-2" i18n>Tipo | Fecha envío</div>
                <div class="col-2 text-center" i18n>Procesado</div>
                <div class="col-2">
                  <select class="form-control" [(ngModel)]="sortParam" (change)="sort($event)">
                    <option *ngFor="let opt of sortOptions; let key = index" [value]="key"
                            [innerHTML]="opt.label"></option>
                  </select>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col-1">
                  <input type="text" class="form-control" [(ngModel)]="filters.id" (change)="filter()">
                </div>
                <div class="col-3">
                  <wzb-ng-select-extended *ngIf="surveys.length > 0" id="survey_id" [items]="surveys" bindLabel="label"
                                          bindValue="value" [(model)]="filters.survey_id"
                                          (modelChange)="filter()"></wzb-ng-select-extended>
                </div>
                <div class="col-2">
                  <wzb-ng-select-extended  *ngIf="audiences.length > 0" id="audience_id" [items]="audiences" bindLabel="label"
                                          bindValue="value" [(model)]="filters.audience_id"
                                          (modelChange)="filter()"></wzb-ng-select-extended>
                </div>
                <div class="col-2">
                  <wzb-ng-select-extended  *ngIf="contactTypes.length > 0" id="type" [items]="contactTypes" bindLabel="label"
                                           bindValue="value" [(model)]="filters.type"
                                           (modelChange)="filter()"></wzb-ng-select-extended>
                </div>
                <div class="col-2">
                  <select class="form-control text-center" [(ngModel)]="filters.processed" (change)="filter()">
                    <option value="null" i18n>Ambos</option>
                    <option value="1" i18n>Si</option>
                    <option value="0" i18n>No</option>
                  </select>
                </div>
                <div class="col-2">
                  <a href="#" (click)="cleanFilters($event)" i18n class="btn btn-sm btn-warning float-right">Reset</a>
                </div>
              </div>
            </div>
            <ng-container *ngIf="collection?.models?.length > 0; else noResults">
              <div class="list-group-item" *ngFor="let scheduledSending of collection.models; let i = index">
                <div class="row">
                  <div class="col-1">{{scheduledSending.id}}</div>
                  <div class="col-3">
                    <a href="#"
                       [routerLink]="['/gestor/survey/'+scheduledSending.survey_id]">{{sh.truncateString(scheduledSending.survey?.title)}}</a>
                  </div>

                  <div class="col-2" i18n>
                    <a href="#"
                       [routerLink]="['/gestor/audience/'+scheduledSending.audience_id]">{{sh.truncateString(scheduledSending.audience?.name)}}</a>
                  </div>
                  <div class="col-2">
                    <div>{{scheduledSending.type == 1?'Email':'Sms'}}</div>
                    {{dh.getDateObjectByTimestamp(scheduledSending.dispatch_date).toLocaleString()}}
                  </div>
                  <div class="col-2 text-center">{{scheduledSending.processed ? 'Si' : 'No'}}</div>
                  <div class="col-2">
                    <div class="btn-group">
                      <button class="btn-outline-secondary btn btn-sm" i18n
                              [routerLink]="['/gestor/scheduled-sending', scheduledSending.id]">{{!scheduledSending.processed ? 'Actualizar' : 'Ver'}}
                      </button>
                      <div class="btn-group" ngbDropdown role="group"
                           aria-label="Button group with nested dropdown" *ngIf="!scheduledSending.processed">
                        <button class="btn btn-outline-secondary dropdown-toggle-split"
                                ngbDropdownToggle></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <button ngbDropdownItem class="text-danger" i18n
                                  (click)="deleteModel(scheduledSending)">Borrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noResults>
              <div class="list-group-item">
                <p class="text-info" i18n> No hay elementos</p>
              </div>
            </ng-template>
          </div>
          <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize"
                          class="d-flex justify-content-center mt-2"
                          [(page)]="collection.page"
                          [pageSize]="collection.pageSize"
                          [collectionSize]="collection.totalRecords"
                          [maxSize]="5"
                          [rotate]="true">
          </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>
