import {Model} from 'ng-wzb';

export class AudienceSpecimenContact extends Model{
  static entity = 'audience-specimen-contact';
  entity = 'audience-specimen-contact';

  public audience_specimen_id: number;
  public sent_at: number;
  public type: number;
  public data: string;
}
