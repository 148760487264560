import {Component, OnInit} from '@angular/core';
import {
  BaseFormComponent,
  BreadcrumbItem,
  DataCollection,
  ErrorMessages
} from 'ng-wzb';
import {AudienceSpecimenContact} from '../../../models/audience-specimen-contact';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {DynamicDatePickerModel, DynamicFormControlModel, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {DateHelper} from '../../../helpers/date-helper';
import {ScheduledSending} from '../../../models/scheduled-sending';
import {ScheduledSendingItem} from '../../../models/scheduled-sending-item';
import Swal from "sweetalert2";

@Component({
  selector: 'app-scheduled-sending-single-form',
  templateUrl: './scheduled-sending-single-form.component.html',
  styleUrls: ['./scheduled-sending-single-form.component.scss']
})
export class ScheduledSendingSingleFormComponent extends BaseFormComponent implements OnInit {
  public model: ScheduledSending;
  public modelClass = ScheduledSending;

  public hours = [];
  public minutes = [];
  public contactTypes = [];
  public editionEnabled = true;
  public keyValPipe;
  public previous;
  public smsBalance: number;
  public specimen_id;
  private addItemAction = 'single-item'

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Scheduled sending'),
      routerLink: '/gestor/scheduled-sending'
    },
    {
      label: this.i18n.get('Single scheduled sending edition')
    }
  ];

  public fieldGroups = [
    ['dispatchDatePick', 'hour'],
    ['type', 'minute'],
  ];

  ngOnInit(): void {
    super.ngOnInit();
    this.previous = this.as.previousRoute;
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.fillTimingArrays();
    this.loadSmsBalance();
    this.loadContactTypes();
  }

  /**
   * Funtion to fill available hours and minutes
   */
  fillTimingArrays() {
    for (let i = 0; i < 24; i++) {
      this.hours.push({value: i, label: i.toString().padStart(2, '0')});
    }
    for (let i = 0; i < 60; i += 15) {
      this.minutes.push({value: i, label: i.toString().padStart(2, '0')});
    }
  }

  /**
   * Function to get sms balance
   */
  loadSmsBalance() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'sms-balance')
      .subscribe(
        response => {
          this.smsBalance = parseInt(response.body.total);
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to get sms balance
   */
  loadContactTypes() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'contact-types')
      .subscribe(
        response => {
          new Promise((resolve) => {
            this.keyValPipe.transform(response.body).forEach((type) => {
              this.contactTypes.push({
                label: type.value,
                value: parseInt(type.key),
              });
            });
            resolve();
          }).then(() => {
            this.reset();
            req.unsubscribe();
          });
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  afterGetFormModel(fullFormModel: Array<DynamicFormControlModel>) {
    const date = new Date();
    const minAllowedDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    fullFormModel.push(
      new DynamicSelectModel({
        id: 'type',
        label: this.i18n.get('Type'),
        placeholder: this.i18n.get('Select a type'),
        filterable: true,
        value: this.model.type,
        disabled: !this.editionEnabled || !this.isNewRecord,
        validators: {
          required: null,
        },
        options: this.contactTypes,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicDatePickerModel({
        id: 'dispatchDatePick',
        label: this.i18n.get('Dispatch date'),
        value: this.model.dispatchDatePick,
        disabled: !this.editionEnabled,
        min: minAllowedDate,
        additional: {
          classCss: 'date-picker'
        },
        // toggleIcon: './../../assets/fontawesome-pro-5.10.2-web/svgs/light/calendar-alt.svg',
        validators: {
          required: null,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicSelectModel({
        id: 'hour',
        label: this.i18n.get('Hour'),
        placeholder: this.i18n.get('Select an hour'),
        filterable: true,
        value: this.model.hour,
        disabled: !this.editionEnabled,
        validators: {
          required: null,
        },
        options: this.hours,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicSelectModel({
        id: 'minute',
        label: this.i18n.get('Minute'),
        placeholder: this.i18n.get('Select a minute'),
        filterable: true,
        value: this.model.minute,
        disabled: !this.editionEnabled,
        validators: {
          required: null,
        },
        options: this.minutes,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      })
    );

  }

  /**
   * Function to check if dispatch date is more than an hour
   */
  checkHourLimit() {
    if (!this.model.dispatch_date) {
      return true;
    }
    const currDate = new Date();
    const selectedDate = DateHelper.getDateObjectByTimestamp(this.model.dispatch_date);
    let diff = (selectedDate.getTime() - currDate.getTime()) / 1000;
    diff /= (60 * 60);
    return diff > 1;
  }

  /**
   * Function to execute after load model
   */
  afterLoadModel(response) {
    super.afterLoadModel(response);
    this.editionEnabled = this.isNewRecord || (this.model.processed == 0 && this.checkHourLimit());

    const surveyId = this.route.snapshot.paramMap.get('surveyId');
    const audienceId = this.route.snapshot.paramMap.get('audienceId');
    const specimenId = this.route.snapshot.paramMap.get('specimenId');
    if (surveyId) {
      // tslint:disable-next-line:radix
      this.model.survey_id = parseInt(surveyId);
    }
    if (audienceId) {
      // tslint:disable-next-line:radix
      this.model.audience_id = parseInt(audienceId);
    }

    if (specimenId) {
      // tslint:disable-next-line:radix
      this.specimen_id = parseInt(specimenId);
    }

    return true;
  }

  /**
   * Function to execute after save model
   */
  afterSaveModel() {
    const data = {
      id: this.model.id,
      audience_id: this.model.audience_id,
      specimen_id: this.specimen_id,
    };

    const req = this.as.postRequest(ScheduledSendingItem.entity, this.addItemAction, data)
      .subscribe(
        response => {
          if (response.body.success) {
            this.toastr.success(this.i18n.get('Specimen added successfully'));
          } else {
            let error = '';
            if(typeof response.body.errors !== 'undefined') {
              error = ': '+response.body.errors;
            }
            this.toastr.error(this.i18n.get('An error occurred adding specimen')+error);
          }
          this.router.navigate(['/gestor/scheduled-sending/' + this.model.id]);
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
    return true;
  }


  /**
   * Function to execute before save model
   * returns boolean. If it is false model does not save
   *
   */
  beforeSaveModel() {
    if (!super.beforeSaveModel()) {
      return false;
    }
    const rightHourLimit = this.checkHourLimit();
    if (!rightHourLimit) {
      Swal.fire({
        title: this.i18n.get('Not allowed'),
        text: this.i18n.get('You can not add scheduled sending if configured date is less than an hour to send.'),
        icon: 'error',
      });
    }
    return rightHourLimit;
  }

}
