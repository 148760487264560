import {Model, ErrorMessages, I18nService} from 'ng-wzb';
import {
  DynamicDatePickerModel,
  DynamicFormControlModel,
  DynamicInputModel
} from '@ng-dynamic-forms/core';
import {AppInjector} from '../app-injector';
import {DateHelper} from '../helpers/date-helper';
import {NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';

export class Specimen extends Model {
  static entity = 'specimen';
  entity = 'specimen';

  public first_name: string;
  public lastname: string;
  public second_lastname: string;
  public email: string;
  public corporate_id: string;
  public phone: string;
  public job: string;
  public section: string;
  public workplace: string;
  public gender_id: number;
  public seniority_id: number;
  public contract_type_id: number;
  public birthdate: number;
  public fullName: string;
  public gender: string;
  public seniority: string;
  public contractType: string;
  public hasParticipate: boolean;
  private _birthdatePick;
  private _age;
  private dateAdapter: NgbDateAdapter<string>;


  /**
   *  Returns and initialize if it is necessary bithdatePick
   */
  public get birthdatePick() {
    if (typeof this._birthdatePick === 'undefined') {
      const date = new Date();
      if (typeof this.birthdate !== 'undefined' && this.birthdate !== null) {
        const jsTimestamp = this.birthdate * 1000;
        date.setTime(jsTimestamp);
      } else {
        date.setFullYear(date.getUTCFullYear() - 18);
      }
      if (typeof this.dateAdapter === 'undefined') {
        this.dateAdapter = AppInjector.get(NgbDateAdapter);
      }

      const objDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      this._birthdatePick = this.dateAdapter.toModel(objDate);

    }
    return this._birthdatePick;
  }

  /**
   *  Function to set bithdatePick value and update birthdate and age with converted value
   * @param value to set birthdatePick
   */
  public set birthdatePick(value) {
    if (typeof this.dateAdapter === 'undefined') {
      this.dateAdapter = AppInjector.get(NgbDateAdapter);
    }
    this._birthdatePick = value;

    const objDate = this.dateAdapter.fromModel(this.birthdatePick);
    this.birthdate = DateHelper.getPhpDateFromDatepickerObject(objDate);
    this.calculateAge();
  }

  /**
   * Return specimen age
   */
  public get age() {
    if (typeof this._age === 'undefined' || !this._age) {
      this.calculateAge();
    }
    return this._age;
  }

  /**
   * Function to calculate specimen age in moment
   */
  protected calculateAge() {
    if(!this.birthdate){
      return false;
    }
    const jsTimestamp = this.birthdate * 1000;
    const date = new Date();
    date.setTime(jsTimestamp);

    const diffMs = Date.now() - date.getTime();
    const ageDt = new Date(diffMs);

    this._age = Math.abs(ageDt.getUTCFullYear() - 1970);
  }

  /**
   * Function to return all instanced form inputs
   */
  public getFormModel(): Array<DynamicFormControlModel> {
    this.i18n = AppInjector.get(I18nService);

    return [
      new DynamicInputModel({
        id: 'corporate_id',
        label: this.i18n.get('Corporate ID'),
        value: this.corporate_id,
        disabled: this.hasParticipate && this.corporate_id !== '' && this.corporate_id !== null,
        validators: {
          maxLength: 32,
          required: null,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
      new DynamicInputModel({
        id: 'email',
        label: this.i18n.get('Email'),
        value: this.email,
        validators: {
          maxLength: 255,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['maxLength'])
      }),
      new DynamicInputModel({
        id: 'phone',
        label: this.i18n.get('Phone'),
        value: this.phone,
        validators: {
          maxLength: 28,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['maxLength'])
      }),
      new DynamicDatePickerModel({
        id: 'birthdatePick',
        label: this.i18n.get('Birthdate'),
        value: this.birthdatePick,
        additional: {
          classCss: 'date-picker'
        },
        // toggleIcon: './../../assets/fontawesome-pro-5.10.2-web/svgs/light/calendar-alt.svg',
        validators: {
          required: null,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicInputModel({
        id: 'first_name',
        label: this.i18n.get('First name'),
        value: this.first_name,
        disabled: this.hasParticipate,
        validators: {
          required: null,
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
      new DynamicInputModel({
        id: 'lastname',
        label: this.i18n.get('Lastname'),
        value: this.lastname,
        disabled: this.hasParticipate,
        validators: {
          required: null,
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
      new DynamicInputModel({
        id: 'second_lastname',
        label: this.i18n.get('Second lastname'),
        value: this.second_lastname,
        disabled: this.hasParticipate && this.second_lastname !== '' && this.second_lastname !== null,
        validators: {
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['maxLength'])
      }),
      new DynamicInputModel({
        id: 'job',
        label: this.i18n.get('Job'),
        value: this.job,
        validators: {
          required: null,
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
      new DynamicInputModel({
        id: 'section',
        label: this.i18n.get('Section'),
        value: this.section,
        validators: {
          required: null,
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
      new DynamicInputModel({
        id: 'workplace',
        label: this.i18n.get('Workplace'),
        value: this.workplace,
        validators: {
          required: null,
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
    ];
  }

}
