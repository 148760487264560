import {LogService, AuthInterceptor as BaseAuthInterceptor} from 'ng-wzb';
import {EMPTY} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor extends BaseAuthInterceptor{

  errorResponse(response) {
    console.warn('ERROR RESPONSE - '+ response.status);
    switch (response.status) {
      case 401:
      case 403:
        const url = '/login';
        LogService.warn('REDIRECT FROM AUTH INTERCEPTOR RESPONSE STATUS ' + response.status);
        this.router.navigate([url]);
        break;
      default:
        this.es.addError(response);
        break;
    }
    return EMPTY;
  }

}
