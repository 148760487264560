<div *ngIf="!confirm" class="wrapper default-theme login-container ">
  <div class="col-md-4 col-sm-6 col-12 mb-4 align-items-stretch">
    <div class="img-container text-center mb-5">
      <img src="./assets/imgs/logo.png" alt="lightning logo" class="nav-logo">
    </div>
    <div class="card border-0 rounded-0 h-100">
      <div class="card-body">
        <form (ngSubmit)="submitLogin()">

          <dynamic-ng-bootstrap-form *ngIf="formModel.length > 0" [group]="formGroup"
                                     [model]="formModel"></dynamic-ng-bootstrap-form>
          <button type="submit" class="btn btn-primary mb-2 mr-2" i18n>Acceder</button>
          <button (click)="back()" class="btn btn-primary mb-2" i18n>Cancelar</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="confirm" class="wrapper default-theme login-container ">
  <div class="col-md-4 col-sm-6 col-12 mb-4 align-items-stretch">
    <div class="card border-0 rounded-0 h-100">
      <div class="card-title mb-1 p-3">
        <h5 i18n>Confirm</h5>
      </div>
      <div class="card-body">
        <form (ngSubmit)="confirmLogin()">
          <dynamic-ng-bootstrap-form *ngIf="confirmFormModel.length > 0" [group]="confirmFormGroup"
                                     [model]="confirmFormModel"></dynamic-ng-bootstrap-form>
          <button type="submit" class="btn btn-primary mb-2 mr-2" i18n>Confirmar</button>
          <button (click)="confirm = false" class="btn btn-primary mb-2" i18n>Cancelar</button>
        </form>
      </div>
    </div>
  </div>
</div>

