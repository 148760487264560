import {Model} from 'ng-wzb';
import {SurveyQuestion} from './survey-question';
import {Audience} from './audience';

export class Survey extends Model {
  static entity = 'survey';
  entity = 'survey';

  public title: string;
  public active: number;
  public questions:  Array<SurveyQuestion> = [];
  public audiences:  Array<Audience> = [];
  public audienceCount:  number;
  public hasAnswers:  boolean;
  public hasStarted:  number;
  public is_anonymous:  number;
  public callback_enabled:  number;
  public callback_emails:  string;
  public callback_text:  string;
  public full = false;


  getFormModel() {
    return [];
  }

}
