<div class="wrapper survey-theme" [ngClass]="getClasses()">
  <main>
    <div class="pages container-fluid pt-4 pb-4 pl-4 pr-4 ">
      <div class="pages-inner"  >
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
</div>
