<div class="import-specimens-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted">
        <span i18n>Importar especímenes</span>
      </h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row" *ngIf="!specimensLoaded; else preview">
    <div class="col-12">
      <div class="card">
        <div class="survey-form card-body">

          <form (ngSubmit)="uploadDocument()">
            <div class="row">
              <div class="col-6">

                <div class="custom-file" id="customFile" lang="es">
                  <input type="file" [accept]="allowedExtensions" class="custom-file-input" id="file"
                         aria-describedby="fileHelp" (change)="fileChanged($event)">
                  <label class="custom-file-label" for="file">
                    <ng-container *ngIf="this.file;else filePlaceholder">{{this.file.name}}</ng-container>
                    <ng-template #filePlaceholder>
                      <span i18n>Seleccione archivo</span>
                    </ng-template>
                  </label>
                </div>
              </div>
              <div class="col-6">
                <button i18n type="submit" id="submitGeneral"
                        class="btn btn-lightning rounded-0 mb-2 mr-2">Previsualizar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ng-template #preview>
    <div class="card">
      <div class="card-header">
        <div class="card-actions">
          <p *ngIf="showSpinner" class="text-success float-left"><fa-icon [spin]="true" [icon]="spinnerIcon"></fa-icon> Procesando...</p>
          <span (click)="importSpecimens()" class="btn btn-lightning rounded-0 float-right" i18n>Importar</span>
          <span (click)="cancel()" class="btn btn-outline-lightning rounded-0 mr-2 float-right" i18n>Cancelar</span>
        </div>
      </div>
      <div class="card-body">
        <div class="list-group">
          <div class="list-group-item" *ngFor="let specimen of specimens">
            <div class="row">
              <div class="col-6">
                {{specimen.first_name}} {{specimen.lastname}} {{specimen.second_lastname}}
                <div>
                  <span i18n>ID Corp.:</span> #
                  <app-not-defined-field [model]="specimen" attr="corporate_id"></app-not-defined-field>
                  |
                  <span i18n>F.nacimiento: </span>
                  <ng-container *ngIf="specimen.birthdate; else noBirthdate">
                    <span>{{dh.getDateObjectByTimestamp(specimen.birthdate).toLocaleDateString()}}</span>|
                    <span i18n>Edad:</span> {{specimen.age}}
                  </ng-container>
                  <ng-template #noBirthdate>
                    <span class="text-danger">No definido</span>
                  </ng-template>
                </div>
                <div>
                  <span i18n>Email: </span>
                  <app-not-defined-field [model]="specimen" attr="email"></app-not-defined-field>
                </div>
                <div>
                  <span i18n>Teléfono: </span>
                  <app-not-defined-field [model]="specimen" attr="phone"></app-not-defined-field>
                </div>
              </div>
              <div class="col-6">
                <ul>
                  <li>
                    <app-not-defined-field
                      [attr]="associativeData.types.genders[specimen.gender_id]" noResultText="Género"></app-not-defined-field>
                  </li>
                  <li>
                    <app-not-defined-field [model]="specimen" attr="job"
                                           noResultText="Puesto de trabajo"></app-not-defined-field>
                  </li>
                  <li>
                    <app-not-defined-field [model]="specimen" attr="section"
                                           noResultText="Sección"></app-not-defined-field>
                    |
                    <app-not-defined-field [model]="specimen" attr="workplace"
                                           noResultText="Planta"></app-not-defined-field>
                  <li>
                    <app-not-defined-field
                      [attr]="associativeData.types.seniorities[specimen.seniority_id]" noResultText="Antigüedad"></app-not-defined-field>
                  </li>
                  <li>
                    <app-not-defined-field
                      [attr]="associativeData.types.contractTypes[specimen.contract_type_id]" noResultText="Tipo contrato"></app-not-defined-field>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
</div>
