import {Component, Input, OnInit} from '@angular/core';
import {Survey} from '../../../models/survey';
import {BaseFormComponent, BreadcrumbItem, DefaultsService, ModalDialogComponent} from 'ng-wzb';
import {SurveyQuestion} from '../../../models/survey-question';
import {faTrash, faBell, faBellSlash} from '@fortawesome/free-solid-svg-icons';
import {SurveyAnswer} from '../../../models/survey-answer';
import {DragulaService} from 'ng2-dragula';
import {AppInjector} from '../../../app-injector';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent extends BaseFormComponent implements OnInit {

  @Input() model: Survey;
  public modelClass = Survey;

  public auxQuestion: SurveyQuestion;
  public questionTypes = {
    names: {},
    constants: {}
  };
  public dragulaService;
  public sortQuestions = false;
  public questionBag = 'QUESTIONS';
  public answerBagPrefix = 'ANSWERS_';

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Surveys'),
      routerLink: '/gestor'
    },
    {
      label: this.i18n.get('Survey edition')
    }
  ];

  public icons = {
    trash: faTrash,
    notify: faBell,
    notNotify: faBellSlash,
  };

  public services;
  public modelLoaded = false;

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.dragulaService = AppInjector.get(DragulaService);
    this.questionTypes.constants = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    this.questionTypes.names = DefaultsService.getValue('QuestionTypesNames', 'GENERAL');
  }

  /**
   * Function to bind exixsting answers sort event
   */
  bindExistingAnswersSort() {
    this.model.questions.forEach((item, key) => {
      this.bindSortableEvent(this.answerBagPrefix + key);
    });
  }

  /**
   * Function to enable/disable questions sort
   */
  setQuestionsSortable() {
    if (this.sortQuestions) {
      this.bindSortableEvent(this.questionBag);
    }
  }

  /**
   * Initialize sortable if enable
   */
  bindSortableEvent(bag) {
    this.dragulaService.dropModel(bag).subscribe((value) => {
      this.setPositions(value.targetModel, bag);
    });

  }

  /**
   * Function to set items positions
   * @param order array with sort result
   * @param bag current bag
   */
  setPositions(order, bag) {
    let items = [];
    // is needed to set origin items to fix multiple sort bug
    if (bag === this.questionBag) {
      this.model.questions = order;
      items = this.model.questions;
    } else {
      const idx = bag.replace(this.answerBagPrefix, '');
      this.model.questions[idx].answers = order;
      items = this.model.questions[idx].answers;
    }
    items.forEach((item, key) => {
      order.forEach((ord, k) => {
        if ((typeof (item.id) !== 'undefined' && item.id === ord.id) ||
          (typeof (item.tempId) !== 'undefined' && item.tempId === ord.tempId)) {
          item.position = k;
        }
      });
    });
  }

  /**
   * Function to execute after load model
   */
  afterLoadModel() {
    this.model.audienceCount = this.model.audiences.length;
    this.model.is_anonymous = this.isNewRecord ? 1 : this.model.is_anonymous;
    this.modelLoaded = true;

    this.instanceQuestion();
    this.bindExistingAnswersSort();
    return true;
  }

  /**
   * Function to instance auxiliary question
   */
  instanceQuestion() {
    this.auxQuestion = new SurveyQuestion();
    this.auxQuestion.survey_id = this.model.id;
    this.cleanAuxQuestion();
  }

  /**
   * Function to clean auxiliary question
   */
  cleanAuxQuestion() {
    this.auxQuestion.text = '';
    this.auxQuestion.type = 1;
    this.auxQuestion.position = this.model.questions.length;
  }

  /**
   * Add question to current survey
   * @param event trigger
   */
  addQuestion(event) {
    event.preventDefault();
    if (typeof this.auxQuestion.text !== 'undefined') {
      const qst = new SurveyQuestion(this.auxQuestion);
      qst['tempId'] = 'Q' + this.model.questions.length;
      this.model.questions.push(qst);
      this.cleanAuxQuestion();
    } else {
      // ERROR TEXTOt
    }
  }


  /**
   * Function to delete question from current survey
   * @param questionKey question index in survey.questions array
   * @param event trigger
   */
  deleteQuestion(questionKey, event) {
    event.preventDefault();
    Swal.fire({
      title: this.i18n.get('Delete confirmation'),
      html: this.i18n.get('Do you really want to delete this question?') + '<br><br>' + this.i18n.get('IMPORTANT: This action will not be effective until the survey is saved.'),
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.i18n.get('Cancel'),
      confirmButtonText: this.i18n.get('Delete')
    }).then((result) => {
      if (result.value) {
        this.deleteQuestionConfirmationCallback(questionKey);
      }
    });
  }

  /**
   * Function to delete question
   * @param questionKey question key
   */
  deleteQuestionConfirmationCallback(questionKey) {
    this.model.questions.splice(questionKey, 1);
  }


  /**
   * Function to add answer to existing question
   * @param questionKey question index in survey.questions array
   * @param event trigger
   */
  addAnswer(questionKey, event) {
    event.preventDefault();
    const elementId = 'answer_text_' + questionKey;
    const elementNotify = 'answer_trigger_callback_' + questionKey;
    const elm = (document.getElementById(elementId) as HTMLInputElement);
    const elmNotify = (document.getElementById(elementNotify) as HTMLInputElement);
    let notifyVal = false;
    if (elmNotify) {
      notifyVal = elmNotify.checked;
      elmNotify.checked = false;
    }
    const val = elm.value;
    elm.value = '';
    const answersLength = this.model.questions[questionKey].answers.length;
    const answer = new SurveyAnswer({
      text: val,
      position: answersLength,
      trigger_callback: notifyVal ? 1 : 0
    });
    answer['tempId'] = 'A' + questionKey + answersLength;
    this.model.questions[questionKey].answers.push(answer);
    if (this.model.questions[questionKey].answers.length === 1) {
      this.bindSortableEvent(this.answerBagPrefix + questionKey);
    }
  }

  /**
   * Function to delete answer from a question
   * @param questionKey question index in survey.questions array
   * @param answerKey anwer index in survey.questions[questionKey].answers array
   * @param event trigger
   */
  deleteAnswer(questionKey, answerKey, event) {
    event.preventDefault();
    Swal.fire({
      title: this.i18n.get('Delete confirmation'),
      html: this.i18n.get('Do you really want to delete this answer?') + '<br><br>' + this.i18n.get('IMPORTANT: This action will not be effective until the survey is saved.'),
      icon: 'warning',
      showCloseButton: true,
      cancelButtonText: this.i18n.get('Cancel'),
      confirmButtonText: this.i18n.get('Delete')
    }).then((result) => {
      if (result.value) {
        this.deleteAnswerConfirmationCallback(questionKey, answerKey);
      }
    });
  }

  /**
   * Function to delete answer
   * @param questionKey question key
   * @param answerKey answer key
   */
  deleteAnswerConfirmationCallback(questionKey, answerKey) {
    this.model.questions[questionKey].answers.splice(answerKey, 1);
  }

  /**
   * Function to execute before save model
   * returns boolean. If it is false model does not save
   *
   */
  beforeSaveModel() {
    this.model.full = true;
    // Needed to disable form data recovering
    // this.loadFormData();
    return true;
  }

}
