import {animate, animateChild, group, query, style, transition, trigger} from "@angular/animations";

let options = {optional: true};
export const slideInAnimation =
    trigger('routeAnimations', [
        transition('left => right', [
            style({position: 'relative'}),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ], options),
            query(':enter', [
                style({left: '-100%'})
            ], options),
            query(':leave', animateChild(),options),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({left: '100%'}))
                ],options),
                query(':enter', [
                    animate('300ms ease-out', style({left: '0%'}))
                ],options)
            ]),
            query(':enter', animateChild(),options),
        ]),
        transition('right => left', [
            style({position: 'relative'}),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                })
            ], options),
            query(':leave', [
                style({left: '-100%'})
            ], options),
            query(':leave', animateChild(),options),
            group([
                query(':leave', [
                    animate('300ms ease-out', style({left: '0%'}))
                ],options),
                query(':enter', [
                    animate('300ms ease-out', style({left: '100%'}))
                ],options)
            ]),
            query(':enter', animateChild(),options),
        ])
    ]);
