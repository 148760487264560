<!--Begin menuItem template-->
<ng-template #menuItem let-sideItemInst let-idx="idx">
  <ng-template #sidebarLink>
    <i class="link-icon {{sideItemInst.iconClass}}"></i>
    <span>{{sideItemInst.name}}</span>
    <span *ngIf="sideItemInst.markAsNew" class="badge badge-warning rounded-0 text-dark p-1">NEW</span>
    <span *ngIf="sideItemInst.notificationsAmount> 0"
          class="badge badge-danger rounded-0 text-dark p-1">{{sideItemInst.notificationsAmount}}</span>
  </ng-template>

  <!--Dropdown-->
  <div *ngIf="sideItemInst.children && sideItemInst.children.length > 0 " class="sidebar-link"
       (click)="toggleMenu(idx)">
    <ng-template [ngTemplateOutlet]="sidebarLink"></ng-template>
    <i class="fa fa-chevron-down dropdown-icon"></i>

  </div>
  <ul *ngIf="sideItemInst.children && sideItemInst.children.length > 0" class="submenu-items"
      [ngClass]="{'collapse': expandedItem!==idx}">
    <li *ngFor="let item of sideItemInst.children">
      <ng-container *ngTemplateOutlet="menuItem; context: {$implicit: item}"></ng-container>
    </li>
  </ul>

  <!--If it is link-->
  <div *ngIf="!sideItemInst.children || sideItemInst.children.length <= 0"
       class="sidebar-link" routerLink="{{sideItemInst.routerLink || '/'}}"
       routerLinkActive="{{sideItemInst.routerLinkActiveClass!}}">
    <ng-template [ngTemplateOutlet]="sidebarLink"></ng-template>
  </div>
</ng-template>

<!--End menuItem template-->
<aside class="sidebar">
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
  <nav class="sidebar-menu pt-3 pb-3">
    <div class="menu-header mt-3 mb-2" i18n>
      Menú principal
    </div>
    <ul class="menu-items">
      <ng-container *ngFor="let item of items; let i = index">
        <li *ngIf="!item.hasOwnProperty('enableRole') ||  user?.roles && user?.roles.indexOf(item.enableRole) > -1 ">
          <ng-container *ngTemplateOutlet="menuItem; context: {$implicit:item, idx: i}"></ng-container>
        </li>
      </ng-container>
    </ul>
  </nav>
</aside>

