<div class="audience-index-component">
  <div class="row">
    <div class="col-sm-7">
      <h4 i18n class="mb-4 text-muted">Audiencia</h4>

    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <a *ngIf="surveyId" [routerLink]="['/gestor/audience/create/'+surveyId]" class="btn btn-primary" i18n>Añadir nueva
        audiencia a encuesta
        #{{surveyId}}</a>
    </div>
    <div class="col-10">
      <div class="card mb4">
        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col-1" i18n>ID</div>
                <div class="col-3" i18n>Nombre</div>
                <div class="col-2 text-center" i18n>Activo</div>
                <div class="col-2" i18n>Especímenes</div>
                <div class="col-2" i18n>Progreso %</div>
                <div class="col-2">
                  <select class="form-control" [(ngModel)]="sortParam" (change)="sort($event)">
                    <option *ngFor="let opt of sortOptions; let key = index" [value]="key"
                            [innerHTML]="opt.label"></option>
                  </select>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col-1">
                  <input type="text" class="form-control" [(ngModel)]="filters.id" (change)="filter()">
                </div>
                <div class="col-3"><input type="text" class="form-control" [(ngModel)]="filters.name"
                                          (change)="filter()"></div>
                <div class="col-2">
                  <select class="form-control" [(ngModel)]="filters.active" (change)="filter()">
                    <option value="null" i18n>Ambos</option>
                    <option value="1" i18n>Si</option>
                    <option value="0" i18n>No</option>
                  </select>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>

                <div class="col-2">
                  <a href="#" (click)="cleanFilters($event)" i18n class="btn btn-sm btn-warning float-right">Reset</a>
                </div>
              </div>
            </div>
            <ng-container *ngIf="collection?.models?.length > 0; else noResults">
              <div class="list-group-item" *ngFor="let audience of collection.models; let i = index">
                <div class="row">
                  <div class="col-1">{{audience.id}}</div>
                  <div class="col-3">
                    <p class="audience-name">{{sh.truncateString(audience.name, 20)}}</p>
                    <span i18n>De </span><a href="#"
                                            [routerLink]="['/gestor/survey/'+audience.survey_id]">#{{audience.survey_id}}</a>
                  </div>

                  <div class="col-2 text-center" i18n>
                    <app-toggle-field [model]="audience" field="active"></app-toggle-field>
                  </div>
                  <div class="col-2 text-center">{{audience.specimenCont}}</div>
                  <div class="col-2 text-center">
                  <span
                    [class]="'badge badge-sm badge-pill badge-'+(audience.answerPercent==100?'success':(audience.answerPercent==0?'danger':'info'))">{{audience.answerPercent}}
                    %</span>
                  </div>
                  <div class="col-2">
                    <div class="btn-group">
                      <button class="btn-outline-secondary btn btn-sm" i18n
                              [routerLink]="['/gestor/audience', audience.id]">Actualizar
                      </button>
                      <div class="btn-group" ngbDropdown role="group"
                           aria-label="Button group with nested dropdown" *ngIf="audience.doneCont === 0">
                        <button class="btn btn-outline-secondary dropdown-toggle-split"
                                ngbDropdownToggle></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <button ngbDropdownItem class="text-danger" i18n
                                  (click)="deleteModel(audience)">Borrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noResults>
              <div class="list-group-item">
                <p class="text-info" i18n> No hay elementos</p>
              </div>
            </ng-template>
          </div>
          <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize"
                          class="d-flex justify-content-center mt-2"
                          [(page)]="collection.page"
                          [pageSize]="collection.pageSize"
                          [collectionSize]="collection.totalRecords"
                          [maxSize]="5"
                          [rotate]="true">
          </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>
