import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BaseFormComponent,
  BreadcrumbItem,
  DataCollection,
  ErrorMessages,
  ErrorSummaryComponent
} from 'ng-wzb';
import {ScheduledSending} from '../../../models/scheduled-sending';
import {SpecimenGridComponent} from '../../specimen/specimen-grid/specimen-grid.component';
import {AudienceSpecimenContact} from '../../../models/audience-specimen-contact';
import {Survey} from '../../../models/survey';
import {DynamicDatePickerModel, DynamicFormControlModel, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {Audience} from '../../../models/audience';
import {StringHelper} from '../../../helpers/string-helper';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {Specimen} from '../../../models/specimen';
import {ScheduledSendingItem} from '../../../models/scheduled-sending-item';
import {DateHelper} from '../../../helpers/date-helper';
import Swal from 'sweetalert2';
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-scheduled-sending-form',
  templateUrl: './scheduled-sending-form.component.html',
  styleUrls: ['./scheduled-sending-form.component.scss']
})
export class ScheduledSendingFormComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public model: ScheduledSending;
  public modelClass = ScheduledSending;
  private updateSpecimensAction = 'bulk-update';

  public availableSurveys = [];
  public availableAudiences = [];
  public smsBalance: number;
  public availableSpecimensCollection: DataCollection;
  public addedSpecimensCollection: DataCollection;
  public AVAILABLE = 1;
  public ADDED = 2;
  public survey;
  public audience;
  public loadedAudiences = 0;
  public hours = [];
  public minutes = [];
  public contactTypes = [];
  public editionEnabled = true;

  public itemsData = {};

  public sh;
  public keyValPipe;
  public previous;
  private instanceSpecimenCollections = true;
  private surveySubscription;
  private findAllPerPage = 2000;

  public duplicatedEmails = [];
  public emails = {};
  public duplicatedPhones = [];
  public phones = {};

  public swalTargets = new SwalPortalTargets();

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Scheduled sending'),
      routerLink: '/gestor/scheduled-sending'
    },
    {
      label: this.i18n.get('Scheduled sending edition')
    }
  ];

  @ViewChild('available') availableGrid: SpecimenGridComponent;
  @ViewChild('added') addedGrid: SpecimenGridComponent;

  public fieldGroups = [
    ['survey_id', 'dispatchDatePick'],
    ['audience_id', 'hour'],
    ['type', 'minute'],
  ];

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.previous = this.as.previousRoute;
    this.checkPrevious();
    this.sh = StringHelper;
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.fillTimingArrays();
    this.loadSmsBalance();
    this.loadContactTypes();
    this.loadAvailableSurveys();
  }

  /**
   * Function to instantiate specimens collections.
   */
  checkInstanceSpecimensCollections() {
    if (!this.isNewRecord && this.instanceSpecimenCollections) {
      this.availableSpecimensCollection = new DataCollection(Specimen, 'get-available-schedule-specimens', {
        id: this.model.id,
        audience_id: this.model.audience_id
      });
      this.availableSpecimensCollection.getModels();

      this.addedSpecimensCollection = new DataCollection(Specimen, 'get-added-schedule-specimens', {
        id: this.model.id,
        audience_id: this.model.audience_id
      });
      this.addedSpecimensCollection.getModels().subscribe(
        response => {
          this.checkDuplicateContactData();
        });
      this.instanceSpecimenCollections = false;
    }
  }

  /**
   * Funtion to fill available hours and minutes
   */
  fillTimingArrays() {
    for (let i = 0; i < 24; i++) {
      this.hours.push({value: i, label: i.toString().padStart(2, '0')});
    }
    for (let i = 0; i < 60; i += 15) {
      this.minutes.push({value: i, label: i.toString().padStart(2, '0')});
    }
  }

  /**
   * Function to get sms balance
   */
  loadContactTypes() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'contact-types')
      .subscribe(
        response => {
          new Promise((resolve) => {
            this.keyValPipe.transform(response.body).forEach((type) => {
              this.contactTypes.push({
                label: type.value,
                value: parseInt(type.key),
              });
            });
            resolve();
          }).then(() => {
            this.reset();
            req.unsubscribe();
          });
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to get sms balance
   */
  loadSmsBalance() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'sms-balance')
      .subscribe(
        response => {
          this.smsBalance = parseInt(response.body.total);
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }


  // @todo: Disable to disable scheduled sending selection
  afterGetFormModel(fullFormModel: Array<DynamicFormControlModel>) {
    const date = new Date();
    const minAllowedDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    fullFormModel.push(
      new DynamicSelectModel({
        id: 'survey_id',
        label: this.i18n.get('Survey'),
        placeholder: this.i18n.get('Select survey'),
        filterable: true,
        value: this.model.survey_id,
        disabled: !this.editionEnabled || !this.isNewRecord,
        validators: {
          required: null,
        },
        options: this.availableSurveys,
        errorMessages: ErrorMessages.generateErrorMessages(['required']),

      }),
      new DynamicSelectModel({
        id: 'audience_id',
        label: this.i18n.get('Audience'),
        placeholder: this.i18n.get('Select an audience'),
        filterable: true,
        value: this.model.audience_id,
        disabled: !this.editionEnabled || !this.isNewRecord,
        validators: {
          required: null,
        },
        options: this.availableAudiences,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicSelectModel({
        id: 'type',
        label: this.i18n.get('Type'),
        placeholder: this.i18n.get('Select a type'),
        filterable: true,
        value: this.model.type,
        disabled: !this.editionEnabled || !this.isNewRecord,
        validators: {
          required: null,
        },
        options: this.contactTypes,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicDatePickerModel({
        id: 'dispatchDatePick',
        label: this.i18n.get('Dispatch date'),
        value: this.model.dispatchDatePick,
        disabled: !this.editionEnabled,
        min: minAllowedDate,
        additional: {
          classCss: 'date-picker'
        },
        // toggleIcon: './../../assets/fontawesome-pro-5.10.2-web/svgs/light/calendar-alt.svg',
        validators: {
          required: null,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicSelectModel({
        id: 'hour',
        label: this.i18n.get('Hour'),
        placeholder: this.i18n.get('Select an hour'),
        filterable: true,
        value: this.model.hour,
        disabled: !this.editionEnabled,
        validators: {
          required: null,
        },
        options: this.hours,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      }),
      new DynamicSelectModel({
        id: 'minute',
        label: this.i18n.get('Minute'),
        placeholder: this.i18n.get('Select a minute'),
        filterable: true,
        value: this.model.minute,
        disabled: !this.editionEnabled,
        validators: {
          required: null,
        },
        options: this.minutes,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      })
    );

  }

  /**
   * Function to construct form grouping by fields if fieldGroups != false
   */
  reset() {
    super.reset();
    if (typeof this.formModel !== 'undefined' && typeof this.formModel[0] !== 'undefined') {
      if (typeof this.surveySubscription !== 'undefined') {
        this.surveySubscription.unsubscribe();
      }

      // @ts-ignore
      this.surveySubscription = this.formModel[0].valueChanges.subscribe(value => {
        this.loadAvailableAudiences(value);
      });
    }
  }

  /**
   * Function to load available surveys to add to scheduled sending form model
   */
  loadAvailableSurveys() {
    this.availableSurveys = [];
    const req = Survey.findAll({}, 1, this.findAllPerPage).subscribe(
      response => {
        new Promise((resolve) => {
          response.forEach((survey, id) => {
            this.availableSurveys.push({
              label: survey.title,
              value: parseInt(survey.id),
            });
          });
          resolve();
        }).then(() => {
          this.reset();
          req.unsubscribe();
        });
      }, error => {
        req.unsubscribe();
        this.es.addError(error);
      }
    );
  }

  /**
   * Function to load available audiences to add to scheduled sending form model
   */
  loadAvailableAudiences(surveyId, search = null) {
    this.availableAudiences = [];

    if (typeof surveyId === 'undefined' || surveyId == null) {
      return;
    }
    if (this.loadedAudiences == surveyId) {
      return;
    }

    let filter = {survey_id: surveyId};

    const req = Audience.findAll(filter, 1, this.findAllPerPage).subscribe(
      response => {
        new Promise((resolve) => {
          response.forEach((audience, id) => {
            this.availableAudiences.push({
              label: audience.name,
              value: parseInt(audience.id),
            });
            this.model.survey_id = surveyId;
            this.loadedAudiences = surveyId;
          });
          this.availableAudiences.reverse();
          resolve();
        }).then(() => {
          this.reset();
          req.unsubscribe();
        });
      }, error => {
        req.unsubscribe();
        this.es.addError(error);
      }
    );
  }

  /**
   * Function to fill array to rever status of each item
   */
  fillScheduledSendingItemsData() {
    if (this.model.items) {
      this.model.items.forEach((item, k) => {
        const audienceSpecimen = this.model.audience.audienceSpecimens.find((au, q) => {
          return au.id == item.audience_specimen_id;
        });
        if (audienceSpecimen) {
          let info = {text: this.i18n.get('Pending sending'), cssClass: 'badge badge-md badge-warning mt-3'};
          if (item.sent == 1) {
            if (item.sent_at != 0) {
              info = {
                text: this.i18n.get('Sent') + ' ' + DateHelper.getDateObjectByTimestamp(item.sent_at).toLocaleString(),
                cssClass: 'badge badge-md badge-success mt-3'
              };
              ;
            }
          } else if (item.sent == -1) {
            info = {text: this.i18n.get('Failed sending'), cssClass: 'badge badge-md badge-danger'};
          }
          this.itemsData[audienceSpecimen.specimen_id] = info;
        }
      });
    }
  }

  /**
   * Function to execute after load model
   */
  afterLoadModel() {
    super.afterLoadModel();
    this.editionEnabled = this.isNewRecord || (this.model.processed == 0 && this.checkHourLimit());
    this.fillScheduledSendingItemsData();
    this.checkInstanceSpecimensCollections();
    // const surveyId = this.route.snapshot.paramMap.get('surveyId');
    // const audienceId = this.route.snapshot.paramMap.get('audienceId');
    // if (surveyId) {
    //   // tslint:disable-next-line:radix
    //   this.model.survey_id = parseInt(surveyId);
    // }
    // if (audienceId) {
    //   // tslint:disable-next-line:radix
    //   this.model.audience_id = parseInt(audienceId);
    // }

    return true;
  }

  /**
   * Function to check previous route
   */
  checkPrevious() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!this.previous || this.previous === '/gestor/scheduled-sending/' + id || this.previous === '/gestor/scheduled-sending/create') {
      this.previous = '/gestor/scheduled-sending';
    }
  }

  /**
   * Function to check if dispatch date is more than an hour
   */
  checkHourLimit() {
    if (!this.model.dispatch_date) {
      return true;
    }
    const currDate = new Date();
    const selectedDate = DateHelper.getDateObjectByTimestamp(this.model.dispatch_date);
    let diff = (selectedDate.getTime() - currDate.getTime()) / 1000;
    diff /= (60 * 60);
    return diff > 1;
  }

  /**
   * Function to execute before save model
   * returns boolean. If it is false model does not save
   *
   */
  beforeSaveModel() {
    if (!super.beforeSaveModel()) {
      return false;
    }
    const rightHourLimit = this.checkHourLimit();
    if (!rightHourLimit) {
      Swal.fire({
        title: this.i18n.get('Not allowed'),
        text: this.i18n.get('You can not add scheduled sending if configured date is less than an hour to send.'),
        icon: 'error',
      });
    }
    return rightHourLimit;
  }

  /**
   * Function to execute after save model
   */
  afterSaveModel() {
    this.afterLoadModel();
    return true;
  }

  /**
   * Function to return selected items of group
   * @param group to recover it items
   * @param id if we want only an array of ids. Set to false returns specimen objects.
   */
  getSelectedSpecimensByGroup(group, id = true) {
    const items = this.getItemsByGroup(group);
    const result = [];
    items.forEach((specimen, key) => {
      if (specimen.selected) {
        const audienceSpecimen = this.model.audience.audienceSpecimens.find((auS, k) => {
          return auS.specimen_id == specimen.id;
        });
        result.push(id ? audienceSpecimen.id : audienceSpecimen);
      }
    });
    return result;
  }

  /**
   * Function to return all items of group
   * @param group of items to select
   */
  getItemsByGroup(group) {
    let items = [];
    switch (group) {
      case this.ADDED:
        items = this.addedSpecimensCollection.models;
        break;
      case this.AVAILABLE:
        items = this.availableSpecimensCollection.models;
        break;
    }
    return items;
  }

  /**
   * Function to check if all results is selected
   * @param group current group id
   */
  getAllResultsValue(group) {
    const grid = this.getGridByGroup(group);
    if (grid) {
      return grid.isAllResultsChecked();
    }
    return false;
  }

  /**
   * Function to return grid by group
   * @param group current group id
   */
  getGridByGroup(group) {
    let grid: boolean | SpecimenGridComponent = false;
    switch (group) {
      case this.ADDED:
        grid = this.addedGrid;
        break;
      case this.AVAILABLE:
        grid = this.availableGrid;
        break;
    }
    return grid;
  }

  /**
   * Function to recover applied grid filters
   * @param group current group id
   */
  getAppliedFilters(group) {
    const grid = this.getGridByGroup(group);
    if (grid) {
      return grid.composeFilter();
    }
    return false;
  }

  /**
   * Function to set checkbox value of all items in group
   * @param event trigger {group: 1, eventData: event}
   */
  setAllSpecimens(event) {
    const group = event.group;
    const eventData = event.eventData;
    const checked = eventData.target.checked;
    const items = this.getItemsByGroup(group);
    this.setSelectedColumn(items, checked);
  }

  /**
   * Function to set items selected value
   * @param items group of items to update
   * @param selected value to set
   */
  setSelectedColumn(items, selected = false) {
    if (typeof items === 'undefined')
      return;
    for (let i = items.length - 1; i >= 0; i--) {
      const specimen = items[i];
      specimen.selected = selected;
    }
  }

  /**
   * Function to update audience specimens models
   * @param event trigger
   */
  updateSpecimens(event) {
    const group = event.group;

    const items = this.getSelectedSpecimensByGroup(group);
    const availableAllResultsChecked = this.getAllResultsValue(this.AVAILABLE);
    if (group === this.ADDED) {
      if (!this.editionEnabled) {
        return;
      }
    } else {
      if (this.model.type == 2) {
        // sms balance if group is available
        let addedLength = items.length;
        if (availableAllResultsChecked) {
          addedLength = this.addedSpecimensCollection.totalRecords;
        }
        if (addedLength > this.smsBalance) {
          Swal.fire({
            title: this.i18n.get('No sms enough'),
            text: this.i18n.get('There is no sms enough to send to all selected specimens.'),
            icon: 'error',
          });
        }
      }
    }

    const data = {
      items,
      id: this.model.id,
      audience_id: this.model.audience_id,
      addedAll: this.getAllResultsValue(this.ADDED),
      addedFilters: this.getAppliedFilters(this.ADDED),
      availableAll: this.getAllResultsValue(this.AVAILABLE),
      availableFilters: this.getAppliedFilters(this.AVAILABLE)
    };

    const req = this.as.postRequest(ScheduledSendingItem.entity, this.updateSpecimensAction, data)
      .subscribe(
        response => {
          if (response.body.success) {
            this.model = response.body.model;
            this.fillScheduledSendingItemsData();
            this.addedSpecimensCollection.getModels().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              response => {
                if (this.addedGrid) {
                  this.addedGrid.checkSelected(false);
                  // this.addedGrid.prepareNotifiedData();
                  this.checkDuplicateContactData();
                }
              });
            this.availableSpecimensCollection.getModels().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              response => {
                if (this.availableGrid) {
                  this.availableGrid.checkSelected(false);
                }
              });
          }

          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }


  /**
   * Function to check all selected specimens contact data for the selected way
   * @param isSms if way is sms
   */
  checkAllSendData(event, isSms) {
    const items = this.getSelectedSpecimensByGroup(this.ADDED, false);
    const attr = isSms ? 'phone' : 'email';
    let cont = 0;
    items.forEach((item, k) => {
      if (item[attr] !== null && item[attr] !== '') {
        cont++;
      }
    });

    if (items.length > cont) {
      Swal.fire({
        title: this.i18n.get('Incomplete contact data'),
        text: this.i18n.get('There is no contact data for all selected specimens. Send anyway?'),
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: this.i18n.get('No'),
        confirmButtonText: this.i18n.get('Yes')
      }).then((result) => {
        if (result.value) {
          this.updateSpecimens(event);
        }
      });
    }
    return !(items.length > cont);
  }

  /**
   * Function to check duplicated contact data
   */
  checkDuplicateContactData() {
    this.emails = {};
    this.phones = {};
    this.duplicatedEmails = [];
    this.duplicatedPhones = [];
    this.addedSpecimensCollection.models.forEach((specimen, k) => {
      if (specimen.email !== null && specimen.email !== '') {
        if (typeof this.emails[specimen.email] === 'undefined') {
          this.emails[specimen.email] = [];
        } else {
          this.duplicatedEmails.push(specimen.email);
        }
        this.emails[specimen.email].push(specimen);
      }

      if (specimen.phone !== null && specimen.phone !== '') {
        if (typeof this.phones[specimen.phone] === 'undefined') {
          this.phones[specimen.phone] = [];
        } else {
          this.duplicatedPhones.push(specimen.phone);
        }
        this.phones[specimen.phone].push(specimen);
      }
    });
  }

  /**
   * Function to redirect to specimen edition
   * @param event trigger
   * @param specimen model to redirect
   */
  redirectToSpecimen(event, specimen) {
    event.preventDefault();
    this.router.navigate(['/gestor/specimen/' + specimen.id]);
  }

  /**
   * Function to remove survey_id input subscription
   */
  ngOnDestroy() {
    this.surveySubscription.unsubscribe();
  }
}
