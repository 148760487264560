import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from 'ng-wzb';

@Component({
  selector: 'app-not-defined-field',
  templateUrl: './not-defined-field.component.html',
  styleUrls: ['./not-defined-field.component.scss']
})
export class NotDefinedFieldComponent extends BaseComponent implements OnInit {


  @Input() attr;
  @Input() model;
  @Input() noResultText = this.i18n.get('Not defined');
  @Input() titleText = this.i18n.get('Not defined');
  public value;


  ngOnInit() {
    super.ngOnInit();
    if(this.model && this.attr){
      this.value = this.model[this.attr];
    }else{
      this.value = this.attr;
    }
  }

}
