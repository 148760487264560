import {Component, OnInit} from '@angular/core';
import {LogService, BreadcrumbItem, User, Yii2UsuarioService} from 'ng-wzb';
import {HttpClient} from '@angular/common/http';
import {SidebarItem} from '../sidebar-item/intefaces/sidebar-item';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public items: SidebarItem[] = [];

  public expandedItem = -1;
  private menuJsonUrl = 'assets/data/menu.json';

  constructor(private http: HttpClient) {
  }

  get user(): User {
    return Yii2UsuarioService.user;
  }

  ngOnInit() {
    this.getJSON();
  }

  toggleMenu(id) {
    this.expandedItem = id;
  }

  public getJSON() {

    return this.http.get(this.menuJsonUrl)
      .subscribe(
        (data: SidebarItem[]) => this.items = data,
        error => LogService.log('oops', error)
      );

  }
}
