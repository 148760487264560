import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, KeyValuePipe} from '@angular/common';
import {NgWzbModule, WaizabuConfig, I18nService} from 'ng-wzb';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {DynamicFormsNGBootstrapUIModule} from '@ng-dynamic-forms/ui-ng-bootstrap';
import {LightboxModule} from 'ngx-lightbox';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FileUploadModule} from 'ng2-file-upload';
import {ToastrModule} from 'ngx-toastr';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {RouterModule} from '@angular/router';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {FooterComponent} from './layouts/footer/footer.component';
import {NavbarComponent} from './layouts/navbar/navbar.component';
import {SidebarComponent} from './layouts/sidebar/sidebar.component';
import {ToggleFieldComponent} from './widgets/toggle-field/toggle-field.component';
import {SurveyComponent} from './components/survey/survey.component';
import {SurveyFormComponent} from './components/survey/survey-form/survey-form.component';
import {DragulaModule} from 'ng2-dragula';
import {setAppInjector} from './app-injector';
import {AudienceComponent} from './components/audience/audience.component';
import {SpecimenComponent} from './components/specimen/specimen.component';
import {AudienceFormComponent} from './components/audience/audience-form/audience-form.component';
import {SpecimenFormComponent} from './components/specimen/specimen-form/specimen-form.component';
import {ConductSurveyComponent} from './components/conduct-survey/conduct-survey.component';
import {AuthInterceptor} from './auth-interceptor';
import {SurveyLayoutComponent} from './layouts/survey-layout/survey-layout.component';
import {SurveyManageComponent} from './components/survey/survey-manage/survey-manage.component';
import {SurveyResultsComponent} from './components/survey/survey-results/survey-results.component';
import {SpecimenGridComponent} from './components/specimen/specimen-grid/specimen-grid.component';
import {ImportSurveyComponent} from './components/survey/import-survey/import-survey.component';
import {SurveyPreviewComponent} from './components/survey/survey-preview/survey-preview.component';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {RegisterComponent} from './components/register/register.component';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import {I18nWzbService} from './services/i18n-wzb.service';
import {WzbDateAdapterService} from './services/wzb-date-adapter.service';
import {WzbDateParserFormatterService} from './services/wzb-date-parser-formatter.service';
import { ImportSpecimensComponent } from './components/specimen/import-specimens/import-specimens.component';
import { SmsBalanceComponent } from './components/sms-balance/sms-balance.component';
import { BaseIndexComponent } from './components/base-index/base-index.component';
import { NotDefinedFieldComponent } from './widgets/not-defined-field/not-defined-field.component';
import { ScheduledSendingComponent } from './components/scheduled-sending/scheduled-sending.component';
import { ScheduledSendingFormComponent } from './components/scheduled-sending/scheduled-sending-form/scheduled-sending-form.component';
import { ScheduledSendingSingleFormComponent } from './components/scheduled-sending/scheduled-sending-single-form/scheduled-sending-single-form.component';


registerLocaleData(localeEs, 'es-ES', localeEsExtra);

export const toastConfig: Object = {
  positionClass: 'toast-bottom-center',
  timeOut: 3000,
};

export const wzbConfig: WaizabuConfig = {
  BASE_URL: 'https://settings.questio.es',
  API_URL: 'https://settings.questio.es/v1',
  PUBLIC_URL: 'https://settings.questio.es'
  // BASE_URL: 'http://survey.api.local',
  // API_URL: 'http://survey.api.local/v1',
  // PUBLIC_URL: 'http://survey.api.local'
  // BASE_URL: 'https://leaderapi.questio.es',
  // API_URL: 'https://leaderapi.questio.es/v1',
  // PUBLIC_URL: 'https://leaderapi.questio.es'
  // BASE_URL: 'https://laboroapi.questio.es',
  // API_URL: 'https://laboroapi.questio.es/v1',
  // PUBLIC_URL: 'https://laboroapi.questio.es'
};


@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFieldComponent,
    SurveyComponent,
    SurveyFormComponent,
    AudienceComponent,
    SpecimenComponent,
    AudienceFormComponent,
    SpecimenFormComponent,
    ConductSurveyComponent,
    SurveyLayoutComponent,
    SurveyManageComponent,
    SurveyResultsComponent,
    SpecimenGridComponent,
    ImportSurveyComponent,
    SurveyPreviewComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ImportSpecimensComponent,
    SmsBalanceComponent,
    BaseIndexComponent,
    NotDefinedFieldComponent,
    ScheduledSendingComponent,
    ScheduledSendingFormComponent,
    ScheduledSendingSingleFormComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    ToastrModule.forRoot(toastConfig),
    FileUploadModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule,
    DynamicFormsNGBootstrapUIModule,
    TabsModule.forRoot(),
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    ClipboardModule,
    BrowserAnimationsModule,
    DragulaModule.forRoot(),
    NgSelectModule,
    // Waizabu
    NgWzbModule.forRoot(wzbConfig),
    NgWzbModule,
  ],
  providers: [
    KeyValuePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: I18nService, useClass: I18nWzbService},
    {provide: NgbDateAdapter, useClass: WzbDateAdapterService},
    {provide: NgbDateParserFormatter, useClass: WzbDateParserFormatterService}

  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
