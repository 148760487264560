import {Injectable, OnInit} from '@angular/core';
import {I18nService, ApiHttpClient, LogService} from 'ng-wzb';
import {AppInjector} from '../app-injector';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class I18nWzbService extends I18nService {

  static http;
  static translationPath = 'assets/i18nTranslations/';
  static translations;
  public as;

  static loadTranslations(lang = 'es') {
    I18nWzbService.http = AppInjector.get(HttpClient);
    return I18nWzbService.http.get(I18nWzbService.translationPath + lang + '.json')
      .subscribe(
        data => {
          this.translations = data
        },
        error => LogService.log('oops', error)
      );

  }

  /**
   * Function to scan and fill no existing translations to lang file. Create lang file if it does not exists
   * @param lang loaded lang
   */
  scan(lang = 'es') {
    this.as = AppInjector.get(ApiHttpClient);
    const req = this.as.getRequest('defaults', 'scan-translations', {lang}, true).subscribe(
      response => {
        req.unsubscribe();
        return true;
      }, error => {
        req.unsubscribe();
      });
  }
  /**
   *
   * @param text to be translated
   */
  get(text: string) {
    if (typeof I18nWzbService.translations !== 'undefined') {
      if (typeof I18nWzbService.translations[text] != 'undefined') {
        const translation = I18nWzbService.translations[text];
        if (translation !== '') {
          text = translation;
        }
      } else {
        //add request
      }
    }
    return text;
  }
}
