import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DataCollection, BaseComponent, ModalComponent, DefaultsService} from 'ng-wzb';
import {Audience} from '../../../models/audience';
import {faEye, faEyeSlash, faFilter, faEnvelope, faPhone, faCheck} from '@fortawesome/free-solid-svg-icons';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {DateHelper} from '../../../helpers/date-helper';

@Component({
  selector: 'app-specimen-grid',
  templateUrl: './specimen-grid.component.html',
  styleUrls: ['./specimen-grid.component.scss']
})
export class SpecimenGridComponent extends BaseComponent implements OnInit {

  @Input() group: number
  @Input() audience: Audience;
  @Input() showUpdateBtn = true;
  @Input() showSendEmailBtn = false;
  @Input() showSendSmsBtn = false;
  @Input() forceUpdateBtn = false;
  @Input() showDone = false;
  @Input() itemsData: {} | boolean = false; // Specimen id as key, value =>  {text: '', cssClass: ''}
  @Input() collection: DataCollection
  @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendSms: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('filterModal') modal: ModalComponent;
  public groupName: string;
  public titleGroupName: string;
  public showNotified;
  public showScheduledProgramingShortCutBtn;

  public notifiedData = {};

  public icons = {
    view: faEye,
    hide: faEyeSlash,
    filter: faFilter,
    envelope: faEnvelope,
    phone: faPhone,
    check: faCheck
  };
  public AVAILABLE = 1;
  public ADDED = 2;

  public groupNames = {
    1: 'available',
    2: 'added'
  }

  public titleGroupNames = {
    1: this.i18n.get('Available'),
    2: this.i18n.get('Added')
  }

  protected keyValPipe;

  public showAllResultsCheck = false;
  public filterApplied = false;
  public filters = {
    corporate_id: null,
    fullName: null,
    email: null,
    phone: null,
    gender_id: null,
    job: null,
    section: null,
    workplace: null,
    seniority_id: null,
    contract_type_id: null,
  }

  public associativeData = {
    genders: [],
    contractTypes: [],
    seniorities: [],
    jobs: [],
    sections: [],
    workplaces: [],
  };

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.groupName = this.groupNames[this.group];
    this.titleGroupName = this.titleGroupNames[this.group];
    this.loadAssociativeData();
    if (this.group == this.ADDED && typeof this.audience !== 'undefined') {
      this.showNotified = true;
      this.showScheduledProgramingShortCutBtn = DefaultsService.getValue('SHOW_SCHEDULED_SEND_SHORTCUT_BTN', 'GENERAL');
      this.prepareNotifiedData();
    }
  }

  /**
   * Function to close modal
   */
  dismissModal() {
    this.modalService.dismissAll();
  }

  /**
   * Function to trigger update event
   * @param event trigger
   */
  updateClick(event) {
    event.preventDefault();
    this.update.emit({group: this.group, eventData: event});
  }

  /**
   * Function to trigger sendMail event
   * @param event trigger
   */
  sendMail(event) {
    event.preventDefault();
    this.sendEmail.emit({group: this.group, eventData: event});
  }

  /**
   * Function to trigger sendSms event
   * @param event trigger
   */
  sendSMS(event) {
    event.preventDefault();
    this.sendSms.emit({group: this.group, eventData: event});
  }

  /**
   * Function to filter collection
   */
  filter() {
    this.modalService.dismissAll();
    this.collection.filter = this.composeFilter();

  }

  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.modalService.dismissAll();
    this.filterApplied = false;
    this.filters = {
      corporate_id: null,
      fullName: null,
      email: null,
      phone: null,
      gender_id: null,
      job: null,
      section: null,
      workplace: null,
      seniority_id: null,
      contract_type_id: null,
    }
    this.collection.filter = {};
  }

  /**
   * Function to prepare filter query
   */
  composeFilter() {
    const filters = [];
    this.keyValPipe.transform(this.filters).forEach((filter) => {
      const key = filter.key;
      // filter.value === 'null' is needed to exclude placeholders values
      if (filter.value === null || filter.value === '' || filter.value === 'null') {
        return;
      }

      let q = {};
      switch (key) {
        case 'fullName':
          const chunks = filter.value.split(' ');
          q = {and: []};
          chunks.forEach((str, k) => {
            // @ts-ignore
            q.and.push(
              {
                or: [
                  {first_name: {like: str}},
                  {lastname: {like: str}},
                  {second_lastname: {like: str}},
                ]
              }
            );
          });

          break;
        case 'workplace':
          const workplace = this.associativeData.workplaces[filter.value].label;
          q[key] = {like: workplace};
          break;
        case 'section':
          const section = this.associativeData.sections[filter.value].label;
          q[key] = {like: section};
          break;
        case 'job':
          const jobName = this.associativeData.jobs[filter.value].label;
          q[key] = {like: jobName};
          break;
        case 'email':
        case 'phone':
        case 'corporate_id':
          q[key] = {like: filter.value};
          break;
        default:
          q[key] = filter.value;
          break;

      }
      filters.push(q);
    });

    let filter = {};
    this.filterApplied = filters.length > 0;
    switch (filters.length) {
      case 0:
        break;
      case 1:
        filter = filters[0];
        break;
      default:
        filter = {and: filters}
        break;
    }
    return filter;
  }

  /**
   * Function to load associative data
   */
  loadAssociativeData() {
    const req = this.as.getRequest('specimen', 'get-associative-data')
      .subscribe(
        response => {
          this.keyValPipe.transform(response.body).forEach((category) => {
            this.keyValPipe.transform(category.value).forEach((item) => {
              this.associativeData[category.key].push({
                label: item.value,
                value: item.key,
              });
            });
          });
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  /**
   * Prepare array to check if specimen has been notified
   */
  prepareNotifiedData() {
    this.notifiedData = {};
    if (typeof this.audience !== 'undefined' && typeof this.audience.audienceSpecimens !== 'undefined' &&
      this.audience.audienceSpecimens.length > 0) {
      this.audience.audienceSpecimens.forEach((audienceSpecimen, k) => {
        // tslint:disable-next-line:max-line-length
        this.notifiedData[audienceSpecimen.specimen_id] = this.getSpecimenContactData(audienceSpecimen);

      });
    }
  }

  /**
   * Function to calculate audienceSpecimen email data
   * @param audienceSpecimen subject
   */
  getSpecimenContactData(audienceSpecimen) {
    // tslint:disable-next-line:radix
    const done = this.showDone ? parseInt(audienceSpecimen.done):0;
    const data = {
      pin: audienceSpecimen.audience_id + '_' + audienceSpecimen.id,
      totalMails: 0,
      lastMail: 0,
      totalSms: 0,
      lastSms: 0,
      mails: [],
      sms: [],
      showMailHistory: false,
      showSmsHistory: false,
      done
    }

    const totalMails = audienceSpecimen.emails ? audienceSpecimen.emails.length : 0;
    if (totalMails > 0) {
      data.totalMails = totalMails;
      data.mails = audienceSpecimen.emails
      data.mails.forEach((mail, k) => {
        mail.sent_at = this.getSpecimenContactDate(mail);
      });
      // @ts-ignore
      data.lastMail = data.mails[0].sent_at;
    }

    const totalSms = audienceSpecimen.sms ? audienceSpecimen.sms.length : 0;
    if (totalSms > 0) {
      data.totalSms = totalSms;
      data.sms = audienceSpecimen.sms
      data.sms.forEach((sms, k) => {
        sms.sent_at = this.getSpecimenContactDate(sms);
      });
      // @ts-ignore
      data.lastSms = data.sms[0].sent_at;
    }

    return data;

  }

  /**
   * Function to get specimenlast email date
   * @param audienceSpecimenContact subject
   */
  getSpecimenContactDate(audienceSpecimenContact) {
    return DateHelper.getDateObjectByTimestamp(audienceSpecimenContact.sent_at).toLocaleString();
  }

  /**
   *  Function to set all checkboxes by trigger all check
   * @param event trigger
   */
  setAllEvent(event) {
    this.showAllResultsCheck = true;
    if (!this.isAllChecked()) {
      const allElm = document.getElementById(this.groupName + '_all_results');
      // @ts-ignore
      allElm.checked = false;
    }
    this.selectAll.emit({group: this.group, eventData: event});
  }

  /**
   * Function to check select all if elem is disabled
   * @param checked if trigger checkbox is checked
   */
  checkSelected(checked) {
    if (!checked) {
      const allElm = document.getElementById(this.groupName + '_all');
      const allResults = document.getElementById(this.groupName + '_all_results');
      // @ts-ignore
      if (allElm && allElm.checked) {
        // @ts-ignore
        allElm.checked = false;
        // @ts-ignore
        allResults.checked = false;
        this.showAllResultsCheck = false;
      }
    }
  }

  /**
   * Function to check if groupName_all checkbox is selected
   */
  isAllChecked() {
    const allElm = document.getElementById(this.groupName + '_all');
    if (!allElm) {
      return false;
    }
    // @ts-ignore
    return allElm && allElm.checked;
  }

  /**
   * Function to check if groupName_all_results checkbox is selected
   */
  isAllResultsChecked() {
    const allElm = document.getElementById(this.groupName + '_all_results');
    if (!allElm) {
      return false;
    }
    // @ts-ignore
    return allElm && allElm.checked;
  }
}
