import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/**
 *  Contains common functionalities to modify string.
 */
export class StringHelper {
  /**
   *
   * @param str string to truncate
   * @param length number max length
   * @param suffix string to add after string if is larger than length
   */
  static truncateString(str: string, length = 40, suffix = '...' ){
    return str.length > length ? str.substr(0, length-1) +  suffix : str;
  }

  static slugify(text) {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }
}
