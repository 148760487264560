import {Component, OnInit} from '@angular/core';
import {BreadcrumbItem, DataCollection} from 'ng-wzb';
import {BaseIndexComponent} from '../base-index/base-index.component';
import {ScheduledSending} from '../../models/scheduled-sending';
import {StringHelper} from '../../helpers/string-helper';
import {AppInjector} from '../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {DateHelper} from '../../helpers/date-helper';
import {Survey} from '../../models/survey';
import {Audience} from '../../models/audience';
import {AudienceSpecimenContact} from '../../models/audience-specimen-contact';

@Component({
  selector: 'app-scheduled-sending',
  templateUrl: './scheduled-sending.component.html',
  styleUrls: ['./scheduled-sending.component.scss']
})
export class ScheduledSendingComponent extends BaseIndexComponent implements OnInit {

  public modelClass = ScheduledSending;

  public sh;
  public keyValPipe;
  public dh;

  public surveys = [];
  public audiences = [];
  public contactTypes = [];

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Scheduled sending'),
    }
  ];

  public filters = {
    id: null,
    survey_id: null,
    audience_id: null,
    processed: null,
    type: null
  }

  public sortOptions = [
    {sort: {id: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Id')},
    {sort: {id: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Id')},
    {sort: {survey_id: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Survey')},
    {sort: {survey_id: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Survey')},
    {sort: {audience_id: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Audience')},
    {sort: {audience_id: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Audience')},
    {sort: {processed: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Processed')},
    {sort: {processed: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Processed')},
    {sort: {type: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Type')},
    {sort: {type: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Type')},
    {sort: {dispatch_date: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Dispatch date')},
    {sort: {dispatch_date: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Dispatch date')},
    {sort: {created_at: DataCollection.SORT_ASC}, label: '&#x2193 ' + this.i18n.get('Created at')},
    {sort: {created_at: DataCollection.SORT_DESC}, label: '&#x2191 ' + this.i18n.get('Created at')}
  ];
  public sortParam = null;
  public sortDefaultParam = 11;

  /**
   * inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.dh = DateHelper;
    this.sh = StringHelper;
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.loadSurveys();
    this.loadAudiences();
    this.loadContactTypes();
  }

  /**
   * Function to execute before load models
   * returns boolean. If it is false models are not loaded
   */
  beforeLoadModels() {
    if (!this.sortParam) {
      this.sortParam = this.sortDefaultParam;
      const param = this.sortOptions[this.sortParam];
      this.collection.setSort(param.sort, false);
    }
    return true;
  }

  /**
   * Fucntion to sort collection
   * @param event trigger
   */
  sort(event) {
    if (this.sortParam !== null) {
      let param = this.sortOptions[this.sortParam];
      if (param) {
      } else {
        this.sortParam = this.sortDefaultParam;
        param = this.sortOptions[this.sortParam];
      }
      this.collection.setSort(param.sort);
    }
  }

  /**
   * Function to get sms balance
   */
  loadContactTypes() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'contact-types')
      .subscribe(
        response => {
          new Promise((resolve) => {
            this.keyValPipe.transform(response.body).forEach((type) => {
              this.contactTypes.push({
                label: type.value,
                value: parseInt(type.key),
              });
            });
            resolve();
          }).then(() => {
            req.unsubscribe();
          });
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }


  /**
   * Function to load available surveys to add to scheduled sending form model
   */
  loadSurveys() {
    this.surveys = [];
    const req = Survey.findAll().subscribe(
      response => {
        new Promise((resolve) => {
          response.forEach((survey, id) => {
            this.surveys.push({
              label: survey.title,
              value: survey.id,
            });
          });
          resolve();
        }).then(() => {
          req.unsubscribe();
        });
      }, error => {
        req.unsubscribe();
        this.es.addError(error);
      }
    );
  }

  /**
   * Function to load available audiences to add to scheduled sending form model
   */
  loadAudiences() {
    this.audiences = [];
    const req = Audience.findAll().subscribe(
      response => {
        new Promise((resolve) => {
          response.forEach((audience, id) => {
            this.audiences.push({
              label: audience.name,
              value: audience.id,
            });
          });
          resolve();
        }).then(() => {
          req.unsubscribe();
        });
      }, error => {
        req.unsubscribe();
        this.es.addError(error);
      }
    );
  }

  /**
   * Function to filter collection
   */
  filter() {
    this.collection.filter = this.composeFilter();
  }


  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.filters = {
      id: null,
      survey_id: null,
      audience_id: null,
      processed: null,
      type: null
    }
    this.collection.filter = {};
  }

  /**
   * Function to prepare filter query
   */
  composeFilter() {
    const filters = [];
    this.keyValPipe.transform(this.filters).forEach((filter) => {
      const key = filter.key;

      // filter.value === 'null' is needed to exclude both value
      if (filter.value === null || filter.value === '' || filter.value === 'null') {
        return;
      }

      const q = {};
      q[key] = filter.value;
      filters.push(q);
    });

    let filter = {};
    switch (filters.length) {
      case 0:
        break;
      case 1:
        filter = filters[0];
        break;
      default:
        filter = {and: filters}
        break;
    }
    return filter;
  }
}
