<div class="import-survey-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted">
        <span i18n>Importar encuesta</span>
      </h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row" *ngIf="!surveyLoaded; else preview">
    <div class="col-12">
      <div class="card">
        <div class="survey-form card-body">

          <form (ngSubmit)="uploadDocument()">
            <div class="row">
              <div class="col-6">

                <div class="custom-file" id="customFile" lang="es">
                  <input type="file" [accept]="allowedExtensions" class="custom-file-input" id="file"
                         aria-describedby="fileHelp" (change)="fileChanged($event)">
                  <label class="custom-file-label" for="file">
                    <ng-container *ngIf="this.file;else filePlaceholder">{{this.file.name}}</ng-container>
                    <ng-template #filePlaceholder>
                      <span i18n>Seleccione archivo</span>
                    </ng-template>
                  </label>
                </div>
              </div>
              <div class="col-6">
                <button i18n type="submit" id="submitGeneral"
                        class="btn btn-lightning rounded-0 mb-2 mr-2">Previsualizar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ng-template #preview>
    <div *ngIf="showSpinner" class="text-success">
      <fa-icon [spin]="true" [icon]="spinnerIcon" class="float-left"></fa-icon>
      Procesando...
    </div>

    <app-survey-preview [model]="survey" [showSubmitBtn]="true" (submitClick)="saveModel()" [showCancelBtn]="true"
                        (cancelClick)="cancel()"></app-survey-preview>
  </ng-template>
</div>




