<nav class="navbar navbar-expand-md fixed-top navbar-dark flex-fill">
  <!-- <button class="sidebar-toggler ml-4" type="button">
    <i class="fa fa-navicon"></i>
  </button> -->
  <!-- header -->
  <div class="navbar-header pl-2 pr-2 ml-3 text-center">
    <a class="navbar-brand m-0 w-100" [routerLink]="['/gestor']" title="Questio">
      <!-- <i class="fa fa-flash mr-1"></i> -->
      <img src="./assets/imgs/logo.png" alt="lightning logo" class="nav-logo">
    </a>
  </div>
<!--  &lt;!&ndash; sidebar toggler &ndash;&gt;-->
  <div class="ml-auto mr-5">
    <a [routerLink]="['/logout']" class="text-danger float-right">Cerrar sesión</a>
<!--    <a class="btn nav-link" (click)="toggleSidebar()"><i class="fa fa-bars"></i></a>-->
  </div>
<!--  &lt;!&ndash; right menu toggler &ndash;&gt;-->
<!--  <div class="nav-toggler-right mr-4 d-md-none">-->
<!--    <button class="" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"-->
<!--            aria-controls="collapseBasic">-->
<!--      &lt;!&ndash; <span class="navbar-toggler-icon"></span> &ndash;&gt;-->
<!--      <img src="./assets/img/user.jpg" class="img-fluid" alt="">-->
<!--    </button>-->
<!--    <span class="user-profile nav-alert bg-danger"></span>-->
<!--  </div>-->
<!--  &lt;!&ndash; left box &ndash;&gt;-->
<!--  <ul class="navbar-nav flex-fill mt-1 align-items-center left-nav">-->

<!--    <li class="nav-item flex-fill">-->
<!--      <input class="form-control navbar-search" type="text" placeholder="Search . . .">-->
<!--    </li>-->
<!--  </ul>-->

<!--  &lt;!&ndash; right menu &ndash;&gt;-->
<!--  <div class="collapse navbar-collapse right-nav" id="collapseBasic" [collapse]="isCollapsed">-->
<!--    <ul class="navbar-nav ">-->
<!--      <li class="nav-item">-->
<!--        <a class="btn nav-link">-->
<!--          <i class="far fa-bell"></i>-->
<!--          <span class="link-text">Alerts</span>-->
<!--          &lt;!&ndash; <span class="badge badge-pill ba dge-danger">3</span> &ndash;&gt;-->
<!--          <span class="nav-alert notifications"></span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="btn nav-link">-->
<!--          <i class="far fa-envelope"></i>-->
<!--          <span class="link-text">Messages</span>-->
<!--          &lt;!&ndash; <span class="badge badge-pill badge-success">5</span> &ndash;&gt;-->
<!--          <span class="nav-alert messages"></span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="btn nav-link">-->
<!--          <i class="far fa-user"></i>-->
<!--          <span class="link-text">Profile</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="btn nav-link">-->
<!--          <i class="fas fa-cog"></i>-->
<!--          <span class="link-text">Setting</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="btn nav-link">-->
<!--          <i class="fas fa-cog"></i>-->
<!--          <span class="link-text" [routerLink]="['/test']">Test</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->



</nav>

