import {Model} from 'ng-wzb';

export class SurveyAnswer extends Model {
  static entity = 'survey-answer';
  entity = 'survey-answer';

  public text: string;
  public survey_question_id: number;
  public position: number;
  public trigger_callback: number;

  getFormModel() {
    return [];
  }

}
