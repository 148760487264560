<div class="survey-results-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>Resultados de la encuesta {{(survey && survey.id ? ' #' + survey.id : '')}}</h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="survey-form card card-body mb-3" *ngIf="survey">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="title" i18n>Título</label>
              <p id="title">{{survey.title}}</p>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label for="audience" i18n>Audiencia</label>
              <ng-container *ngIf="survey?.audiences.length > 0">
                <wzb-ng-select-extended id="audience" [addTag]="true" [items]="survey?.audiences" bindLabel="name"
                                        [(model)]="audienceIds"
                                        [multiple]="true" [truncateText]="true"></wzb-ng-select-extended>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <a href="#" class="btn btn-lightning rounded-0 float-right" (click)="loadResults($event)" i18n>Cargar
              resultados</a>
            <a href="#" class="btn btn-outline-lightning rounded-0 float-left" (click)="exportResults($event)" i18n>Exportar
              resultados</a>
            <a href="#" class="btn btn-outline-lightning rounded-0 float-left ml-2"
               (click)="exportResultsByDataSet($event)"
               i18n>Exportar
              resultados por conjunto de datos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-3"  *ngIf="showFilters">
    <div class="card-header">
      <div class="card-title">
        <h4>Filtro de resultados</h4>
      </div>
    </div>
    <div class="card-body">
      <form (ngSubmit)="filterResults()">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="gender_id" i18n>Género</label>
              <select name="gender_id" id="gender_id" class="form-control" [(ngModel)]="filters.gender_id">
                <option value="null" class="text-muted" i18n>Seleccione género...</option>
                <option *ngFor="let gender of associativeData.genders" [value]="gender.value">{{gender.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="section" i18n>Sección</label>
              <select name="section" id="section" class="form-control"
                      [(ngModel)]="filters.section">
                <option value="null" class="text-muted" i18n>Seleccione sección...</option>
                <option *ngFor="let section of associativeData.sections;"
                        [value]="section.value">{{section.label}}</option>
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="job" i18n>Puesto de trabajo</label>
              <select name="job" id="job" class="form-control"
                      [(ngModel)]="filters.job">
                <option value="null" class="text-muted" i18n>Seleccione puesto de trabajo...</option>
                <option *ngFor="let job of associativeData.jobs;" [value]="job.value">{{job.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="workplace" i18n>Planta</label>
              <select name="workplace" id="workplace" class="form-control"
                      [(ngModel)]="filters.workplace">
                <option value="null" class="text-muted" i18n>Seleccione planta...</option>
                <option *ngFor="let workplace of associativeData.workplaces;"
                        [value]="workplace.value">{{workplace.label}}</option>
              </select>
            </div>

          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="seniority_id" i18n>Antigüedad</label>
              <select name="seniority_id" id="seniority_id" class="form-control" [(ngModel)]="filters.seniority_id">
                <option value="null" class="text-muted" i18n>Seleccione antigüedad...</option>
                <option *ngFor="let seniority of associativeData.seniorities"
                        [value]="seniority.value">{{seniority.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="contract_type_id" i18n>Tipo contrato</label>
              <select name="contract_type_id" id="contract_type_id" class="form-control"
                      [(ngModel)]="filters.contract_type_id">
                <option value="null" class="text-muted" i18n>Seleccione tipo...</option>
                <option *ngFor="let type of associativeData.contractTypes" [value]="type.value">{{type.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button i18n type="submit" id="submitGeneral"
                    class="btn btn-lightning mb-2 mr-2">Filtrar resultados
            </button>
            <a href="#" class="btn btn-warning mb-2 float-right" i18n (click)="cleanFilters($event)">Reset</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <div class="card-title" *ngIf="hasAudiences">
        <h4 *ngFor="let audience of audiences">
          #{{audience.id}} | {{sh.truncateString(audience.name)}}
        </h4>

      </div>
      <div *ngIf="audiences?.length > 0" class="audience-done">
             <span
               [class]="'float-right badge badge-md badge-pill badge-'+(totalPercent==100?'success':(totalPercent==0?'danger':'info'))">{{totalPercent}}
               %</span>
        <span class="float-left"><span i18n>Realizado: </span>{{totalDone}}/{{totalSpecimens}}</span>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!noResults; else noResultsAdvice">
        <div class="list-group questions-container"
             *ngIf="survey?.questions?.length > 0 && questionTypes && resultsLoaded">
          <div class="list-group-item" *ngFor="let question of survey.questions; let i = index">
            <h4>{{question.text}}</h4>
            <ng-container>
              <ng-container *ngIf="question.type != questionTypes.TEXT; else textAnswers">
                <div class="list-group">
                  <div class="list-group-item" *ngFor="let answer of question.answers; let k = index">
                    <p>{{answer.text}}</p>
                    <ng-container *ngIf="resultsLoaded">
                      <ngb-progressbar type="info" [value]="results[question.id]?.answers[answer.id]?.percent"
                                       [title]="results[question.id]?.answers[answer.id]?.percent+'%'"
                                       [showValue]="true"></ngb-progressbar>
                      <div
                        class="text-right">{{(results[question.id]?.answers[answer.id]?.total ? results[question.id]?.answers[answer.id]?.total : 0)}}
                        /{{totalDone}}</div>
                    </ng-container>

                  </div>
                </div>
              </ng-container>
              <ng-template #textAnswers>
                <ng-container
                  *ngIf="results[question.id]?.answers[textAnswersPage[question.id]?.currentPage]; else noTextAnswer">
                  <div class="text-answer">
                    {{results[question.id]?.answers[textAnswersPage[question.id]?.currentPage].value}}
                  </div>
                  <div class="actions" *ngIf="textAnswersPage[question.id]?.total > 1">
                    <a href="#" class="btn btn-link" (click)="back(question.id, $event)" i18n>Atrás</a>
                    <span>{{textAnswersPage[question.id]?.currentPage + 1}}
                      /{{textAnswersPage[question.id]?.total}}</span>
                    <a href="#" class="btn btn-link" (click)="next(question.id, $event)" i18n>Siguiente</a>
                  </div>
                </ng-container>
                <ng-template #noTextAnswer>
                  <p class="text-info" i18n>No hay respuestas</p>
                </ng-template>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #noResultsAdvice>
        <div class="alert alert-info">
          <span i18n *ngIf="!filterApplied;else noFilterResults">Esta audiencia todavía no tiene respuestas</span>
          <ng-template #noFilterResults><span i18n>La combinación del filtro no tiene resultados</span></ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>


