import { Component, OnInit } from '@angular/core';
import {LogoutComponent as BaseLogoutComponent} from 'ng-wzb';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BaseLogoutComponent {
  protected defaultRoute = '/gestor';
}
