<div class="specimen-index-component">
  <div class="row">
    <div class="col-sm-7">
      <h4 i18n class="mb-4 text-muted">Especímenes</h4>

    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <a routerLink="/gestor/specimen/create" class="btn btn-primary" i18n>Nuevo espécimen</a>
    </div>
    <div class="col-10">
      <div class="card mb4">
        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col-5" i18n>Espécimen</div>
                <div class="col-5" i18n>Info</div>
                <div class="col-2">
                   <span (click)="showFilters = !showFilters;" title="Filtrar especímenes"
                         [class]="'filter-icon-container'+(filterApplied?' filter-applied':'')">
                    <fa-icon [icon]="filterIcon"></fa-icon>
                   </span>
                </div>
              </div>
            </div>
            <div class="list-group-item" *ngIf="showFilters">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="corporate_id" i18n>ID Corporativa</label>
                    <input name="corporate_id" id="corporate_id" class="form-control"
                           [(ngModel)]="filters.corporate_id">
                  </div>
                  <div class="form-group">
                    <label for="phone" i18n>Teléfono</label>
                    <input name="phone" id="phone" class="form-control" [(ngModel)]="filters.phone">
                  </div>
                  <div class="form-group">
                    <label for="job" i18n>Puesto de trabajo</label>
                    <select name="job" id="job" class="form-control"
                            [(ngModel)]="filters.job">
                      <option value="null" class="text-muted" i18n>Seleccione puesto de trabajo...</option>
                      <option *ngFor="let job of associativeData.jobs;" [value]="job.value">{{job.label}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="seniority_id" i18n>Antigüedad</label>
                    <select name="seniority_id" id="seniority_id" class="form-control"
                            [(ngModel)]="filters.seniority_id">
                      <option value="null" class="text-muted" i18n>Seleccione antigüedad...</option>
                      <option *ngFor="let seniority of associativeData.seniorities"
                              [value]="seniority.value">{{seniority.label}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="fullName" i18n>Nombre</label>
                    <input name="fullName" id="fullName" class="form-control" [(ngModel)]="filters.fullName">
                  </div>
                  <div class="form-group">
                    <label for="email" i18n>Email</label>
                    <input name="email" id="email" class="form-control" [(ngModel)]="filters.email">
                  </div>
                  <div class="form-group">
                    <label for="section" i18n>Sección</label>
                    <select name="section" id="section" class="form-control"
                            [(ngModel)]="filters.section">
                      <option value="null" class="text-muted" i18n>Seleccione sección...</option>
                      <option *ngFor="let section of associativeData.sections;"
                              [value]="section.value">{{section.label}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="gender_id" i18n>Género</label>
                    <select name="gender_id" id="gender_id" class="form-control" [(ngModel)]="filters.gender_id">
                      <option value="null" class="text-muted" i18n>Seleccione género...</option>
                      <option *ngFor="let gender of associativeData.genders"
                              [ngValue]="gender.value">{{gender.label}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="contract_type_id" i18n>Tipo contrato</label>
                    <select name="contract_type_id" id="contract_type_id" class="form-control"
                            [(ngModel)]="filters.contract_type_id">
                      <option value="null" class="text-muted" i18n>Seleccione tipo...</option>
                      <option *ngFor="let type of associativeData.contractTypes"
                              [value]="type.value">{{type.label}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="workplace" i18n>Planta</label>
                    <select name="workplace" id="workplace" class="form-control"
                            [(ngModel)]="filters.workplace">
                      <option value="null" class="text-muted" i18n>Seleccione planta...</option>
                      <option *ngFor="let workplace of associativeData.workplaces;"
                              [value]="workplace.value">{{workplace.label}}</option>
                    </select>
                  </div>
                  <div class="filter-actions">
                    <a href="#" (click)="filter($event)" i18n class="btn btn-sm btn-lightning mr-2">Buscar</a>
                    <a href="#" (click)="cleanFilters($event)" i18n class="btn btn-sm btn-warning">Reset</a>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="collection?.models?.length > 0; else noResults">
              <div class="list-group-item" *ngFor="let specimen of collection.models; let i = index">
                <div class="row">
                  <div class="col-5">{{specimen.fullName}}
                    <div>
                      <span i18n>ID Corp.: </span> #<app-not-defined-field [model]="specimen" attr="corporate_id"></app-not-defined-field> |
                      <span i18n>Edad: </span> <app-not-defined-field [model]="specimen" attr="age"></app-not-defined-field>
                    </div>
                    <div>
                      <span i18n>Email: </span> <app-not-defined-field [model]="specimen" attr="email"></app-not-defined-field>
                    </div>
                    <div>
                      <span i18n>Teléfono: </span> <app-not-defined-field [model]="specimen" attr="phone"></app-not-defined-field>
                    </div>
                  </div>
                  <div class="col-5">
                    <ul>
                      <li>
                        <app-not-defined-field [model]="specimen" attr="gender" noResultText="Género"></app-not-defined-field>
                      </li>
                      <li>
                        <app-not-defined-field [model]="specimen" attr="job" noResultText="Puesto trabajo"></app-not-defined-field>
                      </li>
                      <li>
                        <app-not-defined-field [model]="specimen" attr="section"
                                               noResultText="Sección"></app-not-defined-field>
                        |
                        <app-not-defined-field [model]="specimen" attr="workplace"
                                               noResultText="Planta"></app-not-defined-field>

                      </li>
                      <li>
                        <app-not-defined-field [model]="specimen" attr="seniority"  noResultText="Antigüedad"></app-not-defined-field>
                      </li>
                      <li>
                        <app-not-defined-field [model]="specimen" attr="contractType" noResultText="Tipo contrato"></app-not-defined-field>
                      </li>
                    </ul>
                  </div>
                  <div class="col-2">
                    <div class="btn-group">
                      <button class="btn-outline-secondary btn btn-sm" i18n
                              [routerLink]="['/gestor/specimen', specimen.id]">Actualizar
                      </button>
                      <div class="btn-group" ngbDropdown role="group"
                           aria-label="Button group with nested dropdown" *ngIf="!specimen.hasParticipate">
                        <button class="btn btn-outline-secondary dropdown-toggle-split"
                                ngbDropdownToggle></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <button ngbDropdownItem class="text-danger" i18n
                                  (click)="deleteModel(specimen)">Borrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noResults>
              <div class="list-group-item">
                <p class="text-info" i18n> No hay elementos</p>
              </div>
            </ng-template>
          </div>
          <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize"
                          class="d-flex justify-content-center mt-2"
                          [(page)]="collection.page"
                          [pageSize]="collection.pageSize"
                          [collectionSize]="collection.totalRecords"
                          [maxSize]="5"
                          [rotate]="true">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

