import { Component, OnInit } from '@angular/core';
import {
  Model,
  BaseIndexComponent as WzbBaseIndexComponent
} from 'ng-wzb';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-base-index',
  templateUrl: './base-index.component.html',
  styleUrls: ['./base-index.component.scss']
})
export class BaseIndexComponent extends WzbBaseIndexComponent implements OnInit {

  /**
   * Function to run model delete action
   * @param model model
   */
  deleteModel(model: Model) {
    Swal.fire({
      title: this.i18n.get('Delete confirmation'),
      text: this.i18n.get('Do you really want to delete this element?'),
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.i18n.get('Cancel'),
      confirmButtonText: this.i18n.get('Delete')
    }).then((result) => {
      if (result.value) {
        this.deleteConfirmationCallBack(true, {model});
      }
    });
  }
}
