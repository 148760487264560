import { Component, OnInit } from '@angular/core';
import {RegisterComponent as BaseRegisterComponent} from 'ng-wzb';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseRegisterComponent{
  back() {
    this.router.navigate(['/gestor']);
  }
}
