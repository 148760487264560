<div class="sms-balance-component">
  <div class="row">
    <div class="col-sm-7">
      <h4 i18n class="mb-4 text-muted">Saldo SMS</h4>

    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h5 i18n>Saldo disponible <span [class]="'float-right badge badge-'+balanceClass">{{availableBalance}}</span></h5>
          </div>
        </div>
        <div class="card-body">
          <p i18n>Para incrementar el saldo póngase en contacto con Waizabú</p>
          <a href="mailto:soporte@waizabú.com?Subject=Aumento%20saldo%20SMS" i18n>soporte@waizabú.com</a>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="card mb4">
        <div class="card-body">
          <div class="list-group" >
            <div class="list-group-item">
              <div class="row">
                <div class="col-5" i18n>Teléfono</div>
                <div class="col-6" i18n>Enviado</div>
                <div class="col-2"></div>
              </div>
            </div>
            <ng-container *ngIf="collection?.models?.length > 0; else noResults">
              <div class="list-group-item" *ngFor="let contact of collection.models; let i = index">
                <div class="row">
                  <div class="col-5">{{contact.data}}</div>
                  <div class="col-6">{{dh.getDateObjectByTimestamp(contact.sent_at).toLocaleString()}}</div>
                  <div class="col-2">
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noResults>
              <div class="list-group-item">
                <p class="text-info" i18n> No hay elementos</p>
              </div>
            </ng-template>
          </div>
          <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize" class="d-flex justify-content-center mt-2"
                          [(page)]="collection.page"
                          [pageSize]="collection.pageSize"
                          [collectionSize]="collection.totalRecords"
                          [maxSize]="5"
                          [rotate]="true">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5" *ngIf="isSuperAmin">
    <div class="col-6 offset-3">
      <div class="card card-body">
        <form>
          <div class="form-group">
            <label for="increase_qty" i18n>Cantidad</label>
            <input type="number" name="increase_qty" id="increase_qty" class="form-control" [(ngModel)]="quantity">
          </div>
          <div>
            <a href="#" class="btn btn-outline-lightning mb-2 float-right" i18n (click)="updateBalance($event)">Incrementar</a>
            <a href="#" class="btn btn-outline-lightning mb-2 float-left" i18n (click)="updateBalance($event, true)">Reducir</a>

          </div>
        </form>
      </div>
    </div>

  </div>
</div>
