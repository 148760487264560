import {Component, OnInit} from '@angular/core';
import {BaseComponent, LogService, BreadcrumbItem, DefaultsService} from 'ng-wzb';
import * as XLSX from 'xlsx';
import {AppInjector} from '../../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {Survey} from '../../../models/survey';
import {SurveyQuestion} from '../../../models/survey-question';
import {SurveyAnswer} from '../../../models/survey-answer';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-import-survey',
  templateUrl: './import-survey.component.html',
  styleUrls: ['./import-survey.component.scss']
})
export class ImportSurveyComponent extends BaseComponent implements OnInit {

  public file: any;
  public allowedExtensions = '.csv,.xls,.xlsx';
  private keyValPipe;
  public surveyLoaded = false;
  public survey: Survey;
  public questionTypes;
  public spinnerIcon = faSpinner;
  public showSpinner = false;

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Import survey')
    }
  ];

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
  }

  /**
   * Function to assign selected file
   * @param event trigger
   */
  fileChanged(event) {
    this.file = event.target.files[0];
  }

  /**
   * Function to read and process selected file
   */
  uploadDocument() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const workBook = XLSX.read(fileReader.result, {type: 'binary'});
      this.processData(workBook);
    }
    fileReader.readAsBinaryString(this.file);
  }


  /**
   * Function to process file data and create survey structure
   * @param workBook read workBook
   */
  processData(workBook) {
    let question;
    let questionCellKey;

    const titleChunks = this.file.name.split('.');
    const letters = ['A', 'B', 'C'];

    this.survey = new Survey({title: titleChunks[0]});

    this.keyValPipe.transform(workBook.Sheets).forEach((sheet) => {
      const cells = sheet.value;

      // Object.keys instead keyValPipe to keep order
      Object.keys(cells).forEach((cellKey, key) => {
          const keyChunks = cellKey.match(/([A-Z])([\d]+)/);

          if (!keyChunks || keyChunks.length < 3) {
            return;
          }
          const letter = keyChunks[1];
          const index = keyChunks[2];

          if (!letters.includes(letter)) {
            return;
          }

          const item = cells[cellKey];
          switch (letter) {
            case 'A':
              // Question
              const questionsLength = this.survey.questions.length;
              questionCellKey = index;
              question = new SurveyQuestion({
                text: item.v,
                position: questionsLength + 1
              });

              this.survey.questions.push(question);
              break;
            case 'B':
              // Type and answers
              if (index == questionCellKey) {
                // type
                const type = item.v.toUpperCase();
                question.type = (typeof this.questionTypes[type] !== 'undefined' ? this.questionTypes[type] : this.questionTypes.UNIQUE);
              } else {

                // answers
                if (question.type != this.questionTypes.TEXT) {
                  // no es texto
                  const answersLength = question.answers.length;
                  const answer = new SurveyAnswer({
                    text: item.v,
                    position: answersLength + 1
                  });
                  question.answers.push(answer);
                }
              }
              break;
            case 'C':
              // Text Answer required
              if (index == questionCellKey && question.type == this.questionTypes.TEXT && item.v == 1) {
                question.answer_required = 1;
              }
              break;
          }

        }
      );
    });
    this.surveyLoaded = true;
  }

  /**
   * Function to save model and redirect to edition
   */
  saveModel() {
    this.survey.full = true;
    this.showSpinner = true;
    // @ts-ignore
    const req = this.survey.save().subscribe(
      response => {
        this.showSpinner = false;
        this.router.navigate(['/gestor/survey/' + this.survey.id]);
        req.unsubscribe();
      },
      error => {
        this.es.addError(error);
        this.showSpinner = false;
        req.unsubscribe();
      });
  }

  /**
   * Function to initalize survey import
   */
  cancel() {
    this.file = null;
    this.surveyLoaded = false;
    this.survey = null;
  }
}
