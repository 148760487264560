<div class="specimen-form-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>{{model ? (model.id ? 'Actualizar espécimen #' + model.id : 'Crear espécimen') : ''}}</h4>
    </div>
    <div class="col-sm-5 text-right">
            <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-8 offset-2">
      <form (ngSubmit)="saveModel()">
        <div class="card">
          <div class="card-body" *ngIf="model && formModel">
            <div class="alert alert-info" *ngIf="model.hasParticipate" i18n>
              Los parámetros asociados a la identidad no pueden ser actualizados. Este espécimen ya ha participado en alguna encuesta y modificar estos parámetros podría corromper la información.
            </div>
            <div class="row">
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModel.length > 0" [group]="formGroup"
                                           [model]="formModel"
                ></dynamic-ng-bootstrap-form>
              </div>
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModels[1].length > 0" [group]="formGroups[1]"
                                           [model]="formModels[1]"></dynamic-ng-bootstrap-form>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModels[2].length > 0" [group]="formGroups[2]"
                                           [model]="formModels[2]"></dynamic-ng-bootstrap-form>
              </div>
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModels[3].length > 0" [group]="formGroups[3]"
                                           [model]="formModels[3]"></dynamic-ng-bootstrap-form>
              </div>
            </div>

            <wzb-error-summary [errors]="errors"></wzb-error-summary>
          </div>
          <div class="card-footer">
            <button i18n type="submit" id="submitGeneral"
                    class="btn btn-lightning rounded-0 mb-2 mr-2">Guardar
            </button>
            <button i18n type="button" [routerLink]="['/gestor/specimen']"
                    class="btn btn-outline-lightning rounded-0 mb-2">
              Cancelar
            </button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
