import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BaseFormComponent,
  BreadcrumbItem,
  DataCollection,
  ErrorSummaryComponent
} from 'ng-wzb';
import {Audience} from '../../../models/audience';
import {Specimen} from '../../../models/specimen';
import {SpecimenGridComponent} from '../../specimen/specimen-grid/specimen-grid.component';
import {AppInjector} from '../../../app-injector';
import {Location} from '@angular/common';
import Swal from 'sweetalert2';
import {SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';
import {AudienceSpecimenContact} from '../../../models/audience-specimen-contact';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {DateHelper} from '../../../helpers/date-helper';

@Component({
  selector: 'app-audience-form',
  templateUrl: './audience-form.component.html',
  styleUrls: ['./audience-form.component.scss']
})
export class AudienceFormComponent extends BaseFormComponent implements OnInit {
  public model: Audience;
  public modelClass = Audience;
  public spinnerIcon = faSpinner;
  public showSpinner = false;
  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Audiences'),
      routerLink: '/gestor/audience'
    },
    {
      label: this.i18n.get('Audience edition')
    }
  ];

  public availableSurveys = [];
  public availableSpecimensCollection: DataCollection;
  public addedSpecimensCollection: DataCollection;
  public receivedSurveyId: boolean | number = false

  public AVAILABLE = 1;
  public ADDED = 2;

  public previous;

  private updateSpecimensEntity = 'audience-specimen';
  private updateSpecimensAction = 'bulk-update';

  private instanceSpecimenCollections = true;

  public duplicatedEmails = [];
  public emails = {};
  public duplicatedPhones = [];
  public phones = {};

  public smsBalance: number;
  public sendErrors = [];

  @ViewChild('available') availableGrid: SpecimenGridComponent;
  @ViewChild('added') addedGrid: SpecimenGridComponent;

  public swalTargets = new SwalPortalTargets();
  public dh;
  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.previous = this.as.previousRoute;
    this.checkPrevious();
    this.dh = DateHelper;
    this.loadSmsBalance();
    // @todo: Disable to disable survey selection
    // this.loadAvailableSurveys();
  }

  /**
   * Function to check previous route
   */
  checkPrevious(){
    const id = this.route.snapshot.paramMap.get('id');
    if (!this.previous || this.previous === '/gestor/audience/' + id  || this.previous === '/gestor/audience/create') {
      this.previous = '/gestor/audience';
    }
  }

  /**
   * Function to get sms balance
   */
  loadSmsBalance() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'sms-balance')
      .subscribe(
        response => {
          this.smsBalance = parseInt(response.body.total);
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to execute after load model
   */
  afterLoadModel() {
    super.afterLoadModel();
    this.checkInstanceSpecimensCollections();
    const receivedId = this.route.snapshot.paramMap.get('surveyId');
    if (receivedId) {
      // @ts-ignore
      // tslint:disable-next-line:radix
      this.receivedSurveyId = parseInt(receivedId);
      this.model.survey_id = this.receivedSurveyId;
    }
    return true;
  }

  /**
   * Function to instantiate specimens collections.
   */
  checkInstanceSpecimensCollections() {
    if (!this.isNewRecord && this.instanceSpecimenCollections) {
      this.availableSpecimensCollection = new DataCollection(Specimen, 'get-available-specimens', {id: this.model.id});
      this.availableSpecimensCollection.getModels();

      this.addedSpecimensCollection = new DataCollection(Specimen, 'get-added-specimens', {id: this.model.id});
      this.addedSpecimensCollection.getModels().subscribe(
        response => {
          this.checkDuplicateContactData();
        });
      this.setSelectedColumn(this.model.specimens);
      this.instanceSpecimenCollections = false;
    }
  }

  /**
   * Function to check duplicated contact data
   */
  checkDuplicateContactData() {
    this.emails = {};
    this.phones = {};
    this.duplicatedEmails = [];
    this.duplicatedPhones = [];
    this.addedSpecimensCollection.models.forEach((specimen, k) => {
      if (specimen.email !== null && specimen.email !== '') {
        if (typeof this.emails[specimen.email] === 'undefined') {
          this.emails[specimen.email] = [];
        } else {
          this.duplicatedEmails.push(specimen.email);
        }
        this.emails[specimen.email].push(specimen);
      }

      if (specimen.phone !== null && specimen.phone !== '') {
        if (typeof this.phones[specimen.phone] === 'undefined') {
          this.phones[specimen.phone] = [];
        } else {
          this.duplicatedPhones.push(specimen.phone);
        }
        this.phones[specimen.phone].push(specimen);
      }
    });
  }

  /**
   * Function to redirect to specimen edition
   * @param event trigger
   * @param specimen requested specimen
   */
  redirectToSpecimen(event, specimen) {
    event.preventDefault();
    this.router.navigate(['/gestor/specimen/' + specimen.id]);
  }

  /**
   * Function to load available surveys to add to audience form model
   */
  loadAvailableSurveys() {
    const req = this.as.getRequest(this.model.entity, 'get-available-surveys')
      .subscribe(
        response => {
          new Promise((resolve) => {
            response.body.forEach((survey, id) => {
              this.availableSurveys.push({
                label: survey.title,
                value: survey.id,
              });
            });
            resolve();
          }).then(() => {
            this.reset();
            req.unsubscribe();
          });
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  // @todo: Disable to disable survey selection
  // afterGetFormModel(fullFormModel: Array<DynamicFormControlModel>) {
  //   fullFormModel.push(
  //     new DynamicSelectModel({
  //       id: 'survey_id',
  //       label: this.i18n.get('Survey'),
  //       placeholder: this.i18n.get('Select survey'),
  //       filterable: true,
  //       value: this.model.survey_id,
  //       validators: {
  //         required: null,
  //       },
  //       options: this.availableSurveys,
  //       errorMessages: ErrorMessages.generateErrorMessages(['required'])
  //     })
  //   );
  // }

  /**
   * Function to execute after save model
   */
  afterSaveModel() {
    this.checkInstanceSpecimensCollections();
    this.checkAndRedirect();
    // @todo: uncomment if want save redirection to related survey. Comment because is redirected without add specimens
    // if (this.receivedSurveyId) {
    //   this.router.navigate([this.previous]);
    // }
    return true;
  }

  /**
   * Checks if it is a creation route, and if model has been saved and
   * has its id redirect without reload to update url
   */
  checkAndRedirect() {
    if (typeof this.location === 'undefined') {
      this.location = AppInjector.get(Location);
    }
    let path = this.location.path();

    if (path.indexOf('create') !== -1 && this.model.id) {
      path = path.replace('create/' + this.model.survey_id, this.model.id);
      this.location.replaceState(path);
    }
  }

  /**
   * Function to execute before save model
   * returns boolean. If it is false model does not save
   *
   */
  beforeSaveModel() {
    super.beforeSaveModel();
    this.model.active = this.model.active ? 1 : 0;
    return true;
  }


  /**
   * Function to set items selected value
   * @param items group of items to update
   * @param selected value to set
   */
  setSelectedColumn(items, selected = false) {
    if (typeof items === 'undefined')
      return;
    for (let i = items.length - 1; i >= 0; i--) {
      const specimen = items[i];
      specimen.selected = selected;
    }
  }

  /**
   * Function to set checkbox value of all items in group
   * @param event trigger {group: 1, eventData: event}
   */
  setAllSpecimens(event) {
    const group = event.group;
    const eventData = event.eventData;
    const checked = eventData.target.checked;
    const items = this.getItemsByGroup(group);
    this.setSelectedColumn(items, checked);
  }

  /**
   * Function to recover applied grid filters
   * @param group current group id
   */
  getAppliedFilters(group) {
    const grid = this.getGridByGroup(group);
    if (grid) {
      return grid.composeFilter();
    }
    return false;
  }


  /**
   * Function to update audience specimens models
   * @param event trigger
   */
  updateSpecimens(event) {
    const group = event.group;
    if (group === this.ADDED && this.model.doneCont > 0)
      return;

    const data = {
      items: this.getSelectedSpecimensByGroup(group),
      id: this.model.id,
      addedAll: this.getAllResultsValue(this.ADDED),
      addedFilters: this.getAppliedFilters(this.ADDED),
      availableAll: this.getAllResultsValue(this.AVAILABLE),
      availableFilters: this.getAppliedFilters(this.AVAILABLE)
    };

    const req = this.as.postRequest(this.updateSpecimensEntity, this.updateSpecimensAction, data)
      .subscribe(
        response => {
          if (response.body.success) {
            this.model.audienceSpecimens = response.body.audience.audienceSpecimens;
            this.model.checkEmails = response.body.audience.checkEmails;
            this.model.checkPhones = response.body.audience.checkPhones;
            this.addedSpecimensCollection.getModels().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              response => {
                if (this.addedGrid) {
                  this.addedGrid.checkSelected(false);
                  this.addedGrid.audience = this.model;
                  this.addedGrid.prepareNotifiedData();
                  this.checkDuplicateContactData();
                }
              });
            this.availableSpecimensCollection.getModels().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              response => {
                if (this.availableGrid) {
                  this.availableGrid.checkSelected(false);
                }
              });
          }

          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to return selected items of group
   * @param group to recover it items
   * @param id if we want only an array of ids. Set to false returns specimen objects.
   */
  getSelectedSpecimensByGroup(group, id = true) {
    const items = this.getItemsByGroup(group);
    const result = [];
    items.forEach((specimen, key) => {
      if (specimen.selected) {
        result.push(id ? specimen.id : specimen);
      }
    });
    return result;
  }

  /**
   * Function to return all items of group
   * @param group of items to select
   */
  getItemsByGroup(group) {
    let items = [];
    switch (group) {
      case this.ADDED:
        items = this.addedSpecimensCollection.models;
        break;
      case this.AVAILABLE:
        items = this.availableSpecimensCollection.models;
        break;
    }
    return items;
  }

  /**
   * Function to return grid by group
   * @param group current group id
   */
  getGridByGroup(group) {
    let grid: boolean | SpecimenGridComponent = false;
    switch (group) {
      case this.ADDED:
        grid = this.addedGrid;
        break;
      case this.AVAILABLE:
        grid = this.availableGrid;
        break;
    }
    return grid;
  }

  /**
   * Function to send notification to selected specimens. Only available to added specimens
   * @param event trigger
   * @param isSms boolean true to send sms | false to email
   */
  send(event, isSms = false) {
    if (isSms) {
      const items = this.getSelectedSpecimensByGroup(this.ADDED);
      const addedAllResultsChecked = this.getAllResultsValue(this.ADDED);
      let addedLength = items.length;
      if (addedAllResultsChecked) {
        addedLength = this.addedSpecimensCollection.totalRecords;
      }
      if (addedLength > this.smsBalance) {
        Swal.fire({
          title: this.i18n.get('No sms enough'),
          text: this.i18n.get('There is no sms enough to send to all selected specimens.'),
          icon: 'error',
        });
        return;
      }
    }

    const suffix = isSms ? 'sms' : 'email';
    Swal.fire({
      title: this.i18n.get('Shipping confirmation') + ' - ' + suffix,
      text: this.i18n.get('Do you really want to send to selected?'),
      icon: 'info',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.i18n.get('Cancel'),
      confirmButtonText: this.i18n.get('Send')
    }).then((result) => {
      if (result.value) {
        if (this.checkAllSendData(isSms)) {
          this.sendNotifications(isSms);
        }
      }
    });
  }

  /**
   * Function to check if all results is selected
   * @param group current group id
   */
  getAllResultsValue(group) {
    const grid = this.getGridByGroup(group);
    if (grid) {
      return grid.isAllResultsChecked();
    }
    return false;
  }

  /**
   * Function to send notification if confirm
   * @param isSms if way is sms
   */
  sendNotifications(isSms) {
    this.sendErrors = [];

    const reqData = {
      items: this.getSelectedSpecimensByGroup(this.ADDED),
      id: this.model.id,
      all: this.getAllResultsValue(this.ADDED)
    };

    const action = isSms ? 'send-sms' : 'send-mail';
    this.showSpinner = true;
    const req = this.as.postRequest(this.updateSpecimensEntity, action, reqData)
      .subscribe(
        response => {
          this.model.audienceSpecimens = response.body.models;
          let msg = this.i18n.get('Successfully sent');

          const hasErrors = response.body.errors.length > 0;
          if (hasErrors) {
            msg = this.i18n.get('Sent with errors');
            this.sendErrors = response.body.errors;
          }
          this.addedSpecimensCollection.getModels().subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            response => {
              if (this.addedGrid) {
                this.addedGrid.checkSelected(false);
                this.addedGrid.prepareNotifiedData();
              }
              this.showSpinner = false;
              if (hasErrors) {
                this.toastr.warning(msg);
              } else {
                this.toastr.success(msg);
              }
            });
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          this.showSpinner = false;
          req.unsubscribe();
        });
  }

  /**
   * Function to check all selected specimens contact data for the selected way
   * @param isSms if way is sms
   */
  checkAllSendData(isSms) {
    const items = this.getSelectedSpecimensByGroup(this.ADDED, false);
    const attr = isSms ? 'phone' : 'email';
    let cont = 0;
    items.forEach((item, k) => {
      if (item[attr] !== null && item[attr] !== '') {
        cont++;
      }
    });

    if (items.length > cont) {
      Swal.fire({
        title: this.i18n.get('Incomplete contact data'),
        text: this.i18n.get('There is no contact data for all selected specimens. Send anyway?'),
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: this.i18n.get('No'),
        confirmButtonText: this.i18n.get('Yes')
      }).then((result) => {
        if (result.value) {
          this.sendNotifications(isSms);
        }
      });
    }
    return !(items.length > cont);
  }
}
