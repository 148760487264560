import {Component, OnInit} from '@angular/core';
import {BreadcrumbItem} from 'ng-wzb';
import {Specimen} from '../../models/specimen';
import {AppInjector} from '../../app-injector';
import {KeyValuePipe} from '@angular/common';
import {BaseIndexComponent} from '../base-index/base-index.component';
import {faFilter} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-specimen',
  templateUrl: './specimen.component.html',
  styleUrls: ['./specimen.component.scss']
})
export class SpecimenComponent extends BaseIndexComponent implements OnInit {

  public modelClass = Specimen;

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Specimens'),
    }
  ];
  public keyValPipe;
  public filterIcon = faFilter;
  public showFilters = false;
  public filterApplied = false;

  public filters = {
    corporate_id: null,
    fullName: null,
    email: null,
    phone: null,
    gender_id: null,
    job: null,
    section: null,
    workplace: null,
    seniority_id: null,
    contract_type_id: null,
  }

  public associativeData = {
    genders: [],
    contractTypes: [],
    seniorities: [],
    jobs: [],
    sections: [],
    workplaces: [],
  };

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.loadAssociativeData();
  }

  /**
   * Function to load associative data
   */
  loadAssociativeData() {
    const req = this.as.getRequest('specimen', 'get-associative-data')
      .subscribe(
        response => {
          this.keyValPipe.transform(response.body).forEach((category) => {
            this.keyValPipe.transform(category.value).forEach((item) => {
              this.associativeData[category.key].push({
                label: item.value,
                value: item.key,
              });
            });
          });
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  /**
   * Function to filter collection
   */
  filter(event) {
    event.preventDefault();
    this.collection.filter = this.composeFilter();
    this.showFilters = false;
  }

  /**
   * Function to clean filters
   * @param event trigger
   */
  cleanFilters(event) {
    event.preventDefault();
    this.filterApplied = false;
    this.showFilters = false;
    this.filters = {
      corporate_id: null,
      fullName: null,
      email: null,
      phone: null,
      gender_id: null,
      job: null,
      section: null,
      workplace: null,
      seniority_id: null,
      contract_type_id: null,
    }
    this.collection.filter = {};
  }

  /**
   * Function to prepare filter query
   */
  composeFilter() {
    const filters = [];
    this.keyValPipe.transform(this.filters).forEach((filter) => {
      const key = filter.key;
      if (filter.value === null || filter.value === '') {
        return;
      }
      let q = {};
      switch (key) {
        case 'fullName':
          const chunks = filter.value.split(' ');
          q = {and: []};
          chunks.forEach((str, k) => {
            // @ts-ignore
            q.and.push(
              {
                or: [
                  {first_name: {like: str}},
                  {lastname: {like: str}},
                  {second_lastname: {like: str}},
                ]}
            );
          });

          break;
        case 'workplace':
          const workplace = this.associativeData.workplaces[filter.value].label;
          q[key] = {like: workplace};
          break;
        case 'section':
          const section = this.associativeData.sections[filter.value].label;
          q[key] = {like: section};
          break;
        case 'job':
          const jobName = this.associativeData.jobs[filter.value].label;
          q[key] = {like: jobName};
          break;
        case 'email':
        case 'phone':
        case 'corporate_id':
          q[key] = {like: filter.value};
          break;
        default:
          q[key] = filter.value;
          break;

      }
      filters.push(q);
    });

    let filter = {};
    this.filterApplied = filters.length > 0;
    switch(filters.length){
      case 0:
        break;
      case 1:
        filter = filters[0];
        break;
      default:
        filter = {and: filters}
        break;
    }

    return filter;
  }
}
