<div class="survey-manage-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>Gestionar encuesta {{(survey && survey.id ? ' #' + survey.id : '')}}</h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="survey-form card card-body mb-3" *ngIf="survey">
        <div class="row">
          <div class="col-8">
              <h3 id="title" class="border-0 font-weight-bold mb-3">{{survey.title}}</h3>
          </div>
          <div class="col-4">
            <span class="badge badge-md badge-info float-right" *ngIf="survey.is_anonymous; else noAnonymous" i18n>Encuesta anónima</span>
            <ng-template #noAnonymous>
              <span class="badge badge-md badge-warning float-right">Encuesta NO anónima</span>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="active" [checked]="survey.active == 1"
                     (change)="survey.active = $event.target.checked?1:0">
              <label class="form-check-label" for="active" i18n>
                Activo
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="callback_enabled"
                     [checked]="survey.callback_enabled == 1"
                     (change)="survey.callback_enabled = $event.target.checked?1:0">
              <label class="form-check-label" for="callback_enabled" i18n>
                Notificar respuestas
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-2 notification-form" *ngIf="survey.callback_enabled">
          <div class="col-6">
            <div class="form-group">
              <label i18n for="callback_text">Texto de la notificación</label>
              <textarea id="callback_text" class="form-control" [(ngModel)]="survey.callback_text"></textarea>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label i18n for="callback_emails">Emails</label>
              <textarea id="callback_emails" class="form-control" [(ngModel)]="survey.callback_emails"></textarea>
              <span class="text-muted emails-hint" i18n>Los emails que serán notificados, separados por comas (,) o punto y comas (;).</span>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <span (click)="saveModel()" class="btn btn-lightning rounded-0 float-right" i18n>Guardar</span>
          </div>
        </div>
      </div>
      <div class="card mb4">
        <div class="card-body">
          <ngb-tabset *ngIf="survey" [justify]="'center'">
            <ngb-tab [title]="tabs.AUDIENCES" id="tab_audiences" class="bg-white">
              <ng-template ngbTabContent>
                <div class="row mt-2">
                  <div class="col-12 text-right">
                    <a href="#" [routerLink]="['/gestor/audience/create/'+survey.id]"
                       class="btn btn-lightning rounded-0 mb-2" i18n> Añadir
                      audiencia</a>
                  </div>
                </div>

                <ng-container *ngIf="collection?.models?.length > 0">
                  <div class="list-group">
                    <div class="list-group-item">
                      <div class="row">
                        <div class="col-1" i18n>ID</div>
                        <div class="col-3" i18n>Nombre</div>
                        <div class="col-2 text-center" i18n>Activo</div>
                        <div class="col-2" i18n>Especímenes</div>
                        <div class="col-2" i18n>Progreso %</div>
                        <div class="col-2"></div>
                      </div>
                    </div>
                    <div class="list-group-item" *ngFor="let audience of collection.models; let i = index">
                      <div class="row">
                        <div class="col-1">{{audience.id}}</div>
                        <div class="col-3">{{audience.name}}</div>
                        <div class="col-2 text-center">
                          <app-toggle-field [model]="audience" field="active"></app-toggle-field>
                        </div>
                        <div class="col-2 text-center">{{audience.specimenCont}}</div>
                        <div class="col-2"><span
                          [class]="'badge badge-sm badge-pill badge-'+(audience.answerPercent==100?'success':(audience.answerPercent==0?'danger':'info'))">{{audience.answerPercent}}
                          %</span></div>
                        <div class="col-2">
                          <div class="btn-group">
                            <button class="btn-outline-secondary btn btn-sm" i18n
                                    [routerLink]="['/gestor/audience', audience.id]">Actualizar
                            </button>
                            <div class="btn-group" ngbDropdown role="group"
                                 aria-label="Button group with nested dropdown">
                              <button class="btn btn-outline-secondary dropdown-toggle-split"
                                      ngbDropdownToggle></button>
                              <div class="dropdown-menu" ngbDropdownMenu>
                                <button ngbDropdownItem class="text-danger" i18n
                                        (click)="deleteModel(audience)">Borrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize"
                                  class="d-flex justify-content-center mt-2"
                                  [(page)]="collection.page"
                                  [pageSize]="collection.pageSize"
                                  [collectionSize]="collection.totalRecords"
                                  [maxSize]="5"
                                  [rotate]="true">
                  </ngb-pagination>
                </ng-container>
              </ng-template>
            </ngb-tab>
            <ngb-tab [title]="tabs.PREVIEW" id="tab_preview" class="bg-white">
              <ng-template ngbTabContent>
                <div class="mt-2">
                  <app-survey-preview [model]="survey" [showHeader]="false"></app-survey-preview>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>

