<div class="audience-form-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>{{model ? (model.id ? 'Actualizar Audiencia #' + model.id : 'Crear Audiencia') : ''}}</h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form (ngSubmit)="saveModel()">
        <div class="card">
          <div class="card-body" *ngIf="model">
            <dynamic-ng-bootstrap-form *ngIf="formModel && formModel.length > 0" [group]="formGroup"
                                       [model]="formModel"
            ></dynamic-ng-bootstrap-form>
            <wzb-error-summary [errors]="errors"></wzb-error-summary>
          </div>
          <div class="card-footer">
            <button i18n type="submit" id="submitGeneral"
                    class="btn btn-lightning rounded-0 mb-2 mr-2">Guardar
            </button>
            <button i18n type="button" [routerLink]="[previous]"
                    class="btn btn-outline-lightning rounded-0 mb-2">
              Cancelar
            </button>
          </div>
        </div>
      </form>
      <div class="card mt-3">
        <div class="card-header">
          <div class="card-title">
            <h5 i18n>Especímenes de la audiencia</h5>
          </div>
        </div>
        <div class="card-body" *ngIf="model">

          <ng-container *ngIf="!isNewRecord; else specimensAdvice">
            <div class="alert alert-secondary" i18n *ngIf="model?.doneCont > 0">
              Esta audiencia tiene respuestas. Puede añadir especímenes, pero no eliminarlos
            </div>
            <div class="alert alert-success" *ngIf="model.nextScheduledSending" i18n>
              ENVÍO {{model.nextScheduledSending == 1?'EMAIL':'SMS'}} PROGRAMADO: {{dh.getDateObjectByTimestamp(model.nextScheduledSending.dispatch_date).toLocaleString()}}
            </div>
            <div class="alert alert-warning" *ngIf="model.checkEmails === false || model.checkPhones === false || this.duplicatedEmails.length > 0 || this.duplicatedPhones.length > 0">
              <p i18n>AVISOS</p>
              <div i18n *ngIf="model.checkEmails === false">No tiene los emails de todos los empleados.</div>
              <div i18n *ngIf="model.checkPhones === false">No tiene los teléfonos de todos los empleados.</div>
              <div i18n *ngIf="this.duplicatedEmails.length > 0">Hay <a href="javascript:void(0)" [swal]="duplicatedEmailSwal" >emails</a> duplicados.</div>
              <div i18n *ngIf="this.duplicatedPhones.length > 0">  Hay <a href="javascript:void(0)"  [swal]="duplicatedPhoneSwal" >teléfonos</a> duplicados.</div>
            </div>
            <div class="alert alert-danger" *ngIf="sendErrors.length > 0">
              <p i18n>Errores envío</p>
              <p *ngFor="let error of sendErrors" class="m-0">{{error}}</p>
            </div>
            <div class="row">
              <div class="col-6">
                <p *ngIf="showSpinner" class="text-success"><fa-icon [spin]="true" [icon]="spinnerIcon"></fa-icon> Enviando...</p>
                <app-specimen-grid #added [collection]="addedSpecimensCollection" [group]="ADDED"
                                   *ngIf="addedSpecimensCollection?.models"
                                   (selectAll)="setAllSpecimens($event)" [audience]="model"
                                   [showSendEmailBtn]="true" (sendEmail)="send($event)"
                                   [showSendSmsBtn]="true" (sendSms)="send($event, true)"
                                   [showDone]="!model.survey?.is_anonymous"
                                   (update)="updateSpecimens($event)"></app-specimen-grid>
              </div>
              <div class="col-6">
                <app-specimen-grid #available [collection]="availableSpecimensCollection" [group]="AVAILABLE"
                                   (selectAll)="setAllSpecimens($event)"
                                   (update)="updateSpecimens($event)"></app-specimen-grid>
              </div>
            </div>
          </ng-container>
          <ng-template #specimensAdvice>
            <div class="alert alert-info" i18n>
              Para añadir especímenes, primero tiene que guardar la audiencia
            </div>
          </ng-template>
        </div>
      </div>

    </div>
  </div>
</div>
<swal
  #duplicatedEmailSwal
  title="Emails duplicados"
  text="Esto no se puede deshacer."
  icon="warning"
 >
  <div *swalPortal="swalTargets.content">
    <div *ngFor="let duplicatedEmail of duplicatedEmails">
      <p>{{duplicatedEmail}}</p>
      <div *ngFor="let specimen of emails[duplicatedEmail]">
        <a href="#" (click)="redirectToSpecimen($event, specimen)">{{specimen.fullName}}</a>
      </div>
    </div>
  </div>
</swal>
<swal
  #duplicatedPhoneSwal
  title="Teléfonos duplicados"
  icon="warning"
  >
  <div *swalPortal="swalTargets.content">
    <div *ngFor="let duplicatedPhone of duplicatedPhones">
      <p>{{duplicatedPhone}}</p>
      <div *ngFor="let specimen of phones[duplicatedPhone]">
        <a href="#" (click)="redirectToSpecimen($event, specimen)">{{specimen.fullName}}</a>
      </div>
    </div>
  </div>
</swal>

