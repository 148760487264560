import {Model, ErrorMessages, I18nService} from 'ng-wzb';
import {ScheduledSendingItem} from './scheduled-sending-item';
import {AppInjector} from '../app-injector';
import {NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {DateHelper} from '../helpers/date-helper';
import {DynamicFormControlModel} from '@ng-dynamic-forms/core';
import {Survey} from './survey';
import {Audience} from './audience';

export class ScheduledSending extends Model {
  static entity = 'scheduled-sending';
  entity = 'scheduled-sending';

  public survey_id: number;
  public audience_id: number;
  public type: number;
  public processed: number;
  public dispatch_date: number;
  public items: Array<ScheduledSendingItem>;
  public survey: Survey;
  public audience: Audience;
  public checkEmails?: boolean;
  public checkPhones?: boolean;
  private _hour: number;
  private _minute: number;
  private _dispatchDatePick;
  private dateAdapter: NgbDateAdapter<string>;

  /**
   *  Returns and initialize if it is necessary dispatchDatePick
   */
  public get dispatchDatePick() {
    if (typeof this._dispatchDatePick === 'undefined') {
      const date = new Date();
      if (typeof this.dispatch_date !== 'undefined' && this.dispatch_date !== null) {
        const jsTimestamp = this.dispatch_date * 1000;
        date.setTime(jsTimestamp);
      }

      if (typeof this.dateAdapter === 'undefined') {
        this.dateAdapter = AppInjector.get(NgbDateAdapter);
      }

      const objDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
      this._dispatchDatePick = this.dateAdapter.toModel(objDate);

    }
    return this._dispatchDatePick;
  }

  /**
   *  Function to set dispatchDatePick value and update dipatch_date and age with converted value
   * @param value to set dispatchDatePick
   */
  public set dispatchDatePick(value) {
    if (typeof this.dateAdapter === 'undefined') {
      this.dateAdapter = AppInjector.get(NgbDateAdapter);
    }
    this._dispatchDatePick = value;

    const objDate = this.dateAdapter.fromModel(this.dispatchDatePick);
    this.dispatch_date = DateHelper.getPhpDateFromDatepickerObject(objDate);
  }

  public get hour(){
    const date = new Date();
    if (typeof this.dispatch_date !== 'undefined' && this.dispatch_date !== null) {
      const jsTimestamp = this.dispatch_date * 1000;
      date.setTime(jsTimestamp);
      return date.getHours();
    }
    return null;
  }

  public set hour(value){
    const date = new Date();
    if (typeof this.dispatch_date !== 'undefined' && this.dispatch_date !== null) {
      const jsTimestamp = this.dispatch_date * 1000;
      date.setTime(jsTimestamp);
    }
    date.setHours(value);
    this.dispatch_date = date.getTime() / 1000;
  }

  public get minute(){
    const date = new Date();
    if (typeof this.dispatch_date !== 'undefined' && this.dispatch_date !== null) {
      const jsTimestamp = this.dispatch_date * 1000;
      date.setTime(jsTimestamp);
      return date.getMinutes();
    }
    return null;
  }

  public set minute(value){
    const date = new Date();
    if (typeof this.dispatch_date !== 'undefined' && this.dispatch_date !== null) {
      const jsTimestamp = this.dispatch_date * 1000;
      date.setTime(jsTimestamp);
    }

    date.setMinutes(value);
    this.dispatch_date = date.getTime() / 1000;
  }

  /**
   * Function to return all instanced form inputs
   */
  public getFormModel(): Array<DynamicFormControlModel> {
    this.i18n = AppInjector.get(I18nService);
    return [];
  }
}
