import {Component} from '@angular/core';
import {LoginComponent as BaseLoginComponent} from 'ng-wzb';
import {ErrorMessages} from 'ng-wzb';
import {DynamicCheckboxModel, DynamicFormModel, DynamicInputModel} from '@ng-dynamic-forms/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseLoginComponent {
  protected defaultRoute = '/gestor';

  /**
   * If app user is not guest, logout or redirect to home page depends of router
   */
  redirect() {
    if(typeof this.as.previousRoute !== 'undefined'){
      if(this.as.previousRoute === '/'){
        this.back();
      }else{
        this.router.navigate([this.as.previousRoute]);
      }
    }
  }
  getFormModel(): DynamicFormModel {
    return [
      new DynamicInputModel({
        id: 'login',
        label: this.i18n.get('Usuario'),
        placeholder: this.i18n.get('Introduzca usuario'),
        hint: this.i18n.get('Nunca compartiremos su usuario con nadie más.'),
        validators: {
          required: null,
          pattern: '^[-a-zA-Z0-9_\\.@]+$',
          maxLength: 128,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength', 'pattern'])
      }),
      new DynamicInputModel({
        id: 'password',
        label: this.i18n.get('Contraseña'),
        inputType: 'password',
        placeholder: this.i18n.get('Introduzca contraseña'),
        validators: {
          required: null,
          maxLength: 72,
          minLength: 3,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength', 'minLength'])
      }),
      new DynamicCheckboxModel({
        id: 'rememberMe',
        label: this.i18n.get('Recordarme'),
      }),
    ];
  }
}
