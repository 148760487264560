import {Component, OnInit} from '@angular/core';
import {BaseIndexComponent} from '../base-index/base-index.component';
import {
  BreadcrumbItem,
  DataCollection,
  Yii2UsuarioService
} from 'ng-wzb';
import {AudienceSpecimenContact} from '../../models/audience-specimen-contact';
import {DateHelper} from '../../helpers/date-helper';
import {AppInjector} from '../../app-injector';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sms-balance',
  templateUrl: './sms-balance.component.html',
  styleUrls: ['./sms-balance.component.scss']
})
export class SmsBalanceComponent extends BaseIndexComponent implements OnInit {

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Sms balance')
    }
  ];

  public collection: DataCollection;
  public availableBalance = 0;
  public balanceClass = 'info';
  public dh: DateHelper;

  public quantity: number;
  public yii2usuario;
  public isSuperAmin;

  ngOnInit(): void {
    this.yii2usuario = AppInjector.get(Yii2UsuarioService);

    this.loadUserRole();
    this.loadTotal();
    this.dh = DateHelper;

    this.collection = new DataCollection(AudienceSpecimenContact);
    this.collection.setFilter({
      type: 2
    }, true);
  }

  /**
   * Temporal function to load user role
   */
  loadUserRole() {
    const req = this.as.getRequest('site', 'get-is-super-admin', null, true)
      .subscribe(
        response => {
          this.isSuperAmin = response.body;
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to check css badge class
   */
  checkCssClass() {
    if (this.availableBalance <= 100) {
      this.balanceClass = 'danger';
    } else if (this.availableBalance <= 250) {
      this.balanceClass = 'warning';
    }
  }

  /**
   * Function to load total balance
   */
  loadTotal() {
    const req = this.as.getRequest(AudienceSpecimenContact.entity, 'sms-balance')
      .subscribe(
        response => {
          // tslint:disable-next-line:radix
          this.availableBalance = parseInt(response.body.total);
          this.checkCssClass();
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }

  /**
   * Function to update sms balance
   * @param event trigger
   * @param decrement  if action is decrement
   */
  updateBalance(event, decrement = false) {
    event.preventDefault();
    Swal.fire({
      title: this.i18n.get('Operation confirmation'),
      text: this.i18n.get('Do you really want to execute this operation?'),
      icon: 'info',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.i18n.get('Cancel')
    }).then((result) => {
      if (result.value) {
        this.updateBalanceCallback(decrement);
      }
    });
  }

  /**
   * Function to update sms balance when confirm
   * @param decrement if action is decrement
   */
  updateBalanceCallback(decrement) {

    const req = this.as.postRequest(AudienceSpecimenContact.entity, 'update-sms-balance', {
      qty: this.quantity,
      decrement
    })
      .subscribe(
        response => {
          if (response.body.success) {
            this.availableBalance = response.body.total;
            this.checkCssClass();
            this.quantity = null;
          }
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }
}
