import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AppService} from '../../services/app.service';
import { LogService} from 'ng-wzb';

@Component({
  selector: 'app-survey-layout',
  templateUrl: './survey-layout.component.html',
  styleUrls: ['./survey-layout.component.scss']
})
export class SurveyLayoutComponent {

  title = 'pro-dashboard-angular';

  constructor(private appService: AppService) {

  }

  getClasses() {
    return {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    };
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  prepareRoute(outlet: RouterOutlet) {
    LogService.log(outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']);
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
