import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbItem} from 'ng-wzb';
import {Audience} from '../../../models/audience';
import {Survey} from '../../../models/survey';
import {AppInjector} from '../../../app-injector';
import {StringHelper} from '../../../helpers/string-helper';
import {BaseIndexComponent} from '../../base-index/base-index.component';

@Component({
  selector: 'app-survey-manage',
  templateUrl: './survey-manage.component.html',
  styleUrls: ['./survey-manage.component.scss']
})
export class SurveyManageComponent extends BaseIndexComponent implements OnInit {

  @Input() surveyId: number | boolean = false;
  public modelClass = Audience;
  @Input() survey: Survey;
  public sh;
  public tabs = {
    AUDIENCES: '',
    PREVIEW: ''
  }

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Surveys'),
      routerLink: '/gestor'
    },
    {
      label: this.i18n.get('Survey manage')
    }
  ];

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.sh = AppInjector.get(StringHelper);
    this.tabs.AUDIENCES = this.i18n.get('Audiences');
    this.tabs.PREVIEW = this.i18n.get('Preview');
    const surveyId = this.route.snapshot.paramMap.get('id');
    if (typeof surveyId !== 'undefined' && surveyId !== null) {
      // tslint:disable-next-line:radix
      this.surveyId = parseInt(surveyId);
      this.loadSurvey();
    }
    super.ngOnInit();
  }

  /**
   * Function to execute before load models
   * returns boolean. If it is false models are not loaded
   */
  beforeLoadModels() {
    if (this.surveyId) {
      this.collection.setFilter({
        survey_id: this.surveyId
      }, true);
    }
    return true;
  }

  /**
   * Function to execute after load models
   * @param response of load models request
   */
  afterLoadModels(response) {

    return true;
  }

  /**
   * Function to load survey
   */
  loadSurvey() {
    if (typeof this.survey !== 'undefined')
      return;

    this.survey = new Survey();

    // @ts-ignore
    const req = Survey.findOne(this.surveyId).subscribe(
      response => {
        this.survey = response;
        req.unsubscribe();
      });
  }


  /**
   *  Function to save model
   */
  saveModel() {
    const req = this.survey.save().subscribe(
      response => {
        this.toastr.success(this.i18n.get('Updated'));
        req.unsubscribe();
      });
  }

}
