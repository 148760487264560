import {Model, ErrorMessages, I18nService} from 'ng-wzb';
import {AudienceSpecimen} from './audience-specimen';

export class ScheduledSendingItem extends Model {
  static entity = 'scheduled-sending-item';
  entity = 'scheduled-sending-item';

  public scheduled_sending_id: number;
  public audience_specimen_id: number;
  public sent: number;
  public sent_at: number;
  public audienceSpecimen: AudienceSpecimen;

}
