import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent, LogService, BreadcrumbItem, DefaultsService} from 'ng-wzb';
import {Survey} from '../../../models/survey';
import {faBell, faBellSlash} from '@fortawesome/free-solid-svg-icons';
import {SurveyAnswer} from '../../../models/survey-answer';


@Component({
  selector: 'app-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrls: ['./survey-preview.component.scss']
})
export class SurveyPreviewComponent extends BaseComponent implements OnInit {

  @Input() model: Survey;
  @Input() showSubmitBtn = false;
  @Input() submitBtnText;
  @Input() showCancelBtn = false;
  @Input() cancelBtnText;
  @Input() showHeader = true;
  @Output() submitClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelClick: EventEmitter<any> = new EventEmitter<any>();
  public questionTypes;
  public questionTypesNames;
  public icons = {
    notify: faBell,
    notNotify: faBellSlash,
  };

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    this.questionTypesNames = DefaultsService.getValue('QuestionTypesNames', 'GENERAL');
    if (this.showSubmitBtn && typeof this.submitBtnText === 'undefined') {
      this.submitBtnText = this.i18n.get('Save');
    }
    if (this.showCancelBtn && typeof this.cancelBtnText === 'undefined') {
      this.cancelBtnText = this.i18n.get('Cancel');
    }
    this.loadModel();
  }

  /**
   * Function to trigger submitClick event if showSubmitBtn is enabled
   */
  submitBtnClick() {
    if (this.showSubmitBtn) {
      this.submitClick.emit(true);
    }
  }

  /**
   * Function to trigger cancelClick event if showCancelBtn is enabled
   */
  cancelBtnClick() {
    if (this.showCancelBtn) {
      this.cancelClick.emit(true);
    }
  }

  /**
   * Function to load model
   */
  loadModel() {
    if (typeof this.model !== 'undefined') {
      return;
    }

    this.model = new Survey();
    const id = this.route.snapshot.paramMap.get('id');
    if (id == null) {
      return;
    }
    const req = Survey.findOne(id).subscribe(
      response => {
        this.model = response;
        req.unsubscribe();
      });
  }

  /**
   * Function to update answer trigger callback
   * @param answer to update
   */
  toggleAnswer(answer){
    answer.trigger_callback = (!answer.trigger_callback ? 1 : 0);
    const answerModel = new SurveyAnswer(answer);
    const req = answerModel.save().subscribe(
      response => {
        this.toastr.success(this.i18n.get('Updated'));
        req.unsubscribe();
      });
  }

}
