import {Component, OnInit} from '@angular/core';
import {slideInAnimation} from './animations';
import {DefaultsService} from 'ng-wzb';
import {I18nWzbService} from './services/i18n-wzb.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        slideInAnimation
    ]
})
export class AppComponent implements OnInit {
    title = 'Survey';

  constructor( private ds: DefaultsService) {

  }

  ngOnInit(): void {
    this.ds.loadDefaults();
    I18nWzbService.loadTranslations();
  }

}
