<div class="scheduled sending-form-container">
  <div class="row">
    <div class="col-sm-7">
      <h4 class="mb-4 text-muted"
          i18n>{{model ? (model.id ? 'Actualizar Envío programado #' + model.id : 'Crear Envío programado individual') : ''}}</h4>
    </div>
    <div class="col-sm-5 text-right">
      <wzb-breadcrumbs [items]="breadcrumbs"></wzb-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form (ngSubmit)="saveModel()">
        <div class="card">
          <div class="card-body" *ngIf="model">
            <div class="row">
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModel && formModel.length > 0" [group]="formGroup"
                                           [model]="formModel"
                ></dynamic-ng-bootstrap-form>
              </div>
              <div class="col-6">
                <dynamic-ng-bootstrap-form *ngIf="formModel && formModels[1].length > 0" [group]="formGroups[1]"
                                           [model]="formModels[1]"></dynamic-ng-bootstrap-form>
              </div>

            </div>

          </div>
          <div class="card-footer">
            <button i18n type="submit" id="submitGeneral"
                    class="btn btn-lightning rounded-0 mb-2 mr-2">Guardar
            </button>
            <button i18n type="button" [routerLink]="[previous]"
                    class="btn btn-outline-lightning rounded-0 mb-2">
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
