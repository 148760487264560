<div class="specimen-grid-container">
  <div class="row">
    <div class="col-6">
      <h3 class="grid-title border-bottom-0" i18n> Especímenes <span>{{titleGroupName}}</span>
        <span (click)="openModal(content)" title="Filtrar especímenes"
              [class]="'filter-icon-container'+(filterApplied?' filter-applied':'')">
          <fa-icon [icon]="icons.filter" size="xs"></fa-icon>
        </span>
      </h3>
    </div>
    <div class="col-6">
      <a href="#" class="btn btn-sm btn-info mr-2" (click)="sendSMS($event)" title="Enviar sms"
         i18n *ngIf="showSendSmsBtn">Sms</a>
      <a href="#" class="btn btn-sm btn-info" (click)="sendMail($event)" title="Enviar email"
         i18n *ngIf="showSendEmailBtn">Email</a>
      <a href="#" class="btn btn-sm btn-secondary float-right" (click)="updateClick($event);"
         i18n *ngIf="showUpdateBtn && group == ADDED && (audience?.doneCont === 0 || forceUpdateBtn)">Eliminar</a>
      <a href="#" class="btn btn-sm btn-secondary float-right"
         (click)="updateClick($event);" i18n *ngIf="showUpdateBtn && group == AVAILABLE">Añadir</a>
    </div>
  </div>
  <ng-container *ngIf="collection?.models?.length > 0">
    <div class="list-group">
      <div class="list-group-item">
        <div class="row">
          <div class="col-1">
            <input type="checkbox" [id]="groupName+'_all'" [name]="groupName+'_all'" (click)="setAllEvent($event)">
          </div>
          <div class="col-6" i18n>Espécimen</div>
          <div class="col-5" i18n>Info</div>
        </div>
        <div class="row mt-2" *ngIf="showAllResultsCheck">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" [id]="groupName+'_all_results'"
                     [name]="groupName+'_all'">
              <label class="form-check-label" [id]="groupName+'_all_results'" i18n>
                Seleccionar todos los resultados de todas las páginas
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="list-group-item"
           *ngFor="let specimen of collection.models; let i = index">
        <div class="row">
          <div class="col-1">
            <input type="checkbox" [name]="groupName+'[]'" [(ngModel)]="specimen.selected"
                   (ngModelChange)="checkSelected($event)">
          </div>
          <div class="col-6">
            {{specimen.fullName}}
            <div>
              <span i18n>ID Corp.: </span> #
              <app-not-defined-field [model]="specimen" attr="corporate_id"></app-not-defined-field>
              |
              <span i18n>Edad: </span>
              <app-not-defined-field [model]="specimen" attr="age" noResultText="--"></app-not-defined-field>
            </div>
            <div class="email">
              <div i18n class="info-title">Email</div>
              <app-not-defined-field [model]="specimen" attr="email"></app-not-defined-field>
            </div>
            <div *ngIf="showNotified">
            <span *ngIf="notifiedData[specimen.id]?.totalMails > 0">
              <span class="text-info">  <span
                [class]="'badge badge-sm badge-'+(notifiedData[specimen.id]?.totalMails <3?'info':'warning')">{{notifiedData[specimen.id]?.totalMails}}</span> </span> {{notifiedData[specimen.id]?.lastMail}}
              <span class="viewMore"
                    (click)="notifiedData[specimen.id].showMailHistory = !notifiedData[specimen.id].showMailHistory"
                    *ngIf="notifiedData[specimen.id]?.totalMails > 1">
                <fa-icon [icon]="(notifiedData[specimen.id]?.showMailHistory?icons.hide:icons.view)"></fa-icon>
              </span>
              <ul *ngIf="notifiedData[specimen.id]?.showMailHistory">
                <ng-container *ngFor="let mail of notifiedData[specimen.id]?.mails; let k = index;">
                  <li *ngIf="k > 0">{{mail.sent_at}}</li>
                  </ng-container>
                </ul>
            </span>
            </div>
            <div class="phone">
              <div i18n class="info-title">Teléfono</div>
              <app-not-defined-field [model]="specimen" attr="phone"></app-not-defined-field>
            </div>
            <div *ngIf="showNotified">
            <span *ngIf="notifiedData[specimen.id]?.totalSms > 0">
              <span class="text-info">  <span
                [class]="'badge badge-sm badge-'+(notifiedData[specimen.id]?.totalSms <3?'info':'warning')">{{notifiedData[specimen.id]?.totalSms}}</span> </span> {{notifiedData[specimen.id]?.lastSms}}
              <span class="viewMore"
                    (click)="notifiedData[specimen.id].showSmsHistory = !notifiedData[specimen.id].showSmsHistory"
                    *ngIf="notifiedData[specimen.id]?.totalSms > 1">
                <fa-icon [icon]="(notifiedData[specimen.id]?.showSmsHistory?icons.hide:icons.view)"></fa-icon>
              </span>
              <ul *ngIf="notifiedData[specimen.id]?.showSmsHistory">
                <ng-container *ngFor="let sms of notifiedData[specimen.id]?.sms; let k = index;">
                  <li *ngIf="k > 0">{{sms.sent_at}}</li>
                  </ng-container>
                </ul>
            </span>
            </div>
         </div>
          <div class="col-5">
            <ul>
              <ng-container *ngIf="showDone && notifiedData[specimen.id] && notifiedData[specimen.id].done">
                <li><span class="text-success"><span class="mr-1" i18n>Realizado</span> <fa-icon [icon]="icons.check"
                                                                                                 size="xs"></fa-icon></span>
                </li>
              </ng-container>
              <li *ngIf="group == ADDED && notifiedData[specimen.id]">
                PIN: <strong>{{notifiedData[specimen.id].pin}}</strong>
              </li>
              <li>
                <app-not-defined-field [model]="specimen" attr="gender" noResultText="Género"></app-not-defined-field>
              </li>
              <li>
                <app-not-defined-field [model]="specimen" attr="job"
                                       noResultText="Puesto trabajo"></app-not-defined-field>
              </li>
              <li>
                <app-not-defined-field [model]="specimen" attr="section"
                                       noResultText="Sección"></app-not-defined-field>
                |
                <app-not-defined-field [model]="specimen" attr="workplace"
                                       noResultText="Planta"></app-not-defined-field>

              </li>
              <li>
                <app-not-defined-field [model]="specimen" attr="seniority"
                                       noResultText="Antigüedad"></app-not-defined-field>
              </li>
              <li>
                <app-not-defined-field [model]="specimen" attr="contractType"
                                       noResultText="Tipo contrato"></app-not-defined-field>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6 offset-1">
            <a href="#" [routerLink]="['/gestor/specimen/'+specimen.id]" class="btn btn-sm btn-secondary" i18n>Editar</a>
          </div>
          <div class="col-5">
            <a href="#" class="btn btn-sm btn-secondary float-right"
               [routerLink]="['/gestor/scheduled-sending/create/'+audience.survey_id+'/'+audience.id+'/'+specimen.id]" title="Programar envío para este espécimen"
               i18n *ngIf="showScheduledProgramingShortCutBtn">Programar envío</a>
          </div>
        </div>
        <ng-container *ngIf="itemsData && itemsData[specimen.id]">
          <div class="row">
            <div class="col-12">
              <span [class]="itemsData[specimen.id].cssClass">{{itemsData[specimen.id].text}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ngb-pagination *ngIf="collection.totalRecords > collection.pageSize" class="d-flex justify-content-center mt-2"
                    [(page)]="collection.page"
                    [pageSize]="collection.pageSize"
                    [collectionSize]="collection.totalRecords"
                    [maxSize]="5"
                    [rotate]="true">
    </ngb-pagination>
  </ng-container>
</div>
<ng-template #content let-modal>
  <wzb-modal #filterModal i18n-title title="Filtro espécimen" cssClass="wrapper default-theme bg-white" [modal]="modal">
    <wzb-modal-body>
      <form (ngSubmit)="filter()">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="corporate_id" i18n>ID Corporativa</label>
              <input name="corporate_id" id="corporate_id" class="form-control" [(ngModel)]="filters.corporate_id">
            </div>
            <div class="form-group">
              <label for="phone" i18n>Teléfono</label>
              <input name="phone" id="phone" class="form-control" [(ngModel)]="filters.phone">
            </div>
            <div class="form-group">
              <label for="gender_id" i18n>Género</label>
              <select name="gender_id" id="gender_id" class="form-control" [(ngModel)]="filters.gender_id">
                <option value="null" class="text-muted" i18n>Seleccione género...</option>
                <option *ngFor="let gender of associativeData.genders" [value]="gender.value">{{gender.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="section" i18n>Sección</label>
              <select name="section" id="section" class="form-control"
                      [(ngModel)]="filters.section">
                <option value="null" class="text-muted" i18n>Seleccione sección...</option>
                <option *ngFor="let section of associativeData.sections;"
                        [value]="section.value">{{section.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="seniority_id" i18n>Antigüedad</label>
              <select name="seniority_id" id="seniority_id" class="form-control" [(ngModel)]="filters.seniority_id">
                <option value="null" class="text-muted" i18n>Seleccione antigüedad...</option>
                <option *ngFor="let seniority of associativeData.seniorities"
                        [value]="seniority.value">{{seniority.label}}</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="fullName" i18n>Nombre</label>
              <input name="fullName" id="fullName" class="form-control" [(ngModel)]="filters.fullName">
            </div>
            <div class="form-group">
              <label for="email" i18n>Email</label>
              <input name="email" id="email" class="form-control" [(ngModel)]="filters.email">
            </div>
            <div class="form-group">
              <label for="job" i18n>Puesto de trabajo</label>
              <select name="job" id="job" class="form-control"
                      [(ngModel)]="filters.job">
                <option value="null" class="text-muted" i18n>Seleccione puesto de trabajo...</option>
                <option *ngFor="let job of associativeData.jobs;" [value]="job.value">{{job.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="workplace" i18n>Planta</label>
              <select name="workplace" id="workplace" class="form-control"
                      [(ngModel)]="filters.workplace">
                <option value="null" class="text-muted" i18n>Seleccione planta...</option>
                <option *ngFor="let workplace of associativeData.workplaces;"
                        [value]="workplace.value">{{workplace.label}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="contract_type_id" i18n>Tipo contrato</label>
              <select name="contract_type_id" id="contract_type_id" class="form-control"
                      [(ngModel)]="filters.contract_type_id">
                <option value="null" class="text-muted" i18n>Seleccione tipo...</option>
                <option *ngFor="let type of associativeData.contractTypes" [value]="type.value">{{type.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button i18n type="submit" id="submitGeneral"
                    class="btn btn-lightning mb-2 mr-2">Buscar
            </button>
            <button i18n type="button" class="btn btn-outline-lightning mb-2" (click)="dismissModal()">
              Cancelar
            </button>
            <a href="#" class="btn btn-warning mb-2 float-right" i18n (click)="cleanFilters($event)">Reset</a>
          </div>
        </div>
      </form>
    </wzb-modal-body>
  </wzb-modal>
</ng-template>
