import {Component, OnInit} from '@angular/core';
import {BaseFormComponent, LogService, BreadcrumbItem, ErrorMessages, ErrorSummaryComponent} from 'ng-wzb';
import {Specimen} from '../../../models/specimen';
import {DynamicFormControlModel, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {KeyValuePipe} from '@angular/common';
import {AppInjector} from '../../../app-injector';

@Component({
  selector: 'app-specimen-form',
  templateUrl: './specimen-form.component.html',
  styleUrls: ['./specimen-form.component.scss']
})
export class SpecimenFormComponent extends BaseFormComponent implements OnInit {
  public model: Specimen;
  public modelClass = Specimen;

  public breadcrumbs: BreadcrumbItem[] = [
    {
      label: this.i18n.get('Specimens'),
      routerLink: '/gestor/specimen'
    },
    {
      label: this.i18n.get('Specimen edition')
    }
  ];

  public fieldGroups = [
    ['corporate_id', 'first_name', 'second_lastname'],
    ['birthdatePick', 'lastname', 'gender_id'],
    ['email', 'workplace', 'contract_type_id', 'job'],
    ['phone', 'seniority_id', 'section'],
  ];

  public associativeData = {
    jobs: [],
    sections: [],
    workplaces: [],
    genders: [],
    contractTypes: [],
    seniorities: []
  };

  protected keyValPipe;

  /**
   * @inheritDoc
   */
  ngOnInit() {
    super.ngOnInit();
    this.keyValPipe = AppInjector.get(KeyValuePipe);
    this.loadAssociativeData();
  }

  /**
   * Function to load associative data
   */
  loadAssociativeData() {
    const req = this.as.getRequest(this.model.entity, 'get-associative-data')
      .subscribe(
        response => {
          new Promise((resolve) => {
            this.keyValPipe.transform(response.body).forEach((category) => {
              this.keyValPipe.transform(category.value).forEach((item) => {
                this.associativeData[category.key].push({
                  label: item.value,
                  value: item.key,
                });
              });
            });
            resolve();
          }).then(() => {
            this.reset();
          });
        }, error => {
          req.unsubscribe();
          this.es.addError(error);
        }
      );
  }

  afterGetFormModel(fullFormModel: Array<DynamicFormControlModel>) {
    fullFormModel.push(
      new DynamicSelectModel({
        id: 'gender_id',
        label: this.i18n.get('Gender'),
        placeholder: this.i18n.get('Select gender'),
        filterable: true,
        value: String(this.model.gender_id),
        validators: {
          required: null,
        },
        options: this.associativeData.genders,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      })
    );
    fullFormModel.push(
      new DynamicSelectModel({
        id: 'seniority_id',
        label: this.i18n.get('Seniority'),
        placeholder: this.i18n.get('Select seniority'),
        filterable: true,
        value: String(this.model.seniority_id),
        validators: {
          required: null,
        },
        options: this.associativeData.seniorities,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      })
    );
    fullFormModel.push(
      new DynamicSelectModel({
        id: 'contract_type_id',
        label: this.i18n.get('Contract type'),
        placeholder: this.i18n.get('Select contract type'),
        filterable: true,
        // tslint:disable-next-line:radix
        value: String(this.model.contract_type_id),
        validators: {
          required: null,
        },
        options: this.associativeData.contractTypes,
        errorMessages: ErrorMessages.generateErrorMessages(['required'])
      })
    );
  }

  /**
   * Function to execute after save model
   */
  afterSaveModel() {
    this.reset();
    return true;
  }
}
