import {Model, ErrorMessages, I18nService} from 'ng-wzb';
import {Survey} from './survey';
import {
  DynamicCheckboxModel,
  DynamicFormControlModel,
  DynamicInputModel,
} from '@ng-dynamic-forms/core';

import {AppInjector} from '../app-injector';
import {Specimen} from './specimen';
import {AudienceSpecimenAnswer} from './audience-specimen-answer';
import {AudienceSpecimen} from './audience-specimen';
import {ScheduledSending} from './scheduled-sending';

export class Audience extends Model {
  static entity = 'audience';
  entity = 'audience';

  public name: string;
  public survey_id: number;
  public active: number;
  public survey?: Survey;
  public specimens: Array<Specimen>;
  public audienceSpecimens: Array<AudienceSpecimen>;
  public answers: Array<AudienceSpecimenAnswer>;
  public specimenCont: number;
  public answerPercent: number;
  public doneCont: number;
  public checkEmails?: boolean;
  public checkPhones?: boolean;
  public hasAnswerIncongruity?: boolean;
  public nextScheduledSending?: ScheduledSending;

  /**
   * Function to execute after load model
   * @param response of load model request
   */
  public afterLoadModel(response) {
    if (!super.afterLoadModel(response)) {
      return false;
    }
    if (typeof this.doneCont !== 'undefined' && this.specimenCont > 0) {
      this.answerPercent = parseFloat(((this.doneCont / this.specimenCont) * 100).toFixed(2));
    } else {
      this.answerPercent = 0;
    }
    return true;
  }

  /**
   * Function to return all instanced form inputs
   */
  public getFormModel(): Array<DynamicFormControlModel> {
    this.i18n = AppInjector.get(I18nService);

    return [

      new DynamicCheckboxModel({
        id: 'active',
        label: this.i18n.get('Active'),
        // @ts-ignore:  This condition will always return 'false' since the types 'boolean' and '1' have no overlap
        value: (this.active === 1),
      }),
      new DynamicInputModel({
        id: 'name',
        label: this.i18n.get('Name'),
        value: this.name,
        validators: {
          required: null,
          maxLength: 256,
        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'maxLength'])
      }),
    ];
  }
}
