import {Component, OnInit} from '@angular/core';
import {BaseComponent, LogService, BreadcrumbItem, ErrorMessages, DefaultsService} from 'ng-wzb';
import {AudienceSpecimenAnswer} from '../../models/audience-specimen-answer';
import {DynamicFormModel, DynamicFormService, DynamicInputModel} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {AppInjector} from '../../app-injector';
import {Survey} from '../../models/survey';
import {faInfoCircle, faArrowRight} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-conduct-survey',
  templateUrl: './conduct-survey.component.html',
  styleUrls: ['./conduct-survey.component.scss']
})
export class ConductSurveyComponent extends BaseComponent implements OnInit {

  public confirmedSpecimen = false;
  public specimenPin: number | string;
  public survey: Survey;
  public answers: Array<AudienceSpecimenAnswer> = [];

  public confirmEntity = 'survey';
  public confirmAction = 'conduct-survey';
  public sendAction = 'finish-survey';

  public colorIndex = 0;
  public colorMaxIndex = 0;
  public btnClass = 'btn-outline-light';
  public surveyStep = 0;
  public surveyLastStep = 0;
  public specimenAnswers = [];
  public allowSend = false;
  public showContent = false;
  public finished = false;
  public progress = 0;
  public questionTypes;
  public start = false;
  public identity = false;
  public showNextBtn = false;
  public confirmError = false;
  public infoIcon = faInfoCircle;
  public arrowIcon = faArrowRight;

  public formGroup: FormGroup;
  public formService: DynamicFormService;
  public formModel: DynamicFormModel = [];

  public colors = [
    {bg: '#44FFD1', light: 1},
    {bg: '#6153CC', light: 0},
    {bg: '#A60067', light: 0},
    {bg: '#961D4E', light: 0},
    {bg: '#360A14', light: 0}
  ];

  /**
   * Function to check sent params and call autoconfirm if code is sent
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.colorMaxIndex = this.colors.length - 1;
    this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    this.specimenPin = this.route.snapshot.paramMap.get('code');
    if (typeof this.specimenPin !== 'undefined' && this.specimenPin !== null && this.specimenPin !== '') {
      // send to autoconfirm
      this.confirmSpecimen(true);
    } else {
      this.showContent = true;
      this.formService = AppInjector.get(DynamicFormService);
      this.formModel = this.getConfirmSpecimenFormModel();
      // @ts-ignore
      this.formGroup = this.formService.createFormGroup(this.formModel);
    }


  }

  /**
   * Function to check if user is allowed to do the survey
   * @param auto if is auto-triggerd by param detection
   */
  confirmSpecimen(auto = false) {
    this.confirmError = false;
    if (!auto) {
      if (this.formGroup.value.specimen_pin)
        this.specimenPin = this.formGroup.value.specimen_pin;
    }
    const req = this.as.postRequest(this.confirmEntity, this.confirmAction, {sp: this.specimenPin})
      .subscribe(
        response => {
          if (response.body.success) {
            if (typeof response.body.done !== 'undefined' && response.body.done) {
              this.finished = true;
              this.setPageColor();
            } else {
              if (typeof response.body.survey !== 'undefined') {
                this.confirmedSpecimen = true;
                this.survey = response.body.survey;
                // @ts-ignore
                // tslint:disable-next-line:radix
                this.survey.is_anonymous = parseInt(this.survey.is_anonymous);
                this.surveyLastStep = this.survey.questions.length - 1;
              }
            }
            this.showContent = true;
          } else {
            this.confirmError = true;
            if (this.router.url !== '/' && this.router.url !== '') {
              this.router.navigate(['']);
            }
          }
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          // this.router.navigate(['']);
          req.unsubscribe();
        });
  }

  /**
   * Function to present and start survey
   * @param event trigger
   */
  startSurvey(event) {
    event.preventDefault();
    this.start = true;
    document.body.style.backgroundColor = this.survey.is_anonymous ? '#FF5722' : '#673ab7';
  }

  /**
   * Function to start survey after show identity advice
   * @param event trigger
   */
  identityAdvice(event) {
    event.preventDefault();
    this.identity = true;
    this.checkNextBtn();
    this.setPageColor();
  }

  /**
   * Function to return specimen login form
   */
  getConfirmSpecimenFormModel(): DynamicFormModel {
    return [
      new DynamicInputModel({
        id: 'specimen_pin',
        label: this.i18n.get('Pin personal de encuesta'),
        placeholder: this.i18n.get('Introduzca pin'),
        validators: {
          required: null,
          pattern: '[0-9]+_[0-9]+',

        },
        errorMessages: ErrorMessages.generateErrorMessages(['required', 'pattern'])
      })
    ];
  }


  parseRequiredAttr(question) {
    if (typeof question.answer_required === 'string') {
      // tslint:disable-next-line:radix
      question.answer_required = parseInt(question.answer_required);
    }
    return question;
  }

  /**
   * Function to check if question is type text and anwer is not required, and if it is fill empty value
   * @param question currentQuestion
   */
  fillNotRequiredEmptyTextAnswer(question) {
    question = this.parseRequiredAttr(question);
    // tslint:disable-next-line:triple-equals
    if (question.type == this.questionTypes?.TEXT && !question.answer_required) {
      if (typeof this.specimenAnswers[question.id] === 'undefined') {
        this.specimenAnswers[question.id] = '';
        this.checkAllowSent();
        this.checkNextBtn();
        this.setColorIndex();
      }
    }
  }

  /**
   * Function to register answers
   * @param event trigger
   * @param fromButton if is trigger by nextButtom to register empty text responses
   * @param fromKeyup if is trigger by keyup event
   */
  registerAnswer(event, fromButton = false, fromKeyup = false) {

    if (this.questionTypes == null || typeof this.questionTypes === 'undefined') {
      this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    }
    const currentQuestion = this.parseRequiredAttr(this.survey.questions[this.surveyStep]);
    if (fromButton && !fromKeyup) {
      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      this.fillNotRequiredEmptyTextAnswer(currentQuestion);
    } else {
      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      if (currentQuestion.type == this.questionTypes?.MULTIPLE) {
        this.specimenAnswers[currentQuestion.id] = [];
        const elms = document.querySelectorAll('input[name="answer_' + currentQuestion.id + '[]"]:checked');
        if (elms.length === 0)
          this.specimenAnswers[currentQuestion.id] = null;

        elms.forEach((elm, k) => {
          // @ts-ignore
          // tslint:disable-next-line:radix
          this.specimenAnswers[currentQuestion.id].push(elm.value);
        });
      } else {
        let val = '';
        val = this.cleanAnswer(event.target.value);

        this.specimenAnswers[currentQuestion.id] = val;
        if (!this.isRightAnswer(currentQuestion, val)) {
          this.checkAllowSent();
          this.checkNextBtn();
          return;
        }

        // tslint:disable-next-line:triple-equals
        if (currentQuestion.type == this.questionTypes?.TEXT && fromKeyup) {
          event.target.value = '';
        }
      }

      this.checkAllowSent();
      this.checkNextBtn();
    }

    // tslint:disable-next-line:triple-equals
    if (currentQuestion.type == this.questionTypes?.UNIQUE && this.surveyStep <= this.surveyLastStep) {
      setTimeout(() => {
        if(this.surveyStep < this.surveyLastStep) {
          this.nextQuestion();
        }else{
          this.lastStep();
        }
      }, 500);
    }

    // return if auto increment step
    // tslint:disable-next-line:triple-equals
    return (fromButton || fromKeyup) && currentQuestion.type != this.questionTypes?.UNIQUE;
  }

  /**
   * Function to cjheck if question answer is right
   * @param question model
   * @param answer value
   */
  isRightAnswer(question, answer) {
    // tslint:disable-next-line:max-line-length triple-equals
    return (typeof answer !== 'undefined' && (answer !== null && answer !== '') || (question.type == this.questionTypes?.TEXT && !question.answer_required && answer === ''));
  }

  /**
   * Function to check if all required questions are answered.
   * Update progress and allow send if is completed
   */
  checkAllowSent() {
    let cont = 0;
    this.survey.questions.forEach((question, key) => {
      // tslint:disable-next-line:triple-equals
      if (typeof this.specimenAnswers[question.id] == 'undefined') {
        return;
      }

      const answer = this.specimenAnswers[question.id];
      question = this.parseRequiredAttr(question);

      // tslint:disable-next-line:triple-equals
      if (this.isRightAnswer(question, answer)) {
        cont++;
      }
    });
    this.allowSend = this.survey.questions.length === cont;
    // tslint:disable-next-line:radix
    this.progress = parseInt(String((cont / this.survey.questions.length) * 100));
  }

  /**
   * Function to set color index as infinite loop
   * @param operation if surveyStep increment or decrement
   */
  setColorIndex(operation = '+') {
    switch (operation) {
      case '+':
        if (this.colorIndex < this.colorMaxIndex) {
          this.colorIndex++;
        } else {
          this.colorIndex = 0;
        }
        break;
      case '-':
        if (this.colorIndex > 0) {
          this.colorIndex--;
        } else {
          this.colorIndex = this.colorMaxIndex;
        }
        break;
    }
    this.setPageColor();
  }

  /**
   * Function to set necessary color attributes on page
   */
  setPageColor() {
    document.body.style.backgroundColor = this.colors[this.colorIndex].bg;
    if (this.colors[this.colorIndex].light) {
      document.body.classList.add('light-background');
      this.btnClass = 'btn-dark';
    } else {
      document.body.classList.remove('light-background');
      this.btnClass = 'btn-light';
    }
    window.scrollTo(0, 0);

  }

  /**
   * Function to check when show next button
   */
  checkNextBtn() {
    if (this.questionTypes == null || typeof this.questionTypes === 'undefined') {
      this.questionTypes = DefaultsService.getValue('QuestionTypes', 'GENERAL');
    }
    if(this.surveyStep <= this.surveyLastStep) {
      const currQuestion = this.survey.questions[this.surveyStep];
      const answer = this.cleanAnswer(this.specimenAnswers[this.survey.questions[this.surveyStep].id]);
      this.showNextBtn = this.surveyStep <= this.surveyLastStep && this.isRightAnswer(currQuestion, answer);
    }else{
      this.showNextBtn = false;
    }
  }

  /**
   * Function to clean string answers
   * @param answer value
   */
  cleanAnswer(answer) {
    if (typeof answer === 'string') {
      answer = answer.trim();
    }
    return answer;
  }

  /**
   * Function to go to next page
   * @param event trigger
   */
  next(event) {
    event.preventDefault();
    if(this.surveyStep < this.surveyLastStep) {
      if (this.registerAnswer(event, true, event.type === 'keyup')) {
        this.nextQuestion();
      }
    }else{
      this.lastStep();
    }
  }

  lastStep(){
    this.surveyStep++;
    this.setColorIndex();
  }

  nextQuestion() {
    if (this.showNextBtn) {
      this.surveyStep++;
      this.checkNextBtn();
      this.setColorIndex();
      this.fillNotRequiredEmptyTextAnswer(this.survey.questions[this.surveyStep]);
    }
  }

  /**
   * Function to go back to response
   */
  back(event) {
    event.preventDefault();
    this.surveyStep--;
    this.checkNextBtn();
    this.setColorIndex('-');
  }

  /**
   * Function to send survey to save
   * @param event trigger
   */
  sendSurvey(event) {
    event.preventDefault();
    const req = this.as.postRequest(this.confirmEntity, this.sendAction, {
      items: this.specimenAnswers,
      sp: this.specimenPin
    })
      .subscribe(
        response => {
          if (response.body.success) {
            this.finished = true;
            this.setColorIndex();
          }
          req.unsubscribe();
        },
        error => {
          this.es.addError(error);
          req.unsubscribe();
        });
  }
}
